export const CONFIG = {
  API_URL: window._env_.API_URL,
  CAPTURE_URL: window._env_.CAPTURE_URL,
  ENVIRONMENT: window._env_.ENVIRONMENT,
  APP_NAME: 'RapidPORTAL',
  AUTH_PROVIDER_TYPE: window._env_.AUTH_PROVIDER_TYPE,
  AUTH_AUTHORITY: window._env_.AUTH_AUTHORITY,
  CARESOURCE_PROVIDER_PORTAL: window._env_.CARESOURCE_PROVIDER_PORTAL,
  AUTH_CLIENT_ID: window._env_.AUTH_CLIENT_ID,
  AUTH_SCOPES: window._env_.AUTH_SCOPES,
  AUTH_AUD: window._env_.AUTH_AUD ?? undefined,
  AUTH_CUSTOM_AUTH: window._env_.AUTH_CUSTOM_AUTH ?? undefined,
  AUTH_CUSTOM_ISSUER: window._env_.AUTH_CUSTOM_ISSUER ?? undefined,
  AUTH_CUSTOM_REVOCATION: window._env_.AUTH_CUSTOM_REVOCATION ?? undefined,
  AUTH_CUSTOM_TOKEN: window._env_.AUTH_CUSTOM_TOKEN ?? undefined,
  AUTH_CUSTOM_END_SESSION: window._env_.AUTH_CUSTOM_END_SESSION ?? undefined,
  AUTH_CUSTOM_USER_INFO: window._env_.AUTH_CUSTOM_USER_INFO ?? undefined
};

export enum SECTIONS {
  HOME = 'home',
  DASHBOARD = 'dashboard',
  HCFA = 'hcfa',
  UB04 = 'ub04',
  DENTAL = 'dental'
}

export enum AUTH_PROVIDER_TYPES {
  OKTA = 'OKTA',
  KEYCLOAK = 'KEYCLOAK',
  AUTH0 = 'AUTH0'
}
export enum FORM_ACTIONS {
  NEW = 'new',
  EDIT = 'edit',
  VIEW = 'view',
  COPY = 'copy'
}

export const HCFA_TABLE_EXTERNAL_ID = '24_service_table';
export const UB04_TABLE_EXTERNAL_ID = '42_service_table';
export const DENTAL_TABLE_EXTERNAL_ID = 'dental_service_table';

export const THANK_YOU_PATH = '/thank-you';
export const LOGOUT_URL = window.location.origin + THANK_YOU_PATH;
