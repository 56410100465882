import { FormInstance } from 'antd';
import { SECTIONS } from 'config/constants';
import { hcfaformFields, ub04FormFields, dateInputs, dentalformFields } from 'config/search';
import { ClaimDTO } from 'models/DTO/ClaimDTO';
import { FieldDTO } from 'models/DTO/FieldDTO';
import { FieldValueDTO } from 'models/DTO/FieldValueDTO';
import { TableDTO } from 'models/DTO/TableDTO';
import { TableRowDTO } from 'models/DTO/TableRowDTO';
import moment from 'moment';
import { DATE_FORMAT } from 'utils/hcfaConstants';

function getFormTablesData(_tableData: TableDTO[]) {
  const _arr: Record<string, any>[] = [];
  _tableData.forEach((_table: TableDTO) => {
    if (_table.name?.includes('SERVICE TABLE')) {
      _table.rows.forEach((rowData: TableRowDTO) => {
        let _data: Record<string, any> = {};

        rowData.fields.forEach((fieldData: FieldDTO) => {
          const dataToAdd = getFieldValueDTOToForm({ fieldData });
          _data = { ..._data, ...dataToAdd };
        });

        _arr.push(_data);
      });
    }
  });

  return _arr.filter((obj) => Object.keys(obj).length);
}

export function getClaimDataBySteps(form: SECTIONS, claimData: ClaimDTO, filters: string[]) {
  const fieldsToCopy: string[] = [];
  const filtersFields =
    form === SECTIONS.HCFA ? hcfaformFields : form === SECTIONS.UB04 ? ub04FormFields : dentalformFields;
  let dataToCopy: Record<string, any> = {};
  filters.forEach((filter) => {
    fieldsToCopy.push(...filtersFields[filter]);
  });

  claimData.documents[0].fields!.forEach((data: FieldDTO) => {
    if (fieldsToCopy.includes(data.externalId!)) {
      const dataToAdd = getFieldValueDTOToForm({ fieldData: data });
      dataToCopy = { ...dataToCopy, ...dataToAdd };
    }
  });

  if (fieldsToCopy.includes('24Table')) {
    const tableData = getFormTablesData(claimData.documents[0].tables!);
    dataToCopy['24Table'] = tableData;
  }
  if (fieldsToCopy.includes('42Services')) {
    const tableData = getFormTablesData(claimData.documents[0].tables!);
    dataToCopy['42Services'] = tableData;
  }
  console.log('get data by steps: ', dataToCopy);
  return dataToCopy;
}

function getDateFieldValue(v: FieldValueDTO) {
  /**timestamp to MMDDYYYY format that date component expects */
  if (isNaN(Number(v.value))) return v.value;
  const timeStampMoment = moment(Number(v.value));
  const uiFormat = timeStampMoment.format('MMDDYYYY');

  const date = moment(uiFormat);

  return date;
}

function getFieldValueDTOToForm({ fieldData }: { fieldData: FieldDTO }) {
  const dataToCopy: Record<string, any> = {};
  const { values } = fieldData;
  if (values.length === 0) {
    /**No values */
    dataToCopy[fieldData.externalId!] = null;
  } else if (values.length > 1) {
    /**Multiple values */
    dataToCopy[fieldData.externalId!] = values.map((v) =>
      // dateInputs.includes(fieldData.externalId!) && v.value ? getDateFieldValue(v) : v.value
      dateInputs.includes(fieldData.externalId!) && v.value
        ? moment(isNaN(Number(v.value)) ? v.value : Number(v.value))
        : v.value
    );
  } else {
    /**Single value */
    dataToCopy[fieldData.externalId!] =
      // dateInputs.includes(fieldData.externalId!) && values[0].value ? getDateFieldValue(values[0]) : values[0].value;
      dateInputs.includes(fieldData.externalId!) && values[0].value
        ? moment(isNaN(Number(values[0].value)) ? values[0].value : Number(values[0].value))
        : values[0].value;
  }
  return dataToCopy;
}

export function getClaimData(claimData: ClaimDTO, form: 'ub04' | 'hcfa' = 'hcfa') {
  let dataToCopy: Record<string, any> = {};

  claimData.documents[0].fields!.forEach((data) => {
    const dataToAdd = getFieldValueDTOToForm({ fieldData: data });
    dataToCopy = { ...dataToCopy, ...dataToAdd };
  });
  const tableData = getFormTablesData(claimData.documents[0].tables!);

  if (form === 'ub04') {
    dataToCopy['42Services'] = tableData;
  } else {
    /**hcfa, dental has 24Table */
    dataToCopy['24Table'] = tableData;
  }
  console.log('data to copy ', dataToCopy);
  return dataToCopy;
}

export function onSearchSelect(
  form: FormInstance<any> | undefined,
  field: string | string[],
  value: string,
  options: string[],
  triggerChange?: (val: any) => void
) {
  const filterResponse = options.filter((item) => item.toLowerCase().startsWith(value.toLowerCase()));
  if (filterResponse.length === 1) {
    form?.setFieldValue(field, filterResponse[0]);
    if (triggerChange) {
      triggerChange(filterResponse[0]);
    } else {
      form?.validateFields([field]);
    }
    // form?.validateFields([field]);
    form?.getFieldInstance(field)?.blur();
  } else if (filterResponse.length === 0 && options.includes('') && 'unsigned'.startsWith(value.toLowerCase())) {
    form?.setFieldValue(field, '');
  }
}
