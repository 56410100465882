import React, { useContext } from 'react';
import { Col, Form, Input, Radio, Select } from 'antd';
import { useTranslation } from 'react-i18next';
import { getYupValidator, getYupWarning } from 'utils/ub04/validataions';
import { UB04_FIELDS } from 'utils/ub04';
import { toCurrency, toCurrencyPaste } from 'helpers/toCurrency';
import { nullifyRadioGroup } from 'helpers/nullifyRadioGroup';
import { onSearchSelect } from 'utils/search/search';
import { FormContext } from 'components/common/FormProvider';

type Fields = 'name' | 'healthPlanId' | 'releaseInfo' | 'benefits' | 'priorPayments' | 'estAmount' | 'otherId';

type FieldsetPayerInformationProps = {
  nameId: string;
  healthPlanId: string;
  releaseInfoId: string;
  benefitsId: string;
  priorPaymentsId: string;
  estAmountId: string;
  billingNpiId?: string;
  otherName: string;
  otherId: string;
  labels?: Partial<Record<Fields, string>>;
  form?: any;
};

function FieldsetPayerInformation(props: FieldsetPayerInformationProps): JSX.Element {
  const { nameId, healthPlanId, releaseInfoId, benefitsId, priorPaymentsId, estAmountId, otherName, otherId } = props;
  const { form } = useContext(FormContext);

  const { t } = useTranslation();

  function handleUbestAmountKeyDown(e: any) {
    if (e.key === 'Tab') {
      e.preventDefault();
      const nextInput = document.getElementById('basic_56_billing_npi');
      if (nextInput) {
        nextInput.focus();
      }
    }
  }

  return (
    <div className="grid-container7" style={{ height: '100%' }}>
      <div className="item30">
        <Form.Item name={nameId} required={nameId === UB04_FIELDS.payer50A} rules={[getYupValidator(t, nameId)]}>
          <Input onInput={(e: any) => (e.target.value = e.target.value.toUpperCase())} />
        </Form.Item>
      </div>
      <div className="item31">
        <Form.Item
          name={healthPlanId}
          required={healthPlanId === UB04_FIELDS.healthPlan51A}
          rules={[getYupValidator(t, healthPlanId)]}
        >
          <Input onInput={(e: any) => (e.target.value = e.target.value.toUpperCase())} />
        </Form.Item>
      </div>
      <div className="item32">
        <Form.Item name={releaseInfoId}>
          <Select
            showSearch
            allowClear
            filterOption={(input, option) => (`${option?.value}` ?? '').toLowerCase().includes(input?.toLowerCase())}
            onSearch={(input) => {
              onSearchSelect(form, releaseInfoId, input, ['X', 'Y', 'N']);
            }}
          >
            <Select.Option value="X">X</Select.Option>
            <Select.Option value="Y">Y</Select.Option>
            <Select.Option value="N">N</Select.Option>
          </Select>
        </Form.Item>
      </div>
      <div className="item33">
        <Form.Item name={benefitsId} rules={[getYupValidator(t, benefitsId)]}>
          <Select
            showSearch
            allowClear
            filterOption={(input, option) => (`${option?.value}` ?? '').toLowerCase().includes(input?.toLowerCase())}
            onSearch={(input) => {
              onSearchSelect(form, benefitsId, input, ['X', 'Y', 'N', 'W']);
            }}
          >
            <Select.Option value="X">X</Select.Option>
            <Select.Option value="Y">Y</Select.Option>
            <Select.Option value="N">N</Select.Option>
            <Select.Option value="W">W</Select.Option>
          </Select>
        </Form.Item>
      </div>
      <div className="item34">
        <Form.Item
          name={priorPaymentsId}
          rules={[getYupValidator(t, priorPaymentsId), getYupWarning(t, priorPaymentsId)]}
        >
          <Input onPaste={toCurrencyPaste} allowClear onInput={toCurrency} prefix="$" placeholder="1.00" />
        </Form.Item>
      </div>
      <div className="item35">
        <Form.Item name={estAmountId} rules={[getYupValidator(t, estAmountId), getYupWarning(t, estAmountId)]}>
          <Input
            onPaste={toCurrencyPaste}
            allowClear
            onInput={toCurrency}
            prefix="$"
            placeholder="1.00"
            onKeyDown={estAmountId === UB04_FIELDS.estimatedAmount55A ? (e) => handleUbestAmountKeyDown(e) : undefined}
          />
        </Form.Item>
      </div>
      {/* {billingNpiId && (
        <>
          <div className="item36" style={{ position: 'relative', bottom: '100%', borderLeft: 0 }}>
            <label className="form_label_color">56. {'NPI'}</label>
          </div>
          <div className="item37" style={{ position: 'relative', bottom: '100%' }}>
            <Form.Item
              name={UB04_FIELDS.billingNpi}
              rules={[getYupValidator(t, UB04_FIELDS.billingNpi), getYupWarning(t, UB04_FIELDS.billingNpi)]}
            >
              <Input />
            </Form.Item>
          </div>
        </>
      )} */}
      <div className="item36">
        <span className="form_label_color">{otherName}</span>
      </div>
      <div className="item37">
        <label className="form_label_color">
          <Form.Item name={otherId} rules={[getYupValidator(t, otherId)]}>
            <Input />
          </Form.Item>
        </label>
      </div>
    </div>
  );
}

export default FieldsetPayerInformation;
