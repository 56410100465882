import { useOidcAccessToken } from '@axa-fr/react-oidc';

export const useUserData = () => {
  const { accessTokenPayload } = useOidcAccessToken();
  const roles = accessTokenPayload?.['realm_access']?.['roles'] as string[];
  const username = accessTokenPayload?.['preferred_username'] as string;
  return {
    isAdmin: roles?.includes('SUPER_ADMIN'),
    username,
    isProvidersAdmin: roles?.includes('PROVIDERS_ADMIN'),
    isProviderAdmin: roles?.includes('PROVIDER_ADMIN'),
    isUserRole: roles?.includes('USER')
  };
};
