import React from 'react';
import { useTranslation } from 'react-i18next';
import { Form, Radio } from 'antd';

import { HCFA_FIELDS } from '../../../utils/hcfa/fields';
import { getYupValidator } from 'utils/hcfa/validations';
import { nullifyRadioGroup } from 'helpers/nullifyRadioGroup';

type Props = {
  label?: string;
};

function FormItemEmployment({ label }: Props) {
  const { t } = useTranslation();

  return (
    <Form.Item
      label={label || t('common:fields.employment') + ' (' + t('common:fields.employmentStatus') + ')'}
      name={HCFA_FIELDS.employmentStatus}
      rules={[getYupValidator(t, HCFA_FIELDS.employmentStatus)]}
    >
      <Radio.Group name={HCFA_FIELDS.employmentStatus} style={{ maxWidth: '150px' }}>
        <Radio onClick={nullifyRadioGroup} value="YES">
          {t('common:yes')}
        </Radio>
        <Radio onClick={nullifyRadioGroup} value="NO">
          {t('common:no')}
        </Radio>
      </Radio.Group>
    </Form.Item>
  );
}

export default FormItemEmployment;
