import axios from 'axios';

export const submitBranding = async (data: any, token?: string) => {
  const res = await axios.post(window._env_.API_URL + `/api/v1/branding`, data, {
    headers: { Authorization: `Bearer ${token}` }
  });
  return res.data;
};

export const updateBranding = async (data: any, token?: string, id?: string) => {
  const res = await axios.put(window._env_.API_URL + `/api/v1/branding/${id}`, data, {
    headers: { Authorization: `Bearer ${token}` }
  });
  return res.data;
};

export const deleteBrand = async (token?: string, id?: string) => {
  const res = await axios.delete(window._env_.API_URL + `/api/v1/branding/${id}`, {
    headers: { Authorization: `Bearer ${token}` }
  });
  return res.data;
};

export const getBranding = async (token?: string) => {
  const res = await axios.get<any>(window._env_.API_URL + `/api/v1/branding`, {
    headers: {
      Authorization: `Bearer ${token}`
    }
  });
  return res.data;
};

export const getAllBrands = async (token?: string) => {
  const res = await axios.get<any>(window._env_.API_URL + `/api/v1/branding/all`, {
    headers: {
      Authorization: `Bearer ${token}`
    }
  });
  return res.data;
};

export const getImage = async (imgId: any, token?: string) => {
  const res = await axios.post(window._env_.API_URL + `/api/v1/files/download?filename=${imgId}`, null, {
    headers: {
      Authorization: `Bearer ${token}`
    }
  });
  return res.data;
};
