import * as React from 'react';
import { Form, Input } from 'antd';
import { getYupValidator } from 'utils/ub04/validataions';
import { useTranslation } from 'react-i18next';

type Fields = 'code' | 'value' | 'qualifier';

type FieldsetFullNameProps = {
  codeId: string;
  qualifierId: string;
  valueId: string;
  labels?: Partial<Record<Fields, string>>;
  isRequired?: Partial<Record<Fields, boolean>>;
};

const QUALIFIERS = ['A1', 'A2', 'A3', 'A4', 'AC', 'AT', 'B3', 'BG', 'BH', 'BI', 'PKW', 'ZZ'];

function FieldsetAdditionalCodes(props: FieldsetFullNameProps): JSX.Element {
  const { codeId, valueId, qualifierId, labels, isRequired } = props;
  const { t } = useTranslation();

  return (
    <div className="flex">
      <Form.Item
        style={{ width: '170px ' }}
        label={labels?.qualifier || 'qualifier'}
        name={qualifierId}
        rules={[getYupValidator(t, qualifierId)]}
      >
        <Input onInput={(e: any) => (e.target.value = e.target.value.toUpperCase())} />
      </Form.Item>

      <Form.Item
        className="field--first"
        label={labels?.code || 'npi'}
        name={codeId}
        rules={[getYupValidator(t, codeId)]}
      >
        <Input onInput={(e: any) => (e.target.value = e.target.value.toUpperCase())} />
      </Form.Item>

      <Form.Item label={labels?.value || 'value'} name={valueId} rules={[getYupValidator(t, valueId)]}>
        <Input onInput={(e: any) => (e.target.value = e.target.value.toUpperCase())} />
      </Form.Item>
    </div>
  );
}

export default FieldsetAdditionalCodes;
