export const HCFA_FIELDS = {
  insuranceType: 'single_1__INSURANCE_TYPE',

  patientLastName: '2_pat_last_name',
  patientFirstName: '2_pat_first_name',
  patientMiddleInitial: '2_pat_mi',

  HCFA31psysiciancheckbox: 'HCFA_31_psysician_checkbox',
  HCFA33psysiciancheckbox: 'HCFA_33_psysician_checkbox',

  physicianLastName: 'HCFA31_01_01',
  physicianFirstName: 'HCFA31_01_02',
  physicianMiddleInitial: 'HCFA31_01_03',
  physicianMiddleName: 'HCFA31_01_04',
  physicianSuffix: 'HCFA31_01_05',
  physicianCredential: 'HCFA31_01_06',
  physicianGeneration: 'HCFA31_01_07',

  providersPhysicianLastname: 'HCFA33_01_01',
  providersPhysicianFirstname: 'HCFA33_01_02',
  providersPhysicianMiddleIntial: 'HCFA33_01_03',
  HCFA33Credentials: 'HCFA_33_credentials',
  HCFA33Address2: 'HCFA_33_address_2',
  HCFA33Generation: 'HCFA_33_generation',

  patientGen: '2_pat_gen',
  patientSex: '3_pat_sex',
  patientAddress: '5_pat_addr_1',
  patientCity: '5_pat_city',
  patientState: '5_pat_state',
  patientZip: '5_pat_zip',
  patientPhone: '_5e__PATIENTS_TELEPHONE',
  //patientPhoneCode: '_5e__PATIENTS_TELEPHONE_code',
  patientSignature: '_12__SIGNATURE',
  patientSignedDate: '_12a__SIGNED_DATE',

  pat_addr2: 'pat_addr2',

  insuredId: '1a_insured_id',
  insuredLastName: '4_ins_last_name',
  insuredFirstName: '4_ins_first_name',
  insuredMiddleInitial: '4_ins_mi',
  insuredGen: '4_ins_gen',
  insuredSex: 'single_11aa__INSUREDS_SEX',
  insuredAddress: '7_ins_addr_1',
  insuredCity: '7_ins_city',
  insuredState: '7_ins_state',
  insuredZip: '7_ins_zip',
  insuredPhone: '_7e__INSUREDS_TELEPHONE',
  insuredPhoneCode: '_7e__INSUREDS_TELEPHONE_code',
  insuredDateOfBirth: '_11a__INSUREDS_DATE_OF_BIRTH',
  insuredPolicyGroup: '11_ins_grp_no',
  insuredSignature: '13_insured_signature',
  insuredSignatureDate: 'HCFA13',

  ins_addr2: 'ins_addr2',
  useAddressTwo: 'useAddressTwo',

  insurancePlanProgramName: '_11c__INSURANCE_PLAN_NAME_OR_PROGRAM_NAME',

  otherInsuredId: '9a_oc_ins_id',
  otherInsuredLastName: '9_oc_insured_last_name',
  otherInsuredFirstName: '9_oc_insured_first_name',
  otherInsuredMiddleInitial: '9_oc_insured_middle_name',
  otherInsuredGen: '9_oc_insured_generation',
  otherInsuredPlanName: '9d_oc_plan_name',

  employmentStatus: '10a_job_related',
  autoAccident: '10b_auto_accident',
  otherAccident: '10c_other_accident',
  accidentState: '10b_accident_state',

  claimCode1: '10d_code_1',
  claimCode2: '10d_code_2',
  claimCode3: '10d_code_3',
  claimCode4: '10d_code_4',

  otherClaimQuality: '11b_other_clm_qual',
  otherClaimId: '_11b__OTHER_CLAIM_ID',
  otherCoverage: '11d_other_coverage',
  otherDate: '_15__OTHER_DATE',
  otherQuality: '_15__OTHER_QUAL',
  servicesTable: '24Table',

  currentIllness: '14_current_illness',
  quality: '_14b__QUAL',
  unableToWorkFromDate: '_16a__UNABLE_TO_WORK_FROM',
  unableToWorkToDate: '_16b__UNABLE_TO_WORK_TO',

  referringProviderLastName: '17_ref_last_name',
  referringProviderFirstName: '17_ref_first_name',
  referringProviderMiddleInitial: '17_ref_mi',
  referringProviderGen: '17_ref_gen',
  referringTitle: '17_ref_title',
  referringProviderQuality: '17_refer_prov_qual',
  referringProviderId: '17a_refer_prov_id',
  referringProviderIdQuality: '17a_ref_prov_id_qual',

  referringNpi: '17b_referring_npi',

  hospitalizationFromDate: '_18a__HOSPITALIZATION_FROM',
  hospitalizationToDate: '_18b__HOSPITALIZATION_TO',

  outsideLab: '20_outside_lab',
  labCharges: '20_lab_charges',
  icdInd: '21_icd_ind',

  dxCodeA: '21a_dx',
  dxCodeB: '21b_dx',
  dxCodeC: '21c_dx',
  dxCodeD: '21d_dx',
  dxCodeE: '21e_dx',
  dxCodeF: '21f_dx',
  dxCodeG: '21g_dx',
  dxCodeH: '21h_dx',
  dxCodeI: '21i_dx',
  dxCodeJ: '21j_dx',
  dxCodeK: '21k_dx',
  dxCodeL: '21l_dx',
  dxCodeM: '21m_dx',
  dxCodeN: '21n_dx',
  dxCodeO: '21o_dx',

  resubmissionCode: '22_resub_code',
  originalRefNumber: '22_original_ref_no',
  numberType: '23_number_type',
  authorizationNumber: '23_authorization',
  cliaNumber: '23_cliaNumber',
  zipCode: '23_zipCode',
  federalTaxId: '25_billing_tin',
  federalTaxIdType: '25_tin_type',

  patientAccountNumber: '26_pat_id_acct_no',
  assignment: '27_assignment',
  totalCharges: '28_total_charges',
  amountPaid: '29_amt_paid',
  reservedForNuccUse1: '_8_reserved_for_nucc',
  reservedForNuccUse2: 'HCFA09b_Reserved',
  reservedForNuccUse3: 'HCFA09c',
  rsvdForNuccUse: '_30__RESERVED_FOR_NUCC_USE',

  signatureOfPhysician: '_31a__SIGNATURE_OF_PHYSICIAN_SIGNED',
  signatureOfPhysicianDate: '_31b__SIGNATURE_OF_PHYSICIAN_DATE',

  hcfaAmbFromLocation: 'AmbFromLocation',
  hcfaFromAddress: '32_from_facility_address',
  hcfaFromCity: 'HCFA24_from_city',
  hcfaFromState: 'HCFA24_from_state',
  hcfaFromZip: 'HCFA24_from_zip',

  hcfaAmbToLocation: 'AmbToLocation',
  hcfaToAddress: '32_to_facility_address',
  hcfaToCity: 'HCFA24_to_city',
  hcfaToState: 'HCFA24_to_state',
  hcfaToZip: 'HCFA24_to_zip',

  facilityName: '32_facility_name',
  facilityAddress: '32_facility_addr_1',
  facilityCity: '32_facility_city',
  facilityState: '32_facility_state',
  facilityZip: '32_facility_zip',
  facilityNpi: '32a_facility_npi',
  facilityId: '32b_facility_id',
  facilityIdQuality: '32b_facility_id_qual',

  box32Address2: 'HCFA32_03',
  box32FromFacilityAddress2: 'HCFA32_FRADD_02',
  box32ToFacilityAddress2: 'HCFA32_TOADD_02',

  billingName: '33_billing_name',
  billingAddress: '33_billing_addr_1',
  billingCity: '33_billing_city',
  billingState: '33_billing_state',
  billingZip: '33_billing_zip',
  billingPhone: '33_billing_phone',
  billingPhoneCode: '33_billing_phone_code',
  billingNpi: '33a_billing_npi',
  billingId: '_33b_',
  billingIdQuality: '33_billing_id_qual',

  procedure24: '24d_procedure',
  diagnosisPointer: '24e_dx_pointer',
  chargesf24: '24f_charges',
  epsdt24: '24_epsdt',
  renderId24: '24j_render_id',
  renderNPI24: '24j_render_npi',
  patRelInfo6: '6_pat_rel_info',
  anesthesiaDuration: '24_anesthesia_duration',
  /**NDC */
  ndcCode: 'ndc_code',
  ndcQuantity: 'ndc_quantity',
  ndcUnit: 'ndc_unit',

  additionalClaim19: '19_additional',
  isAmbulance: 'is-ambulance'
};

export const PHONE_CODES_MAP = {
  //_5e__PATIENTS_TELEPHONE_code: '_5e__PATIENTS_TELEPHONE',
  _7e__INSUREDS_TELEPHONE_code: '_7e__INSUREDS_TELEPHONE',
  '33_billing_phone_code': '33_billing_phone'
};

export const DIAGNOSIS_CODES_MAP = {
  a: HCFA_FIELDS.dxCodeA,
  b: HCFA_FIELDS.dxCodeB,
  c: HCFA_FIELDS.dxCodeC,
  d: HCFA_FIELDS.dxCodeD,
  e: HCFA_FIELDS.dxCodeE,
  f: HCFA_FIELDS.dxCodeF,
  g: HCFA_FIELDS.dxCodeG,
  h: HCFA_FIELDS.dxCodeH,
  i: HCFA_FIELDS.dxCodeI,
  j: HCFA_FIELDS.dxCodeJ,
  k: HCFA_FIELDS.dxCodeK,
  l: HCFA_FIELDS.dxCodeL
};
