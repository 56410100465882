import { RcFile } from 'antd/lib/upload';
import axios from 'axios';

interface FileOptions {
  path: string;
  token: string;
  file?: RcFile;
}

export class FilesOptions {
  paths: string[] = [];
  token: string;
  files: RcFile[] = [];
}

const getS3UploadLink = async ({ paths, token, files }: FilesOptions) => {
  // const params = new URLSearchParams();
  // paths.forEach((p) => params.append('filenames', p));
  const body: { name: string; uploadLength: number }[] = [];
  paths.forEach((p, idx) => {
    body.push({ name: p, uploadLength: files[idx]?.size });
  });
  const res = await axios.post<Record<string, string>>(`${window._env_.API_URL}/api/v1/files/upload`, body, {
    headers: {
      Authorization: `Bearer ${token}`
    }
  });
  return res.data;
};

const getS3DownloadLink = async ({ path, token }: FileOptions) => {
  const res = await axios.post<string>(`${window._env_.API_URL}/api/v1/files/download?filename=${path}`, undefined, {
    headers: {
      Authorization: `Bearer ${token}`
    }
  });
  return res.data;
};

const getS3DeleteLink = async ({ path, token }: FileOptions) => {
  const res = await axios.post<string>(`${window._env_.API_URL}/api/v1/files/delete?filename=${path}`, undefined, {
    headers: {
      Authorization: `Bearer ${token}`
    }
  });
  return res.data;
};

const uploadFiles = async (filesOptions: FilesOptions) => {
  console.log('upload ', filesOptions);
  const uploadLink = await getS3UploadLink(filesOptions);
  const promises = Object.entries(uploadLink).map(([filename, link]) => {
    const idx = filesOptions.paths.findIndex((p) => p === filename);
    return axios.put(link, filesOptions.files[idx]);
  });
  const files = await Promise.all(promises);
  return files.map((f) => f.data);
};

const downloadFile = async (fileOptions: FileOptions) => {
  const downloadLink = await getS3DownloadLink(fileOptions);
  const res = await axios.get<ArrayBuffer>(downloadLink, { responseType: 'arraybuffer' });

  return res.data;
};

const deleteFile = async (fileOptions: FileOptions) => {
  const deleteLink = await getS3DeleteLink(fileOptions);
  const res = await axios.delete(deleteLink);
  return res.data;
};

const deleteStorageFilesUponSave = async ({ deletePaths, token }: { deletePaths: string[]; token: string }) => {
  if (!deletePaths.length) return;
  Promise.all(deletePaths.map((path) => deleteFile({ path, token })));
};

export { uploadFiles, downloadFile, deleteFile, deleteStorageFilesUponSave };
