import { UB04_FIELDS } from './fields';

export const HOURS = [
  '00',
  '01',
  '02',
  '03',
  '04',
  '05',
  '06',
  '07',
  '08',
  '09',
  '10',
  '11',
  '12',
  '13',
  '14',
  '15',
  '16',
  '17',
  '18',
  '19',
  '20',
  '21',
  '22',
  '23'
];

export const ADMISSION_TYPE = ['1', '2', '3', '4', '5', '9'];
export const ADMISSION_SOURCE = ['1', '2', '3', '4', '5', '6', '7', '8', '9'];
export const DISCHARGE_STATUS = [
  '01',
  '02',
  '03',
  '04',
  '05',
  '06',
  '07',
  '08',
  '09',
  '20',
  '21',
  '30',
  '40',
  '41',
  '42',
  '43',
  '50',
  '51',
  '61',
  '62',
  '63',
  '64',
  '65',
  '66'
];

export const RELATIONSHIP_CODES = ['01', '18', '19', '20', '21', '39', '40', '53', 'G8'];
export const DIAGNOSIS_CODES = ['1', 'Y', 'N', 'U', 'W'];

export const ADDITIONAL_CODES = ['a', 'b', 'c', 'd', 'e', 'f', 'g', 'h', 'i', 'j', 'k', 'l', 'm', 'n', 'o', 'p', 'q'];

export const TYPE_OF_BILL = [
  '111',
  '117',
  '118',
  '121',
  '127',
  '128',
  '131',
  '137',
  '138',
  '141',
  '147',
  '148',
  '181',
  '187',
  '188',
  '211',
  '217',
  '218',
  '221',
  '227',
  '228',
  '231',
  '237',
  '238',
  '281',
  '287',
  '288',
  '321',
  '327',
  '328',
  '331',
  '337',
  '338',
  '341',
  '347',
  '348',
  '411',
  '417',
  '418',
  '431',
  '437',
  '438',
  '651',
  '657',
  '658',
  '661',
  '667',
  '668',
  '711',
  '717',
  '718',
  '721',
  '727',
  '728',
  '731',
  '737',
  '738',
  '741',
  '747',
  '748',
  '751',
  '757',
  '758',
  '761',
  '767',
  '768',
  '771',
  '777',
  '778',
  '781',
  '787',
  '788',
  '791',
  '797',
  '798',
  '811',
  '817',
  '818',
  '821',
  '827',
  '828',
  '831',
  '837',
  '838',
  '841',
  '847',
  '848',
  '851',
  '857',
  '858',
  '861',
  '867',
  '868',
  '891',
  '897',
  '898'
];

export const INSURED = [
  {
    title: 'INSURED A',
    lastNameId: UB04_FIELDS.insured58aLastName,
    firstNameId: UB04_FIELDS.insured58aFirstName,
    middleInitialId: UB04_FIELDS.insured58aMiddleInitial,
    generationId: UB04_FIELDS.insured58aGen,
    patientsRelationshipId: UB04_FIELDS.insured59aRelationship,
    uniqueIdentifierId: UB04_FIELDS.insured60aUniqueId,
    groupNameId: UB04_FIELDS.insured61aGroupName,
    groupNumberId: UB04_FIELDS.insured62aGroupNumber
  },
  {
    title: 'INSURED B',
    lastNameId: UB04_FIELDS.insured58bLastName,
    firstNameId: UB04_FIELDS.insured58bFirstName,
    middleInitialId: UB04_FIELDS.insured58bMiddleInitial,
    generationId: UB04_FIELDS.insured58bGen,
    patientsRelationshipId: UB04_FIELDS.insured59bRelationship,
    uniqueIdentifierId: UB04_FIELDS.insured60bUniqueId,
    groupNameId: UB04_FIELDS.insured61bGroupName,
    groupNumberId: UB04_FIELDS.insured62bGroupNumber
  },
  {
    title: 'INSURED C',
    lastNameId: UB04_FIELDS.insured58cLastName,
    firstNameId: UB04_FIELDS.insured58cFirstName,
    middleInitialId: UB04_FIELDS.insured58cMiddleInitial,
    generationId: UB04_FIELDS.insured58cGen,
    patientsRelationshipId: UB04_FIELDS.insured59cRelationship,
    uniqueIdentifierId: UB04_FIELDS.insured60cUniqueId,
    groupNameId: UB04_FIELDS.insured61cGroupName,
    groupNumberId: UB04_FIELDS.insured62cGroupNumber
  }
];

export const OTHER_PROVIDERS = [
  {
    title: 'ATTENDING PROVIDER',
    qualifierId: UB04_FIELDS.attendingProviderQual,
    npiId: UB04_FIELDS.attendingProviderNpi,
    identifierId: UB04_FIELDS.attendingProviderId,
    lastNameId: UB04_FIELDS.attendingProviderLastName,
    firstNameId: UB04_FIELDS.attendingProviderFirstName,
    middleInitialId: UB04_FIELDS.attendingProviderMiddleInitial,
    generationId: UB04_FIELDS.attendingProviderGender,
    otherType: null
  },
  {
    title: 'OPERATING PROVIDER',
    qualifierId: UB04_FIELDS.operatingProviderQual,
    npiId: UB04_FIELDS.operatingProviderNpi,
    identifierId: UB04_FIELDS.operatingProviderId,
    lastNameId: UB04_FIELDS.operatingProviderLastName,
    firstNameId: UB04_FIELDS.operatingProviderFirstName,
    middleInitialId: UB04_FIELDS.operatingProviderMiddleInitial,
    generationId: UB04_FIELDS.operatingProviderGender,
    otherType: null
  },
  {
    title: 'OTHER PROVIDER 1',
    qualifierId: UB04_FIELDS.otherProvider1Qual,
    identifierId: UB04_FIELDS.otherProvider1Id,
    npiId: UB04_FIELDS.otherProvider1Npi,
    lastNameId: UB04_FIELDS.otherProvider1LastName,
    firstNameId: UB04_FIELDS.otherProvider1FirstName,
    middleInitialId: UB04_FIELDS.otherProvider1MiddleInitial,
    generationId: UB04_FIELDS.otherProvider1Gender,
    otherType: UB04_FIELDS.otherType78
  },
  {
    title: 'OTHER PROVIDER 2',
    qualifierId: UB04_FIELDS.otherProvider2Qual,
    identifierId: UB04_FIELDS.otherProvider2Id,
    npiId: UB04_FIELDS.otherProvider2Npi,
    lastNameId: UB04_FIELDS.otherProvider2LastName,
    firstNameId: UB04_FIELDS.otherProvider2FirstName,
    middleInitialId: UB04_FIELDS.otherProvider2MiddleInitial,
    generationId: UB04_FIELDS.otherProvider2Gender,
    otherType: UB04_FIELDS.otherType79
  }
];
