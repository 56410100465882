import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { FORM_ACTIONS } from 'config/constants';

type Labels = { key: string; label: string }[];

type Context = [Labels, React.Dispatch<React.SetStateAction<Labels>>];

const BreadcrumbContext = React.createContext<Context | undefined>(undefined);

function BreadcrumbProvider({ children }: { children: React.ReactNode }): JSX.Element {
  const [labels, setLabels] = React.useState<Labels>([]);

  return <BreadcrumbContext.Provider value={[labels, setLabels]}>{children}</BreadcrumbContext.Provider>;
}

function useBreadcrumb(): {
  readonly breadcrumbItems: {
    key: string;
    to: string;
    name: string;
  }[];
  readonly updateBreadcrumb: React.Dispatch<React.SetStateAction<Labels>>;
} {
  const context = React.useContext(BreadcrumbContext);
  if (!context) {
    throw new Error('useBreadcrumb must be used within a BreadcrumbProvider');
  }
  const [labels, setLabels] = context;
  const { t } = useTranslation();
  const locations = useLocation().pathname.split('/').filter(Boolean);

  const breadcrumbItems = React.useMemo(() => {
    const items: any[] = [];
    const excludedPath: string[] = [FORM_ACTIONS.EDIT as string, FORM_ACTIONS.COPY as string];

    // if (locations[0] !== 'dashboard') {
    //   items.push({ key: 'dashboard', to: '/', name: t('pages.dashboard') });
    // }

    let currentPath = '';
    locations.forEach((location) => {
      currentPath += `/${location}`;

      if (!excludedPath.includes(location)) {
        const labelLabel = labels?.find((_label) => _label.key === location)?.label;
        items.push({
          key: location,
          to: currentPath,
          name: labelLabel ?? t(`pages.${location}`, location)
        });
      }
    });
    return items;
  }, [locations, t, labels]);
  return { breadcrumbItems, updateBreadcrumb: setLabels } as const;
}

export { BreadcrumbProvider, useBreadcrumb };
