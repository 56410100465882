import React from 'react';

const AppFooter = () => {
  return (
    <footer className="footer">
      <div className="poweredByText">Powered by Sympraxis</div>
    </footer>
  );
};

export default AppFooter;
