import moment from 'moment';

function mapFormValue(formValue: string | number | null | moment.Moment) {
  if (typeof formValue === 'string') return formValue;
  if (typeof formValue === 'number' || typeof formValue === 'boolean') return `${formValue}`;
  if (moment.isMoment(formValue)) return `${formValue.unix() * 1000}`;
  return formValue;
}

export function mapUIValueToDTO(formValue: string | number | string[] | null | moment.Moment) {
  if (Array.isArray(formValue)) return formValue.map((v) => ({ value: mapFormValue(v) }));
  return [{ value: mapFormValue(formValue) }];
}
