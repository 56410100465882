import * as React from 'react';
import { useLocation } from 'react-router-dom';

function NotFound(): JSX.Element {
  const location = useLocation();
  return (
    <>
      <h1>Page {location.pathname} could not be found</h1>
    </>
  );
}

export default NotFound;
