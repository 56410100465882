import * as React from 'react';
import { Form, Input, Select } from 'antd';
import { useTranslation } from 'react-i18next';
import { getYupValidator, getYupWarning } from 'utils/ub04/validataions';
import { toPositiveNumber } from 'helpers/toPositiveNumber';
import { FormContext } from 'components/common/FormProvider';
import { onSearchSelect } from 'utils/search/search';
import { getFormProvidersDataFromGlobalObject } from 'utils/hcfa/putFormProvidersDataInGlobalObject';

type Fields = 'npi' | 'identifier' | 'qualifier';

type FieldsetFullNameProps = {
  npiId: string;
  identifierId: string;
  qualifierId: string;
  labels?: Partial<Record<Fields, string>>;
  otherType?: any;
  index?: number;
};

const QUALIFIERS = ['0B', '1G', 'G2', 'LU'];

function FieldsetProviderIds(props: FieldsetFullNameProps): JSX.Element {
  const { npiId, identifierId, qualifierId, labels, otherType, index } = props;
  const { t } = useTranslation();
  const { form } = React.useContext(FormContext);

  return (
    <div className="flex">
      {otherType && (
        <Form.Item
          label="Other type"
          name={otherType}
          rules={[getYupValidator(t, otherType, getFormProvidersDataFromGlobalObject())]}
        >
          <Input min={3} max={50} onInput={(e: any) => (e.target.value = e.target.value.toUpperCase())} />
        </Form.Item>
      )}

      <Form.Item
        className="field--first"
        label={labels?.npi || 'npi'}
        name={npiId}
        rules={[getYupValidator(t, npiId, getFormProvidersDataFromGlobalObject())]}
      >
        <Input onInput={toPositiveNumber} placeholder="123456789" />
      </Form.Item>
      <Form.Item
        label={labels?.qualifier || 'qualifier'}
        name={qualifierId}
        rules={[getYupValidator(t, qualifierId, getFormProvidersDataFromGlobalObject())]}
      >
        <Select
          options={QUALIFIERS.map((el: any) => ({ label: el, value: el }))}
          allowClear
          showSearch
          onSearch={(input) => {
            onSearchSelect(form, qualifierId, input, QUALIFIERS);
          }}
        />
      </Form.Item>
      <Form.Item
        label={labels?.identifier || 'identifier'}
        name={identifierId}
        rules={[getYupValidator(t, identifierId, getFormProvidersDataFromGlobalObject())]}
      >
        <Input min={3} max={50} onInput={(e: any) => (e.target.value = e.target.value.toUpperCase())} />
      </Form.Item>
    </div>
  );
}

export default FieldsetProviderIds;
