export const GLOBAL_FORM_PROVIDERS_DATA = 'GLOBAL_FORM_PROVIDERS_DATA';

export function putFormProvidersDataInGlobalObject(data: Record<string, any>) {
  //@ts-ignore
  window[GLOBAL_FORM_PROVIDERS_DATA] = data;
}

export function getFormProvidersDataFromGlobalObject(): Record<string, any> {
  //@ts-ignore
  return window[GLOBAL_FORM_PROVIDERS_DATA] as Record<string, any>;
}

export function editTableDataInGlobalObject(path: [string, number, string], value: any) {
  const [tableName, index, fieldName] = path;
  const currentData = getFormProvidersDataFromGlobalObject();
  const tableData = currentData[tableName];
  if (!tableData) return;
  const row = tableData[index];
  if (row && fieldName) {
    row[fieldName] = value;
  }
  putFormProvidersDataInGlobalObject(currentData);
}
