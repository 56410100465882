export const handleRevenueCode: React.FormEventHandler<HTMLInputElement> = (e) => {
  const target = e.target as HTMLInputElement;
  const data = e.nativeEvent as InputEvent;
  if (data.data) {
    if (target.value.length === 3) {
      target.value = '0' + target.value;
    }
    if (target.value.length === 5 && target.value.startsWith('0')) target.value = target.value.slice(1);
  }
};
