import { useOidcAccessToken } from '@axa-fr/react-oidc';
import { RolesEnum } from 'models/enums/RolesEnum';

export const useAccessTokenRoleInfo = () => {
  const { accessTokenPayload } = useOidcAccessToken();
  const userRoles: string[] | undefined = accessTokenPayload?.realm_access?.[RolesEnum.roles];
  return {
    userRoles
  };
};
