import * as yup from 'yup';
import { TFunction } from 'i18next';

import { DENTAL_FIELDS } from './fields';
import { Rule } from 'antd/lib/form';
import { StoreValue } from 'antd/lib/form/interface';
import {
  testCompanyNameAddress,
  testPatientInsuredRegardingRelation,
  testInsCompanyAddress,
  testSubLastName,
  testSubNameAddress,
  testPatLastName,
  testPatAddress,
  testPatientsRelationship2,
  testOriginalRefNumToRessubmission,
  testSubSignatureInd,
  testPatGuardSignature,
  testBillingOrTreatingNpi,
  testTreatFirstName,
  testTreatLastName,
  testTreatSign,
  testBillingFirstName,
  testBillingLastName,
  testAnyAccident,
  testReplacePros,
  testOrthoTreatment,
  testPatientDateOfBirth,
  testPatFirstName,
  testSubscriberFirstName,
  testFutureDate,
  testSubFirstName,
  testPatientsRelationshipIsSelf,
  testRelationRegardingPatientInsured,
  testBillingAddress,
  testAccidentStateWhenAutoAccident,
  testTreatAddress,
  testPatState,
  testPatRel,
  testTypeOfTransaction,
  testNPIforLuhnAlghoritm,
  testPatientsRelationship22isOtherThenSelf,
  testreatNpi,
  testHasBillingFirstName,
  testHasBillingLastName,
  testNpi54,
  testSubscriberLastName,
  testSubIdIsNumeric,
  testSubIdIsAlphanumeric,
  testSubIdLength,
  testICD002,
  testSubIdAlphanumericLenght,
  testOtherCoverage1,
  testOtherCoverage2,
  testOtherCoverage3,
  testRequiredByOtherCoverage,
  testNpi49ForFirstName,
  testNpi49ForLastName,
  testNpi49ForOrganizationName,
  testPhysicalAddress
} from './customRules';

import { dateInvalidMsg, diagnoseCodeLookupMsg } from 'utils/hcfa/validations';
import {
  diagnosisCodeLookup,
  testIsDateValid,
  testProcedureCodeLookup,
  testZipCode5or9IgnoreHyphens,
  zipCodeLookup
} from 'utils/hcfa/customRules';
import { futureDaysAnd100YearPast } from 'utils/common';
import { getDentalWarningSchema, knownWarnings } from './warningSchema';
import { getFormProvidersDataFromGlobalObject } from 'utils/hcfa/putFormProvidersDataInGlobalObject';

const alphaWithSpecChar = /^[A-Za-z !@#$%^&*()_+{}\[\]:;<>,.?~\\/'"`-]+$/;
const alphaNumericWithSpecChar = /^[A-Za-z0-9 !@#$%^&*()_+{}\[\]:;<>,.?~\\/'"`-]+$/;
const alphaNum = /^[a-zA-Z0-9]+$/;
const toothSystem = /^[JP]+$/;
const toothSurfaceValidCharacters = /^[MILONBDF]+$/;
const alphaNumWithHyphen = /^[a-zA-Z0-9-]+$/;
const alphaNumWithHyphenAndComma = /^[a-zA-Z0-9,-]+$/;
const alphaNumWithHyphenAndCommaAH = /^[A-Ha-h0-9,-]+$/;
const diagnosisPointerAL = /^[A-D1-4]+$/;
export const numericLengthFiveOrNine = /^(\d{5}|\d{9})$/;
const cliaNumber = /^\d{2}D\d{7}$/;
const alpha = /^[A-Za-z]+$/;
const alphaWithoutSpecificWords = /^(?!SAME$|SAME AS$|SELF$)[A-Za-z]+$/;
const alphaSpace = /^[A-Za-z ]+$/;
const numeric = /^[0-9]+$/;
const zeroOrNine = /^(0|9)$/;
const alphaNumWithDecimal = /^[A-Z]\d{2}[A-Z0-9]{0,4}(\.\d+)?$/;
const numericWithDotAndHypen = /^[0-9.-]+$/;
const decimalsWithMinus = /^-?\d*\.?\d+$/;
const statePattern = /^[a-zA-Z]{2}$/;

const decimalsWithMinusVal = '';
const alphaWithSpecCharVal = '';
const alphaNumericWithSpecCharVal = '';
const alphaNumVal = '[A-Z and 0-9]';
const toothSurfaceValidCharactersVal = 'M,I,L,O,B,D,F';
const toothSystemVal = 'JP';
const alphaNumWithHyphenVal = '[A-Z, 0-9 and -]';
const alphaNumWithHyphenAndCommaVal = '[A-Z, 0-9, - and ,]';
const alphaNumWithHyphenAndCommaAHVal = '';
const diagnosisPointerALVal = '[A-D], [1-4]';
const cliaNumberVal = '10 digits and 3rd character has to be "D"';
const alphaVal = '[A-Z]';
const alphaSpaceVal = '[A-Z]';
const numericVal = '[0-9]';
const justNineVal = '[9]';
const diagCodeListVal = '[0, 9, B, AB]';
const diagCodeList = /^(B|AB|0|9)$/;
const numericLengthFiveOrNineVal = '[5 or 9]';
const alphaNumWithDecimalVal = '';
const numericWithDotAndHypenVal = '0 to 9.-';
const alphaNumStartsEndsWithNumber = /^\d.*\d$/;
const alphaNumStartsEndsWithNumberVal = 'that starts and ends with a number and contains only leters and numbers';
const commonNameValidation = (t: TFunction, requiredMessage: string = '') => {
  let schema = yup
    .string()
    .nullable()
    .transform((v, o) => (o === '' ? null : v))
    .min(2, t('common:fieldErrors.min', { n: 2 }))
    .max(60, t('common:fieldErrors.max', { n: 60 }))
    .matches(/^$|[A-Za-z0-9\-\s]/, t('common:fieldErrors.insuredId'))
    .matches(/^(?!SAME$|SAME AS$|SELF$)[A-Za-z0-9\s-]+$/, t('common:fieldErrors.sameSameAsSelf'));

  if (requiredMessage) {
    schema = yup
      .string()
      .nullable()
      .transform((v, o) => (o === '' ? null : v))
      .required(requiredMessage)
      .min(2, t('common:fieldErrors.min', { n: 2 }))
      .max(60, t('common:fieldErrors.max', { n: 60 }))
      .matches(/^$|[A-Za-z0-9\-\s]/, t('common:fieldErrors.insuredId'))
      .matches(/^(?!SAME$|SAME AS$|SELF$)[A-Za-z0-9\-\s]+$/, t('common:fieldErrors.sameSameAsSelf'));
  }

  return schema;
};

const commonAddressValidation = (t: TFunction, requiredMessage: string = '') => {
  let schema = yup
    .string()
    .nullable()
    .transform((v, o) => (o === '' ? null : v))
    .matches(alphaNumericWithSpecChar, t('common:fieldErrors.VALID001', { validation: alphaNumericWithSpecCharVal }))
    .min(1, t('common:fieldErrors.LEN001', { length: '(1-55)' }))
    .max(55, t('common:fieldErrors.LEN001', { length: '(1-55)' }));

  // if (requiredMessage) {
  //   schema = yup
  //     .string()
  //     .nullable()
  //     .transform((v, o) => (o === '' ? null : v))
  //     .required(requiredMessage)
  //     .min(3, t('common:fieldErrors.min', { n: 3 }))
  //     .max(30, t('common:fieldErrors.max', { n: 30 }))
  //     .matches(/^$|[A-Za-z0-9\s]/, t('common:fieldErrors.address'))
  //     .matches(/^(?!SAME$|SAME AS$|SELF$)[A-Za-z0-9\s]+$/, t('common:fieldErrors.sameSameAsSelf'));
  // }

  return schema;
};

const commonCityValidation = (t: TFunction, requiredMessage: string = '') => {
  let schema = yup
    .string()
    .nullable()
    .transform((v, o) => (o === '' ? null : v))
    .matches(alphaSpace, t('common:fieldErrors.VALID001', { validation: alphaSpaceVal }))
    .min(1, t('common:fieldErrors.LEN001', { length: '(1-30)' }))
    .max(30, t('common:fieldErrors.LEN001', { length: '(1-30)' }));

  // if (requiredMessage) {
  //   schema = yup
  //     .string()
  //     .nullable()
  //     .transform((v, o) => (o === '' ? null : v))
  //     .required(requiredMessage)
  //     .max(24, t('common:fieldErrors.max', { n: 24 }))
  //     .matches(/^$|[A-Za-z\s-]/, t('common:fieldErrors.city'));
  // }

  return schema;
};

const commonZipcodeValidation = (t: TFunction, requiredMessage: string = '') => {
  let schema = yup
    .string()
    .nullable()
    .transform((v, o) => (o === '' ? null : v))
    .test(
      'common-zipCode',
      t('common:fieldErrors.VALID001', { validation: numericLengthFiveOrNineVal }),
      testZipCode5or9IgnoreHyphens
    );
  // .matches(numericLengthFiveOrNine, t('common:fieldErrors.VALID001', { validation: numericLengthFiveOrNineVal }));
  // .test(
  //   'zip-lookup',
  //   'Please review the entered value, it is not found in the standard list of values',
  //   zipCodeLookup
  // );

  if (requiredMessage) {
    schema = yup
      .string()
      .nullable()
      .transform((v, o) => (o === '' ? null : v))
      .test(
        'common-zipCode',
        t('common:fieldErrors.VALID001', { validation: numericLengthFiveOrNineVal }),
        testZipCode5or9IgnoreHyphens
      )
      // .matches(numericLengthFiveOrNine, t('common:fieldErrors.VALID001', { validation: numericLengthFiveOrNineVal }))
      .required(t(requiredMessage));
    // .test(
    //   'zip-lookup',
    //   'Please review the entered value, it is not found in the standard list of values',
    //   zipCodeLookup
    // );
  }

  return schema;
};
// const commonZipcodeExtValidation = (t: TFunction, requiredMessage: string = '') => {
//   let schema = yup
//     .string()
//     .nullable()
//     .transform((v, o) => (o === '' ? null : v))
//     .matches(numeric, t('common:fieldErrors.VALID001', { validation: numericVal }))
//     .min(4, t('common:fieldErrors.LEN001', { length: '(4)' }))
//     .max(4, t('common:fieldErrors.LEN001', { length: '(4)' }));

//   if (requiredMessage) {
//     schema = yup
//       .string()
//       .nullable()
//       .transform((v, o) => (o === '' ? null : v))
//       .matches(numeric, t('common:fieldErrors.VALID001', { validation: numericVal }))
//       .min(4, t('common:fieldErrors.LEN001', { length: '(4)' }))
//       .max(4, t('common:fieldErrors.LEN001', { length: '(4)' }))
//       .required(t(requiredMessage));
//   }

//   return schema;
// };

const commonDiagnosisCodeValidation = (t: TFunction) =>
  yup
    .string()
    .nullable()
    .transform((v, o) => (o === '' ? null : v))
    .min(3, t('common:fieldErrors.LEN001', { length: '(3-7)' }))
    .max(7, t('common:fieldErrors.LEN001', { length: '(3-7)' }))
    .matches(alphaNumWithDecimal, t('common:fieldErrors.VALID001', { validation: alphaNumWithDecimalVal }));
//.required(t('common:fieldErrors.MUST001'));

export const getDentalSchema = (t: TFunction) =>
  yup.object().shape({
    [DENTAL_FIELDS.predeterminationNo]: yup
      .string()
      .nullable()
      .transform((v, o) => (o === '' ? null : v))
      .matches(alphaNum, t('common:fieldErrors.VALID001', { validation: alphaNumVal }))
      .min(1, t('common:fieldErrors.LEN001', { length: '(1-30)' }))
      .max(30, t('common:fieldErrors.LEN001', { length: '(1-30)' })),
    [DENTAL_FIELDS.companyName]: yup
      .string()
      .nullable()
      .transform((v, o) => (o === '' ? null : v))
      .matches(alphaNumericWithSpecChar, t('common:fieldErrors.VALID001', { validation: alphaNumericWithSpecCharVal }))
      .min(1, t('common:fieldErrors.LEN001', { length: '(1-60)' }))
      .max(60, t('common:fieldErrors.LEN001', { length: '(1-60)' })),
    [DENTAL_FIELDS.companyNameAddress]: commonAddressValidation(t),
    [DENTAL_FIELDS.companyCity]: commonCityValidation(t).test(
      'custom-companyCity',
      t('common:fieldErrors.ADD001'),
      testCompanyNameAddress
    ),
    [DENTAL_FIELDS.companyState]: yup
      .string()
      .nullable()
      .transform((v, o) => (o === '' ? null : v))
      .test('custom-companyState', t('common:fieldErrors.ADD001'), testCompanyNameAddress)
      .matches(statePattern, 'State field should be 2 characters'),
    [DENTAL_FIELDS.companyZipcode]: commonZipcodeValidation(t).test(
      'custom-companyZipcode',
      t('common:fieldErrors.ADD001'),
      testCompanyNameAddress
    ),
    // [DENTAL_FIELDS.companyZipcodeExt]: commonZipcodeExtValidation(t),
    ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    [DENTAL_FIELDS.subscriberLastName]: yup
      .string()
      .nullable()
      .transform((v, o) => (o === '' ? null : v))
      .matches(alphaWithSpecChar, t('common:fieldErrors.VALID001', { validation: alphaWithSpecCharVal }))
      .min(1, t('common:fieldErrors.LEN001', { length: '(1-60)' }))
      .max(60, t('common:fieldErrors.LEN001', { length: '(1-60)' }))
      .test('custom-subscriberLastName', t('common:fieldErrors.REL001'), testPatientsRelationshipIsSelf)
      //.test('custom-subscriberLastNameOtherCoverage1', t('common:fieldErrors.COM008'), testOtherCoverage1)
      .test('custom-subscriberLastName', t('common:fieldErrors.NAME001'), testSubscriberFirstName),
    [DENTAL_FIELDS.subscriberFirstName]: yup
      .string()
      .nullable()
      .transform((v, o) => (o === '' ? null : v))
      .matches(alphaWithSpecChar, t('common:fieldErrors.VALID001', { validation: alphaWithSpecCharVal }))
      .min(1, t('common:fieldErrors.LEN001', { length: '(1-35)' }))
      .max(35, t('common:fieldErrors.LEN001', { length: '(1-35)' }))
      .test('custom-subscriberFirstName', t('common:fieldErrors.REL001'), testPatientsRelationshipIsSelf)
      //.test('custom-subscriberLastNameOtherCoverage2', t('common:fieldErrors.COM008'), testOtherCoverage2)
      .test('custom-subscriberLastName', t('common:fieldErrors.NAME001'), testSubscriberLastName),
    //.required(t('common:fieldErrors.MUST001')),
    [DENTAL_FIELDS.subscriberMiddle]: yup
      .string()
      .nullable()
      .transform((v, o) => (o === '' ? null : v))
      .matches(alpha, t('common:fieldErrors.VALID001', { validation: alphaVal }))
      .min(1, t('common:fieldErrors.LEN001', { length: '(1-25)' }))
      .max(25, t('common:fieldErrors.LEN001', { length: '(1-25)' })),
    [DENTAL_FIELDS.subscriberSuffix]: yup
      .string()
      .nullable()
      .transform((v, o) => (o === '' ? null : v))
      .matches(alpha, t('common:fieldErrors.VALID001', { validation: alphaVal }))
      .min(1, t('common:fieldErrors.LEN001', { length: '(1-10)' }))
      .max(10, t('common:fieldErrors.LEN001', { length: '(1-10)' })),
    [DENTAL_FIELDS.subSuffix]: yup
      .string()
      .nullable()
      .transform((v, o) => (o === '' ? null : v))
      .matches(alpha, t('common:fieldErrors.VALID001', { validation: alphaVal }))
      .min(1, t('common:fieldErrors.LEN001', { length: '(1-10)' }))
      .max(10, t('common:fieldErrors.LEN001', { length: '(1-10)' })),
    [DENTAL_FIELDS.patSuffix]: yup
      .string()
      .nullable()
      .transform((v, o) => (o === '' ? null : v))
      .matches(alpha, t('common:fieldErrors.VALID001', { validation: alphaVal }))
      .min(1, t('common:fieldErrors.LEN001', { length: '(1-10)' }))
      .max(10, t('common:fieldErrors.LEN001', { length: '(1-10)' })),
    ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    [DENTAL_FIELDS.subscriberDateOfBirth]: yup
      .string()
      .nullable()
      // .required(t('common:fieldErrors.COM008'))
      .test('custom-subscriberDateOfBirthfuture', t('common:fieldErrors.DATE001'), testFutureDate)
      .test('custom-validDate', dateInvalidMsg, testIsDateValid),
    [DENTAL_FIELDS.subscriberNumber]: yup
      .string()
      .nullable()
      .transform((v, o) => (o === '' ? null : v))
      .matches(alphaNum, t('common:fieldErrors.VALID001', { validation: alphaNumVal }))
      .min(2, t('common:fieldErrors.LEN001', { length: '(2-80)' }))
      .max(80, t('common:fieldErrors.LEN001', { length: '(2-80)' }))
      .test('custom-requiredSubscriberNumber', t('common:fieldErrors.COM008'), testRequiredByOtherCoverage),
    [DENTAL_FIELDS.planGroupNumber]: yup
      .string()
      .nullable()
      .transform((v, o) => (o === '' ? null : v))
      .matches(alphaNum, t('common:fieldErrors.VALID001', { validation: alphaNumVal }))
      .min(2, t('common:fieldErrors.LEN001', { length: '(2-80)' }))
      .max(80, t('common:fieldErrors.LEN001', { length: '(2-80)' }))
      .test('custom-requiredSubscriberNumber', t('common:fieldErrors.COM008'), testRequiredByOtherCoverage),
    //.required(t('common:fieldErrors.COM008')),
    // [DENTAL_FIELDS.patientsRelationship]: yup.string().nullable().required(t('common:fieldErrors.COM008')),
    [DENTAL_FIELDS.patientsRelationship]: yup
      .string()
      .nullable()
      .test('custom-requiredSubscriberNumber', t('common:fieldErrors.COM008'), testRequiredByOtherCoverage),

    [DENTAL_FIELDS.dentalBenefitPlanName]: yup
      .string()
      .nullable()
      .transform((v, o) => (o === '' ? null : v))
      .matches(alphaNumericWithSpecChar, t('common:fieldErrors.VALID001', { validation: alphaNumericWithSpecCharVal }))
      .min(1, t('common:fieldErrors.LEN001', { length: '(1-60)' }))
      .max(60, t('common:fieldErrors.LEN001', { length: '(1-60)' }))
      .test('custom-requiredSubscriberNumber', t('common:fieldErrors.COM008'), testRequiredByOtherCoverage),
    //.required(t('common:fieldErrors.COM008')),
    [DENTAL_FIELDS.insCompanyAddress]: yup
      .string()
      .nullable()
      .transform((v, o) => (o === '' ? null : v))
      .matches(alphaNumericWithSpecChar, t('common:fieldErrors.VALID001', { validation: alphaNumericWithSpecCharVal }))
      .min(1, t('common:fieldErrors.LEN001', { length: '(1-55)' }))
      .max(55, t('common:fieldErrors.LEN001', { length: '(1-55)' }))
      .test('custom-requiredSubscriberNumber', t('common:fieldErrors.COM008'), testRequiredByOtherCoverage),
    //.required(t('common:fieldErrors.COM008')),
    [DENTAL_FIELDS.insCompanyCity]: yup
      .string()
      .nullable()
      .transform((v, o) => (o === '' ? null : v))
      .matches(alphaSpace, t('common:fieldErrors.VALID001', { validation: alphaSpaceVal }))
      .min(1, t('common:fieldErrors.LEN001', { length: '(1-30)' }))
      .max(30, t('common:fieldErrors.LEN001', { length: '(1-30)' }))
      .test('custom-insCompanyCity', t('common:fieldErrors.ADD001'), testInsCompanyAddress),
    [DENTAL_FIELDS.insCompanyState]: yup
      .string()
      .nullable()
      .transform((v, o) => (o === '' ? null : v))
      .test('custom-insCompanyState', t('common:fieldErrors.ADD001'), testInsCompanyAddress)
      .matches(statePattern, 'State field should be 2 characters'),
    [DENTAL_FIELDS.insCompanyZipcode]: commonZipcodeValidation(t).test(
      'custom-insCompanyZipcode',
      t('common:fieldErrors.ADD001'),
      testInsCompanyAddress
    ),
    // [DENTAL_FIELDS.insCompanyZipcodeExt]: commonZipcodeExtValidation(t),
    ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    [DENTAL_FIELDS.subLastName]: yup
      .string()
      .nullable()
      .transform((v, o) => (o === '' ? null : v))
      .matches(alphaWithoutSpecificWords, t('common:fieldErrors.VALID001', { validation: alphaVal }))
      .min(1, t('common:fieldErrors.LEN001', { length: '(1-60)' }))
      .max(60, t('common:fieldErrors.LEN001', { length: '(1-60)' }))
      .required(t('common:fieldErrors.MUST001'))
      .test('custom-subLastName', t('common:fieldErrors.NAME001'), testSubFirstName),
    [DENTAL_FIELDS.subFirstName]: yup
      .string()
      .nullable()
      .transform((v, o) => (o === '' ? null : v))
      .matches(alphaWithoutSpecificWords, t('common:fieldErrors.VALID001', { validation: alphaVal }))
      .min(1, t('common:fieldErrors.LEN001', { length: '(1-35)' }))
      .max(35, t('common:fieldErrors.LEN001', { length: '(1-35)' }))
      .required(t('common:fieldErrors.MUST001'))
      .test('custom-subFirstName', t('common:fieldErrors.NAME001'), testSubLastName),
    [DENTAL_FIELDS.subMiddle]: yup
      .string()
      .nullable()
      .transform((v, o) => (o === '' ? null : v))
      .matches(alpha, t('common:fieldErrors.VALID001', { validation: alphaVal }))
      .min(1, t('common:fieldErrors.LEN001', { length: '(1-25)' }))
      .max(25, t('common:fieldErrors.LEN001', { length: '(1-25)' })),
    [DENTAL_FIELDS.subInitial]: yup
      .string()
      .nullable()
      .transform((v, o) => (o === '' ? null : v))
      .matches(alpha, t('common:fieldErrors.VALID001', { validation: alphaVal }))
      .min(1, t('common:fieldErrors.LEN001', { length: '(1)' }))
      .max(1, t('common:fieldErrors.LEN001', { length: '(1)' })),

    [DENTAL_FIELDS.subNameAddress]: yup
      .string()
      .nullable()
      .transform((v, o) => (o === '' ? null : v))
      .matches(alphaNumericWithSpecChar, t('common:fieldErrors.VALID001', { validation: alphaNumericWithSpecCharVal }))
      .min(1, t('common:fieldErrors.LEN001', { length: '(1-55)' }))
      .max(55, t('common:fieldErrors.LEN001', { length: '(1-55)' }))
      .required(t('common:fieldErrors.MUST001')),
    [DENTAL_FIELDS.subCity]: yup
      .string()
      .nullable()
      .transform((v, o) => (o === '' ? null : v))
      .matches(alphaSpace, t('common:fieldErrors.VALID001', { validation: alphaSpaceVal }))
      .min(2, t('common:fieldErrors.LEN001', { length: '(2-30)' }))
      .max(30, t('common:fieldErrors.LEN001', { length: '(2-30)' }))
      .required(t('common:fieldErrors.MUST001'))
      .test('custom-subCity', t('common:fieldErrors.ADD001'), testSubNameAddress),
    [DENTAL_FIELDS.subState]: yup
      .string()
      .nullable()
      .transform((v, o) => (o === '' ? null : v))
      //.matches(alphaSpace, t('common:fieldErrors.VALID001', { validation: alphaSpaceVal }))
      .matches(statePattern, 'State field should be 2 characters')
      .test('custom-subState', t('common:fieldErrors.ADD001'), testSubNameAddress)
      .test('custom-subState', t('common:fieldErrors.MUST001'), testPatState)
      .test('custom-subState', t('common:fieldErrors.MUST001'), testPatRel)
      .required(t('common:fieldErrors.MUST001')),
    [DENTAL_FIELDS.subZip]: commonZipcodeValidation(t).test(
      'custom-insuredFirstName',
      t('common:fieldErrors.ADD001'),
      testSubNameAddress
    ),
    // [DENTAL_FIELDS.subZipExt]: commonZipcodeExtValidation(t),
    ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    [DENTAL_FIELDS.subDateOfBirth]: yup
      .string()
      .nullable()
      .required(t('common:fieldErrors.MUST001'))
      .test('custom-subDateOfBirthfuture', t('common:fieldErrors.DATE001'), testFutureDate)
      .test('custom-validDate', dateInvalidMsg, testIsDateValid),
    [DENTAL_FIELDS.typeOfTransaction]: yup
      .array()
      .of(yup.string())
      .nullable()
      .test(
        'custom-typeOfTransaction',
        'Value can not be REQUEST FOR PREDETERMINALION / PREAUTHORIZATION',
        testTypeOfTransaction
      ),

    [DENTAL_FIELDS.subId]: yup
      .string()
      .nullable()
      .transform((v, o) => (o === '' ? null : v))
      .test(
        'custom-subIdIsNumeric',
        t('common:fieldErrors.VALID001', {
          validation: numericVal
        }),
        testSubIdIsNumeric
      )
      .test(
        'custom-subIdIsNumeric',
        t('common:fieldErrors.VALID001', {
          validation: alphaNumStartsEndsWithNumberVal
        }),
        testSubIdIsAlphanumeric
      )
      .test('custom-insuredState', t('common:fieldErrors.LEN001', { length: '(2-80)' }), testSubIdLength)
      .test('custom-insuredState', t('common:fieldErrors.LEN001', { length: '(11)' }), testSubIdAlphanumericLenght),
    //.required(t('common:fieldErrors.MUST001')),
    [DENTAL_FIELDS.subGroupNumber]: yup
      .string()
      .nullable()
      .transform((v, o) => (o === '' ? null : v))
      .matches(alphaNumericWithSpecChar, t('common:fieldErrors.VALID001', { validation: alphaNumericWithSpecChar }))
      .min(1, t('common:fieldErrors.LEN001', { length: '(1-30)' }))
      .max(30, t('common:fieldErrors.LEN001', { length: '(1-30)' })),
    [DENTAL_FIELDS.subEmployerName]: yup
      .string()
      .nullable()
      .transform((v, o) => (o === '' ? null : v))
      .matches(alphaNumericWithSpecChar, t('common:fieldErrors.VALID001', { validation: alphaNumericWithSpecCharVal }))
      .min(1, t('common:fieldErrors.LEN001', { length: '(1-60)' }))
      .max(60, t('common:fieldErrors.LEN001', { length: '(1-60)' })),
    [DENTAL_FIELDS.frequencyCode]: yup
      .string()
      .nullable()
      .transform((v, o) => (o === '' ? null : v)),
    [DENTAL_FIELDS.patientsRelationship2]: yup
      .string()
      .nullable()
      .required(t('common:fieldErrors.MUST001'))
      .test('custom-patientsRelationship2', t('common:fieldErrors.REL002'), testRelationRegardingPatientInsured),
    [DENTAL_FIELDS.reservedForFuture]: yup
      .string()
      .nullable()
      .transform((v, o) => (o === '' ? null : v))
      .matches(alphaNumericWithSpecChar, t('common:fieldErrors.VALID001', { validation: alphaNumericWithSpecCharVal }))
      .min(1, t('common:fieldErrors.LEN001', { length: '(1-10)' }))
      .max(10, t('common:fieldErrors.LEN001', { length: '(1-10)' })),

    [DENTAL_FIELDS.patLastName]: yup
      .string()
      .nullable()
      .transform((v, o) => (o === '' ? null : v))
      .matches(alpha, t('common:fieldErrors.VALID001', { validation: alphaVal }))
      .min(1, t('common:fieldErrors.LEN001', { length: '(1-60)' }))
      .max(60, t('common:fieldErrors.LEN001', { length: '(1-60)' }))
      .test('custom-patLastName', t('common:fieldErrors.NAME001'), testPatFirstName)
      .test('custom-patLastName', t('common:fieldErrors.MUST001'), testPatientsRelationship2),
    [DENTAL_FIELDS.patFirstName]: yup
      .string()
      .nullable()
      .transform((v, o) => (o === '' ? null : v))
      .matches(alpha, t('common:fieldErrors.VALID001', { validation: alphaVal }))
      .min(1, t('common:fieldErrors.LEN001', { length: '(1-35)' }))
      .max(35, t('common:fieldErrors.LEN001', { length: '(1-35)' }))
      .test('custom-patFirstName', t('common:fieldErrors.NAME001'), testPatLastName)
      .test('custom-patLastName', t('common:fieldErrors.MUST001'), testPatientsRelationship2),
    [DENTAL_FIELDS.patMiddle]: yup
      .string()
      .nullable()
      .transform((v, o) => (o === '' ? null : v))
      .matches(alpha, t('common:fieldErrors.VALID001', { validation: alphaVal }))
      .min(1, t('common:fieldErrors.LEN001', { length: '(1-25)' }))
      .max(25, t('common:fieldErrors.LEN001', { length: '(1-25)' })),
    [DENTAL_FIELDS.patientGen]: yup.string().nullable(),
    [DENTAL_FIELDS.patAddress]: commonAddressValidation(t),
    [DENTAL_FIELDS.patCity]: yup
      .string()
      .nullable()
      .transform((v, o) => (o === '' ? null : v))
      .matches(alphaSpace, t('common:fieldErrors.VALID001', { validation: alphaSpaceVal }))
      .min(2, t('common:fieldErrors.LEN001', { length: '(2-30)' }))
      .max(30, t('common:fieldErrors.LEN001', { length: '(2-30)' }))
      .test('custom-patCity', t('common:fieldErrors.ADD001'), testPatAddress),

    [DENTAL_FIELDS.patState]: yup
      .string()
      .nullable()
      .transform((v, o) => (o === '' ? null : v))
      .test('custom-patState', t('common:fieldErrors.ADD001'), testPatAddress)
      //.test('custom-patState', t('common:fieldErrors.MUST001'), testPatientsRelationship22isOtherThenSelf)
      .matches(statePattern, 'State field should be 2 characters')
      .test('custom-patLastName', t('common:fieldErrors.MUST001'), testPatientsRelationship2),
    //.test('custom-patState', t('common:fieldErrors.REL003'), testPatientsRelationship2),
    [DENTAL_FIELDS.patZip]: commonZipcodeValidation(t).test(
      'custom-patZip',
      t('common:fieldErrors.ADD001'),
      testPatAddress
    ),
    // [DENTAL_FIELDS.patZipExt]: commonZipcodeExtValidation(t),
    [DENTAL_FIELDS.patDateOfBirth]: yup
      .string()
      .nullable()
      .test('custom-patDateOfBirth', t('common:fieldErrors.REL003'), testPatientsRelationship22isOtherThenSelf)
      .test('custom-patDateOfBirthfuture', t('common:fieldErrors.DATE001'), testFutureDate)
      .test('custom-validDate', dateInvalidMsg, testIsDateValid),
    [DENTAL_FIELDS.patIdAccNo]: yup
      .string()
      .nullable()
      .transform((v, o) => (o === '' ? null : v))
      .matches(alphaNumericWithSpecChar, t('common:fieldErrors.VALID001', { validation: alphaNumericWithSpecCharVal }))
      .min(1, t('common:fieldErrors.LEN001', { length: '(1-38)' }))
      .max(38, t('common:fieldErrors.LEN001', { length: '(1-38)' })),
    ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    [DENTAL_FIELDS.patientId]: yup
      .string()
      .nullable()
      .transform((v, o) => (o === '' ? null : v))
      .matches(alphaNum, t('common:fieldErrors.VALID001', { validation: alphaNumVal }))
      .min(1, t('common:fieldErrors.LEN001', { length: '(1-38)' }))
      .max(38, t('common:fieldErrors.LEN001', { length: '(1-38)' })),

    [DENTAL_FIELDS.diagCodeList]: yup
      .string()
      .nullable()
      .transform((v, o) => (o === '' ? null : v))
      .min(1, t('common:fieldErrors.LEN001', { length: '(1)' }))
      // .max(1, t('common:fieldErrors.LEN001', { length: '(1)' }))
      .matches(diagCodeList, t('common:fieldErrors.VALID001', { validation: diagCodeListVal })),

    [DENTAL_FIELDS.otherFee1]: yup
      .string()
      .nullable()
      .transform((v, o) => (o === '' ? null : v))
      .matches(decimalsWithMinus, t('common:fieldErrors.VALID001', { validation: decimalsWithMinusVal }))
      .min(1, t('common:fieldErrors.LEN001', { length: '(1-18)' }))
      .max(18, t('common:fieldErrors.LEN001', { length: '(1-18)' })),

    [DENTAL_FIELDS.otherFee2]: yup
      .string()
      .nullable()
      .transform((v, o) => (o === '' ? null : v))
      .matches(decimalsWithMinus, t('common:fieldErrors.VALID001', { validation: decimalsWithMinusVal }))
      .min(1, t('common:fieldErrors.LEN001', { length: '(1-18)' }))
      .max(18, t('common:fieldErrors.LEN001', { length: '(1-18)' })),
    // [DENTAL_FIELDS.totalFee]: yup
    //   .string()
    //   .nullable()
    //   .transform((v, o) => (o === '' ? null : v))
    //   .matches(decimalsWithMinus, t('common:fieldErrors.VALID001', { validation: decimalsWithMinusVal }))
    //   .min(1, t('common:fieldErrors.LEN001', { length: '(1-18)' }))
    //   .max(18, t('common:fieldErrors.LEN001', { length: '(1-18)' }))
    //   .required(t('common:fieldErrors.MUST001')),

    // Task: POR-279
    // [DENTAL_FIELDS.remarks]: yup
    //   .string()
    //   .nullable()
    //   .transform((v, o) => (o === '' ? null : v))
    //   .matches(alphaNum, t('common:fieldErrors.VALID001', { validation: alphaNumericWithSpecCharVal }))
    //   .min(1, t('common:fieldErrors.LEN001', { length: '(1-12)' }))
    //   .max(12, t('common:fieldErrors.LEN001', { length: '(1-12)' }))
    //   .required(t('common:fieldErrors.MUST001')),

    [DENTAL_FIELDS.remarks]: yup
      .string()
      .nullable()
      .transform((v, o) => (o === '' ? null : v))
      .matches(alphaNumericWithSpecChar, t('common:fieldErrors.VALID001', { validation: alphaNumericWithSpecCharVal }))
      .min(2, t('common:fieldErrors.LEN001', { length: '(2-80)' }))
      .max(80, t('common:fieldErrors.LEN001', { length: '(2-80)' })),

    [DENTAL_FIELDS.firstVisitDate]: yup
      .string()
      .nullable()
      .transform((v, o) => (o === '' ? null : v))
      .test('custom-firstVisitDate', t('common:fieldErrors.DOS003'), testPatientDateOfBirth)
      .test('custom-firstVisitDatefuture', t('common:fieldErrors.DATE001'), testFutureDate)
      .test('custom-validDate', dateInvalidMsg, testIsDateValid),

    [DENTAL_FIELDS.placeOfTreat]: yup
      .string()
      .nullable()
      .transform((v, o) => (o === '' ? null : v))
      .matches(alphaNum, t('common:fieldErrors.VALID001', { validation: alphaNumVal }))
      .min(2, t('common:fieldErrors.LEN001', { length: '(2)' }))
      .max(2, t('common:fieldErrors.LEN001', { length: '(2)' })),
    //.required(t('common:fieldErrors.MUST001')),

    [DENTAL_FIELDS.subSignatureDate]: yup
      .string()
      .nullable()
      .transform((v, o) => (o === '' ? null : v))
      .test('custom-subSignatureDate', t('common:fieldErrors.DATE002'), testSubSignatureInd)
      .test('custom-subSignatureDatefuture', t('common:fieldErrors.DATE001'), testFutureDate)
      .test('custom-validDate', dateInvalidMsg, testIsDateValid),

    [DENTAL_FIELDS.patGuardSignatureDate]: yup
      .string()
      .nullable()
      .transform((v, o) => (o === '' ? null : v))
      .test('custom-patGuardSignatureDate', t('common:fieldErrors.DATE002'), testPatGuardSignature)
      .test('custom-patGuardSignatureDatefuture', t('common:fieldErrors.DATE001'), testFutureDate)
      .test('custom-validDate', dateInvalidMsg, testIsDateValid),

    [DENTAL_FIELDS.enclosures]: yup
      .string()
      .nullable()
      .transform((v, o) => (o === '' ? null : v))
      .matches(alpha, t('common:fieldErrors.VALID001', { validation: alphaVal })),

    [DENTAL_FIELDS.orthoTreatment]: yup
      .string()
      .nullable()
      .transform((v, o) => (o === '' ? null : v)),
    //.matches(alpha, t('common:fieldErrors.VALID001', { validation: alphaVal })),
    //.min(1, t('common:fieldErrors.LEN001', { length: '(1)' }))
    //.max(1, t('common:fieldErrors.LEN001', { length: '(1)' })),

    [DENTAL_FIELDS.monthOfTreat]: yup
      .string()
      .nullable()
      .transform((v, o) => (o === '' ? null : v))
      .matches(numeric, t('common:fieldErrors.VALID001', { validation: numericVal }))
      .min(1, t('common:fieldErrors.LEN001', { length: '(1-3)' }))
      .max(3, t('common:fieldErrors.LEN001', { length: '(1-3)' }))
      .test('custom-monthOfTreat', t('common:fieldErrors.DATE002'), testOrthoTreatment),
    [DENTAL_FIELDS.appPlaceDate]: yup
      .string()
      .nullable()
      .transform((v, o) => (o === '' ? null : v))
      .test('custom-appPlaceDate', t('common:fieldErrors.DATE002'), testOrthoTreatment)
      .test('custom-appPlaceDatefuture', t('common:fieldErrors.DATE001'), testFutureDate)
      .test('custom-validDate', dateInvalidMsg, testIsDateValid),

    [DENTAL_FIELDS.priorPlacement]: yup
      .string()
      .nullable()
      .transform((v, o) => (o === '' ? null : v))
      .test('custom-priorPlacement', t('common:fieldErrors.DATE002'), testReplacePros)
      .test('custom-priorPlacementfuture', t('common:fieldErrors.DATE001'), testFutureDate)
      .test('custom-validDate', dateInvalidMsg, testIsDateValid),

    [DENTAL_FIELDS.accDate]: yup
      .string()
      .nullable()
      .transform((v, o) => (o === '' ? null : v))
      .test('custom-accDate', t('common:fieldErrors.DATE003'), testAnyAccident)
      .test('custom-accDatefuture', t('common:fieldErrors.DATE001'), testFutureDate)
      .test('custom-validDate', dateInvalidMsg, testIsDateValid),

    [DENTAL_FIELDS.accState]: yup
      .string()
      .nullable()
      .transform((v, o) => (o === '' ? null : v))
      //.matches(alphaSpace, t('common:fieldErrors.VALID001', { validation: alphaSpaceVal }))
      .test('custom-accState', t('common:fieldErrors.COM004'), testAccidentStateWhenAutoAccident)
      .matches(statePattern, 'State field should be 2 characters'),

    [DENTAL_FIELDS.treatAddress]: yup
      .string()
      .nullable()
      .transform((v, o) => (o === '' ? null : v))
      .matches(alphaNumericWithSpecChar, t('common:fieldErrors.VALID001', { validation: alphaNumericWithSpecCharVal }))
      .min(1, t('common:fieldErrors.LEN001', { length: '(1-55)' }))
      .max(55, t('common:fieldErrors.LEN001', { length: '(1-55)' })),
    // .required(t('common:fieldErrors.MUST001')),

    [DENTAL_FIELDS.treatAddress2]: yup
      .string()
      .nullable()
      .transform((v, o) => (o === '' ? null : v))
      .matches(alphaNumericWithSpecChar, t('common:fieldErrors.VALID001', { validation: alphaNumericWithSpecCharVal }))
      .min(1, t('common:fieldErrors.LEN001', { length: '(1-55)' }))
      .max(55, t('common:fieldErrors.LEN001', { length: '(1-55)' })),
    [DENTAL_FIELDS.treatCity]: yup
      .string()
      .nullable()
      .transform((v, o) => (o === '' ? null : v))
      .matches(alphaSpace, t('common:fieldErrors.VALID001', { validation: alphaSpaceVal }))
      .min(1, t('common:fieldErrors.LEN001', { length: '(1-50)' }))
      .max(50, t('common:fieldErrors.LEN001', { length: '(1-50)' }))
      .test('custom-treatCity', t('common:fieldErrors.ADD001'), testTreatAddress),
    //.required(t('common:fieldErrors.MUST001')),
    [DENTAL_FIELDS.treatState]: yup
      .string()
      .nullable()
      .transform((v, o) => (o === '' ? null : v))
      //.matches(alphaSpace, t('common:fieldErrors.VALID001', { validation: alphaSpaceVal }))
      .matches(statePattern, 'State field should be 2 characters')
      .test('custom-treatState', t('common:fieldErrors.ADD001'), testTreatAddress),
    // .test('custom-patLastName', t('common:fieldErrors.MUST001'), testPatientsRelationship2),
    //.required(t('common:fieldErrors.MUST001')),

    [DENTAL_FIELDS.treatZip]: commonZipcodeValidation(t).test(
      'custom-treatState',
      t('common:fieldErrors.ADD001'),
      testTreatAddress
    ),
    // [DENTAL_FIELDS.treatZipExt]: commonZipcodeExtValidation(t),

    [DENTAL_FIELDS.servicesTable]: yup
      .array()
      .min(1, 'At least one item is required in the Service list')
      .required('Service list is required')
      .of(getDentalServiceTableShape(t)),

    // [DENTAL_FIELDS.patientFirstName]: commonNameValidation(t, 'Patient first name is required'),
    [DENTAL_FIELDS.patientMiddleInitial]: yup.string().nullable(),
    [DENTAL_FIELDS.patientGen]: yup.string().nullable(),
    [DENTAL_FIELDS.subscriberGender]: yup
      .string()
      .nullable()
      .transform((v, o) => (o === '' ? null : v))
      .test('custom-requiredSubscriberNumber', t('common:fieldErrors.COM008'), testRequiredByOtherCoverage),
    [DENTAL_FIELDS.subGender]: yup
      .string()
      .nullable()
      .transform((v, o) => (o === '' ? null : v)),
    //.required(t('common:fieldErrors.sexIsRequired')),
    [DENTAL_FIELDS.patGender]: yup
      .string()
      .nullable()
      .transform((v, o) => (o === '' ? null : v)),
    [DENTAL_FIELDS.patientAddress]: commonAddressValidation(t),
    [DENTAL_FIELDS.patientCity]: commonCityValidation(t),

    [DENTAL_FIELDS.patientState]: yup.string().nullable(),
    [DENTAL_FIELDS.patientZip]: commonZipcodeValidation(t),
    [DENTAL_FIELDS.patientPhone]: yup.string().nullable(),
    // [DENTAL_FIELDS.otherCoverage]: yup.array().of(yup.string()),
    // [DENTAL_FIELDS.insuredId]: yup
    //   .string()
    //   .min(2, t('common:fieldErrors.min', { n: 3 }))
    //   .max(60, t('common:fieldErrors.max', { n: 29 }))
    //   .matches(/^$|[A-Za-z0-9\-\s]/, t('common:fieldErrors.insuredId'))
    //   .required(t('common:fieldErrors.insuredsIdRequired')),
    [DENTAL_FIELDS.insuredLastName]: commonNameValidation(t),
    [DENTAL_FIELDS.insuredFirstName]: commonNameValidation(t),
    [DENTAL_FIELDS.insuredMiddleInitial]: yup.string().nullable(),
    [DENTAL_FIELDS.insuredGen]: yup.string().nullable(),
    [DENTAL_FIELDS.insuredAddress]: commonAddressValidation(t),
    [DENTAL_FIELDS.insuredCity]: commonCityValidation(t),
    [DENTAL_FIELDS.insuredState]: yup.string().nullable(),
    [DENTAL_FIELDS.insuredZip]: commonZipcodeValidation(t),
    [DENTAL_FIELDS.insuredPhone]: yup.string().nullable(),
    // [DENTAL_FIELDS.otherInsuredId]: yup
    //   .string()
    //   .required(t('common:fieldErrors.otherInsuredRequired'))
    //   .min(2, t('common:fieldErrors.min', { n: 3 }))
    //   .max(60, t('common:fieldErrors.max', { n: 29 }))
    //   .matches(/^$|[A-Za-z0-9\-\s]/, t('common:fieldErrors.otherInsuredInvalid')),
    [DENTAL_FIELDS.otherInsuredLastName]: commonNameValidation(t).when(DENTAL_FIELDS.otherInsuredId, {
      is: 'YES',
      then: (schema) => schema.required('Other insured last name is required')
    }),
    [DENTAL_FIELDS.otherInsuredFirstName]: commonNameValidation(t).when(DENTAL_FIELDS.otherInsuredId, {
      is: 'YES',
      then: (schema) => schema.required('Other insured first name is required')
    }),
    [DENTAL_FIELDS.otherInsuredMiddleInitial]: yup.string().nullable(),
    [DENTAL_FIELDS.otherInsuredGen]: yup.string().nullable(),
    [DENTAL_FIELDS.otherInsuredPlanName]: yup
      .string()
      .when(DENTAL_FIELDS.otherInsuredId, {
        is: 'YES',
        then: (schema) => schema.required(t('common:fieldErrors.insurancePlanRequired'))
      })
      .matches(/^[A-Za-z\-\s]{3,33}$/, t('common:fieldErrors.insurancePlanInvalid')),
    [DENTAL_FIELDS.employmentStatus]: yup.string().oneOf(['YES', 'NO']),

    [DENTAL_FIELDS.referringProviderLastName]: commonNameValidation(t),
    [DENTAL_FIELDS.referringProviderFirstName]: commonNameValidation(t),
    [DENTAL_FIELDS.referringProviderMiddleInitial]: yup.string().nullable(),
    [DENTAL_FIELDS.referringProviderGen]: yup.string().nullable(),

    [DENTAL_FIELDS.facilityAddress]: commonAddressValidation(t),
    [DENTAL_FIELDS.facilityCity]: commonCityValidation(t),
    [DENTAL_FIELDS.facilityState]: yup.string().nullable(),
    [DENTAL_FIELDS.facilityZip]: commonZipcodeValidation(t),

    [DENTAL_FIELDS.insuredPolicyGroup]: yup
      .string()
      .matches(/^$|.{0,29}/, t('common:fieldErrors.insuredGroup'))
      .when([DENTAL_FIELDS.insuredFirstName, DENTAL_FIELDS.insuredLastName], {
        is: true,
        then: (schema) => schema.required(t('common:fieldErrors.required'))
      }),
    [DENTAL_FIELDS.otherClaimId]: yup.string().matches(/^[a-zA-Z0-9-]{0,50}$/, t('common:fieldErrors.otherClaimId')),
    [DENTAL_FIELDS.referringProviderId]: yup
      .string()
      .matches(/^$|[A-Za-z0-9\s]{3,11}$/, t('common:fieldErrors.referringProviderId')),

    [DENTAL_FIELDS.dxCodeA]: commonDiagnosisCodeValidation(t),
    // .test(
    //   'dxCodeA-lookup',
    //   diagnoseCodeLookupMsg,
    //   diagnosisCodeLookup
    // ),
    [DENTAL_FIELDS.dxCodeB]: commonDiagnosisCodeValidation(t),
    // .test(
    //   'dxCodeB-lookup',
    //   diagnoseCodeLookupMsg,
    //   diagnosisCodeLookup
    // ),
    [DENTAL_FIELDS.dxCodeC]: commonDiagnosisCodeValidation(t),
    // .test(
    //   'dxCodeC-lookup',
    //   diagnoseCodeLookupMsg,
    //   diagnosisCodeLookup
    // ),
    [DENTAL_FIELDS.dxCodeD]: commonDiagnosisCodeValidation(t),
    // .test(
    //   'dxCodeD-lookup',
    //   diagnoseCodeLookupMsg,
    //   diagnosisCodeLookup
    // ),
    [DENTAL_FIELDS.originalRefNumber]: yup
      .string()
      .nullable()
      .transform((v, o) => (o === '' ? null : v))
      .matches(alphaNum, t('common:fieldErrors.VALID001', { validation: alphaNumVal }))
      .min(12, t('common:fieldErrors.LEN001', { length: '(12)' }))
      .max(12, t('common:fieldErrors.LEN001', { length: '(12)' }))
      .test('custom-originalRefNumber', t('common:fieldErrors.RES001'), testOriginalRefNumToRessubmission),
    [DENTAL_FIELDS.authorizationNumber]: yup
      .string()
      .nullable()
      .transform((v, o) => (o === '' ? null : v))
      .matches(/^$|[A-Za-z0-9\s]/, t('common:fieldErrors.priorAuthorization')),
    [DENTAL_FIELDS.patientAccountNumber]: yup
      .string()
      .nullable()
      .transform((v, o) => (o === '' ? null : v))
      .matches(/[A-Za-z0-9.,\-#\s]*/, t('common:fieldErrors.patientAccount')),
    [DENTAL_FIELDS.facilityName]: yup
      .string()
      .nullable()
      .transform((v, o) => (o === '' ? null : v))
      .matches(/^.{2,23}[a-zA-Z0-9-\s]+$/, t('common:fieldErrors.facilityName')),
    [DENTAL_FIELDS.billingName]: yup
      .string()
      .nullable()
      .transform((v, o) => (o === '' ? null : v))
      .matches(/^.{2,33}[a-zA-Z0-9-\s]+$/, t('common:fieldErrors.billingName')),
    [DENTAL_FIELDS.organizationName]: yup
      .string()
      .nullable()
      .transform((v, o) => (o === '' ? null : v))
      .matches(alphaNumericWithSpecChar, t('common:fieldErrors.VALID001', { validation: alphaNumericWithSpecCharVal }))
      .min(1, t('common:fieldErrors.LEN001', { length: '(1-60)' }))
      .max(60, t('common:fieldErrors.LEN001', { length: '(1-60)' }))
      .test('custom-billingFirstName', t('common:fieldErrors.NPI001'), testNpi49ForOrganizationName),
    [DENTAL_FIELDS.billingLastName]: yup
      .string()
      .nullable()
      .transform((v, o) => (o === '' ? null : v))
      .matches(alphaWithSpecChar, t('common:fieldErrors.VALID001', { validation: alphaWithSpecCharVal }))
      .min(1, t('common:fieldErrors.LEN001', { length: '(1-60)' }))
      .max(60, t('common:fieldErrors.LEN001', { length: '(1-60)' }))
      .test('custom-billingLastName', t('common:fieldErrors.NAME001'), testBillingFirstName)
      .test('custom-billingFirstName', t('common:fieldErrors.NPI001'), testNpi49ForFirstName),
    [DENTAL_FIELDS.billingFirstName]: yup
      .string()
      .nullable()
      .transform((v, o) => (o === '' ? null : v))
      .matches(alphaWithSpecChar, t('common:fieldErrors.VALID001', { validation: alphaWithSpecCharVal }))
      .min(1, t('common:fieldErrors.LEN001', { length: '(1-35)' }))
      .max(35, t('common:fieldErrors.LEN001', { length: '(1-35)' }))
      .test('custom-billingFirstName', t('common:fieldErrors.NAME001'), testBillingLastName)
      .test('custom-billingFirstName', t('common:fieldErrors.NPI001'), testNpi49ForLastName),
    [DENTAL_FIELDS.billingMiddleName]: yup
      .string()
      .nullable()
      .transform((v, o) => (o === '' ? null : v))
      .matches(alpha, t('common:fieldErrors.VALID001', { validation: alphaVal }))
      .min(1, t('common:fieldErrors.LEN001', { length: '(1-25)' }))
      .max(25, t('common:fieldErrors.LEN001', { length: '(1-25)' })),
    [DENTAL_FIELDS.billingSuffix]: yup
      .string()
      .nullable()
      .transform((v, o) => (o === '' ? null : v))
      .matches(alpha, t('common:fieldErrors.VALID001', { validation: alphaVal }))
      .min(1, t('common:fieldErrors.LEN001', { length: '(1-10)' }))
      .max(10, t('common:fieldErrors.LEN001', { length: '(1-10)' })),
    [DENTAL_FIELDS.billingNameAddress]: yup
      .string()
      .nullable()
      .transform((v, o) => (o === '' ? null : v))
      .test('custom-billingAddressPOBox', 'Physical Address Required for Billing Provider', testPhysicalAddress)
      .matches(alphaNumericWithSpecChar, t('common:fieldErrors.VALID001', { validation: alphaNumericWithSpecCharVal }))
      .min(1, t('common:fieldErrors.LEN001', { length: '(1-55)' }))
      .max(55, t('common:fieldErrors.LEN001', { length: '(1-55)' })),

    [DENTAL_FIELDS.billingAddress2]: yup
      .string()
      .nullable()
      .transform((v, o) => (o === '' ? null : v))
      .matches(alphaNumericWithSpecChar, t('common:fieldErrors.VALID001', { validation: alphaNumericWithSpecCharVal }))
      .min(1, t('common:fieldErrors.LEN001', { length: '(1-55)' }))
      .max(55, t('common:fieldErrors.LEN001', { length: '(1-55)' })),
    [DENTAL_FIELDS.billingCity]: yup
      .string()
      .nullable()
      .transform((v, o) => (o === '' ? null : v))
      .matches(alphaSpace, t('common:fieldErrors.VALID001', { validation: alphaSpaceVal }))
      .min(1, t('common:fieldErrors.LEN001', { length: '(1-50)' }))
      .max(50, t('common:fieldErrors.LEN001', { length: '(1-50)' }))
      .required(t('common:fieldErrors.MUST001'))
      .test('custom-billingCity', t('common:fieldErrors.ADD001'), testBillingAddress),
    [DENTAL_FIELDS.billingState]: yup
      .string()
      .nullable()
      .transform((v, o) => (o === '' ? null : v))
      //.matches(alphaSpace, t('common:fieldErrors.VALID001', { validation: alphaSpaceVal }))
      .matches(statePattern, 'State field should be 2 characters')
      .required(t('common:fieldErrors.MUST001'))
      .test('custom-billingCity', t('common:fieldErrors.ADD001'), testBillingAddress),
    [DENTAL_FIELDS.billingZip]: commonZipcodeValidation(t).test(
      'custom-treatState',
      t('common:fieldErrors.ADD001'),
      testBillingAddress
    ),
    // [DENTAL_FIELDS.billingZipExt]: commonZipcodeExtValidation(t),

    [DENTAL_FIELDS.treatSignDate]: yup
      .string()
      .nullable()
      .transform((v, o) => (o === '' ? null : v))
      .test('custom-treatSignDate', t('common:fieldErrors.DATE002'), testTreatSign)
      .test('custom-treatSignDatefuture', t('common:fieldErrors.DATE001'), testFutureDate)
      .test('custom-validDate', dateInvalidMsg, testIsDateValid),

    [DENTAL_FIELDS.treatLastName]: yup
      .string()
      .nullable()
      .transform((v, o) => (o === '' ? null : v))
      .matches(alpha, t('common:fieldErrors.VALID001', { validation: alphaVal }))
      .min(1, t('common:fieldErrors.LEN001', { length: '(1-60)' }))
      .max(60, t('common:fieldErrors.LEN001', { length: '(1-60)' }))
      .test('custom-treatLastName', t('common:fieldErrors.NAME001'), testTreatFirstName)
      .test('custom-treatLastName', t('common:fieldErrors.NPI001'), testreatNpi),
    [DENTAL_FIELDS.treatFirstName]: yup
      .string()
      .nullable()
      .transform((v, o) => (o === '' ? null : v))
      .matches(alpha, t('common:fieldErrors.VALID001', { validation: alphaVal }))
      .min(1, t('common:fieldErrors.LEN001', { length: '(1-35)' }))
      .max(35, t('common:fieldErrors.LEN001', { length: '(1-35)' }))
      .test('custom-treatFirstName', t('common:fieldErrors.NAME001'), testTreatLastName)
      .test('custom-treatLastName', t('common:fieldErrors.NPI001'), testreatNpi),
    [DENTAL_FIELDS.treatMiddleName]: yup
      .string()
      .nullable()
      .transform((v, o) => (o === '' ? null : v))
      .matches(alpha, t('common:fieldErrors.VALID001', { validation: alphaVal }))
      .min(1, t('common:fieldErrors.LEN001', { length: '(1-25)' }))
      .max(25, t('common:fieldErrors.LEN001', { length: '(1-25)' })),
    [DENTAL_FIELDS.treatSuffix]: yup
      .string()
      .nullable()
      .transform((v, o) => (o === '' ? null : v))
      .matches(alpha, t('common:fieldErrors.VALID001', { validation: alphaVal }))
      .min(1, t('common:fieldErrors.LEN001', { length: '(1-10)' }))
      .max(10, t('common:fieldErrors.LEN001', { length: '(1-10)' })),
    // [DENTAL_FIELDS.billingPhoneCode]: yup.string().nullable(),
    // .transform((v, o) => (o === '' ? null : v))
    // .matches(numeric, t('common:fieldErrors.VALID001', { validation: numericVal }))
    // .min(10, t('common:fieldErrors.LEN001', { length: '(10)' }))
    // .max(10, t('common:fieldErrors.LEN001', { length: '(10)' })),
    [DENTAL_FIELDS.billingPhone]: yup
      .string()
      .nullable()
      .transform((v, o) => (o === '' ? null : v))
      .matches(numeric, t('common:fieldErrors.VALID001', { validation: numericVal }))
      .min(10, t('common:fieldErrors.LEN001', { length: '(10)' }))
      .max(10, t('common:fieldErrors.LEN001', { length: '(10)' })),
    // .transform((v, o) => (o === '' ? null : v))
    // .matches(numeric, t('common:fieldErrors.VALID001', { validation: numericVal }))
    // .min(10, t('common:fieldErrors.LEN001', { length: '(10)' }))
    // .max(10, t('common:fieldErrors.LEN001', { length: '(10)' })),

    // [DENTAL_FIELDS.treatingPhoneCode]: yup.string().nullable(),
    // .transform((v, o) => (o === '' ? null : v))
    // .matches(numeric, t('common:fieldErrors.VALID001', { validation: numericVal }))
    // .min(10, t('common:fieldErrors.LEN001', { length: '(10)' }))
    // .max(10, t('common:fieldErrors.LEN001', { length: '(10)' })),
    [DENTAL_FIELDS.treatingPhone]: yup
      .string()
      .nullable()
      .transform((v, o) => (o === '' ? null : v))
      .matches(numeric, t('common:fieldErrors.VALID001', { validation: numericVal }))
      .min(10, t('common:fieldErrors.LEN001', { length: '(10)' }))
      .max(10, t('common:fieldErrors.LEN001', { length: '(10)' })),
    // .transform((v, o) => (o === '' ? null : v))
    // .matches(numeric, t('common:fieldErrors.VALID001', { validation: numericVal }))
    // .min(10, t('common:fieldErrors.LEN001', { length: '(10)' }))
    // .max(10, t('common:fieldErrors.LEN001', { length: '(10)' })),
    [DENTAL_FIELDS.billingNpi]: yup
      .string()
      .nullable()
      .transform((v, o) => (o === '' ? null : v))
      .matches(numeric, t('common:fieldErrors.VALID001', { validation: numericVal }))
      .min(10, t('common:fieldErrors.LEN001', { length: '(10)' }))
      .max(10, t('common:fieldErrors.LEN001', { length: '(10)' }))
      .test('custom-renderNPI49', 'NPI is not valid', testNPIforLuhnAlghoritm)
      .test('custom-billingNpi', t('common:fieldErrors.NPI002'), testBillingFirstName)
      .test('custom-billingNpi', t('common:fieldErrors.NPI002'), testBillingLastName)
      .test('custom-billingNpi', t('common:fieldErrors.MUST001'), testHasBillingFirstName)
      .test('custom-billingNpi', t('common:fieldErrors.MUST001'), testHasBillingLastName),
    // .test('custom-billingNpi', 'NPI is not valid', testNPIforLuhnAlghoritm),
    //.test('custom-billingNpiId', 'Billing NPI and ID are blank/illegible', testIsBillingIdAndIdBlank),

    [DENTAL_FIELDS.billingAddId]: yup
      .string()
      .nullable()
      .transform((v, o) => (o === '' ? null : v))
      .min(1, t('common:fieldErrors.LEN001', { length: '(1-25)' }))
      .max(25, t('common:fieldErrors.LEN001', { length: '(1-25)' }))
      .matches(alphaNum, t('common:fieldErrors.VALID001', { validation: alphaNumVal })),

    [DENTAL_FIELDS.billingLic]: yup
      .string()
      .nullable()
      .transform((v, o) => (o === '' ? null : v))
      .min(1, t('common:fieldErrors.LEN001', { length: '(1-28)' }))
      .max(28, t('common:fieldErrors.LEN001', { length: '(1-28)' }))
      .matches(alphaNum, t('common:fieldErrors.VALID001', { validation: alphaNumVal })),

    [DENTAL_FIELDS.billingSSNorTIN]: yup
      .string()
      .nullable()
      .transform((v, o) => (o === '' ? null : v))
      .matches(numeric, t('common:fieldErrors.VALID001', { validation: numericVal }))
      .min(9, t('common:fieldErrors.LEN001', { length: '(9)' }))
      .max(9, t('common:fieldErrors.LEN001', { length: '(9)' }))
      .required(t('common:fieldErrors.MUST001')),
    [DENTAL_FIELDS.treatNpi]: yup
      .string()
      .nullable()
      .transform((v, o) => (o === '' ? null : v))
      .matches(numeric, t('common:fieldErrors.VALID001', { validation: numericVal }))
      .min(10, t('common:fieldErrors.LEN001', { length: '(10)' }))
      .max(10, t('common:fieldErrors.LEN001', { length: '(10)' }))
      .test('custom-renderNPI54', 'NPI is not valid', testNPIforLuhnAlghoritm)
      .test('custom-treatNpi', t('common:fieldErrors.NPI002'), testTreatFirstName)
      .test('custom-treatNpi', t('common:fieldErrors.NPI002'), testTreatLastName),
    //.test('custom-billingNpiId', 'Billing NPI and ID are blank/illegible', testIsBillingIdAndIdBlank),

    [DENTAL_FIELDS.provSpecCode]: yup
      .string()
      .nullable()
      .transform((v, o) => (o === '' ? null : v))
      .min(1, t('common:fieldErrors.LEN001', { length: '(1-25)' }))
      .max(25, t('common:fieldErrors.LEN001', { length: '(1-25)' }))
      .matches(alphaNum, t('common:fieldErrors.VALID001', { validation: alphaNumVal }))
      //.test('custom-provSpecCode', t('common:fieldErrors.NPI003'), testBillingOrTreatingNpi)
      .test('custom-provSpecCode', t('common:fieldErrors.NPI003'), testBillingOrTreatingNpi),
    [DENTAL_FIELDS.treatLic]: yup
      .string()
      .nullable()
      .transform((v, o) => (o === '' ? null : v))
      .min(1, t('common:fieldErrors.LEN001', { length: '(1-25)' }))
      .max(25, t('common:fieldErrors.LEN001', { length: '(1-25)' }))
      .matches(alphaNum, t('common:fieldErrors.VALID001', { validation: alphaNumVal })),
    // .test('custom-treatLic', t('common:fieldErrors.MUST001'), testNpi54),
    //.required(t('common:fieldErrors.MUST001')),
    [DENTAL_FIELDS.additionalProviderIdDental]: yup
      .string()
      .nullable()
      .transform((v, o) => (o === '' ? null : v))
      .min(1, t('common:fieldErrors.LEN001', { length: '(1-25)' }))
      .max(25, t('common:fieldErrors.LEN001', { length: '(1-25)' }))
      .matches(alphaNum, t('common:fieldErrors.VALID001', { validation: alphaNumVal })),

    [DENTAL_FIELDS.providerId]: yup
      .string()
      .nullable()
      .transform((v, o) => (o === '' ? null : v))
      .min(1, t('common:fieldErrors.LEN001', { length: '(1-25)' }))
      .max(25, t('common:fieldErrors.LEN001', { length: '(1-25)' }))
      .matches(alphaNum, t('common:fieldErrors.VALID001', { validation: alphaNumVal }))
  });

const getDentalServiceTableShape = (t: TFunction) =>
  yup.object().shape({
    [DENTAL_FIELDS.procedureDate]: yup
      .string()
      .nullable()
      .required(t('common:fieldErrors.MUST001'))
      .test('custom-procedureDate', t('common:fieldErrors.DOS003'), testPatientDateOfBirth)
      .test('custom-procedureDatefuture', t('common:fieldErrors.DATE001'), testFutureDate)
      .test('custom-validDate', dateInvalidMsg, testIsDateValid),
    [DENTAL_FIELDS.cavityArea]: yup
      .string()
      .nullable()
      .transform((v, o) => (o === '' ? null : v))
      .matches(
        alphaNumWithHyphenAndComma,
        t('common:fieldErrors.VALID001', { validation: alphaNumWithHyphenAndCommaVal })
      )
      .min(2, t('common:fieldErrors.LEN001', { length: '(2-20)' }))
      .max(20, t('common:fieldErrors.LEN001', { length: '(2-20)' })),
    [DENTAL_FIELDS.toothSystem]: yup
      .string()
      .nullable()
      .transform((v, o) => (o === '' ? null : v))
      .matches(toothSystem, t('common:fieldErrors.VALID001', { validation: toothSystemVal }))
      .min(2, t('common:fieldErrors.LEN001', { length: '(2)' }))
      .max(2, t('common:fieldErrors.LEN001', { length: '(2)' })),
    [DENTAL_FIELDS.toothNumOrLetters]: yup
      .string()
      .nullable()
      .transform((v, o) => (o === '' ? null : v))
      .matches(
        alphaNumWithHyphenAndCommaAH,
        t('common:fieldErrors.VALID001', { validation: alphaNumWithHyphenAndCommaAHVal })
      )
      .min(1, t('common:fieldErrors.LEN001', { length: '(1-20)' }))
      .max(20, t('common:fieldErrors.LEN001', { length: '(1-20)' })),
    [DENTAL_FIELDS.toothSurface]: yup
      .string()
      .nullable()
      .transform((v, o) => (o === '' ? null : v))
      .matches(
        toothSurfaceValidCharacters,
        t('common:fieldErrors.VALID001', { validation: toothSurfaceValidCharactersVal })
      )
      .min(1, t('common:fieldErrors.LEN001', { length: '(1-5)' }))
      .max(5, t('common:fieldErrors.LEN001', { length: '(1-5)' })),
    [DENTAL_FIELDS.procedureCode]: yup
      .string()
      .nullable()
      .transform((v, o) => (o === '' ? null : v))
      .matches(alphaNum, t('common:fieldErrors.VALID001', { validation: alphaNumVal }))
      .min(5, t('common:fieldErrors.LEN001', { length: '(5)' }))
      .max(5, t('common:fieldErrors.LEN001', { length: '(5)' }))
      .required(t('common:fieldErrors.MUST001')),
    // .test(
    //   'procedure-lookup',
    //   'Please review the entered value, it is not found in the standard list of values',
    //   testProcedureCodeLookup
    // ),
    [DENTAL_FIELDS.diagPointer]: yup
      .string()
      .nullable()
      .transform((v, o) => (o === '' ? null : v))
      .matches(diagnosisPointerAL, t('common:fieldErrors.VALID001', { validation: diagnosisPointerALVal }))
      .min(1, t('common:fieldErrors.LEN001', { length: '(1-30)' }))
      .max(30, t('common:fieldErrors.LEN001', { length: '(1-30)' }))
      .test('custom-diagPointer', t('common:fieldErrors.ICD002'), testICD002),
    [DENTAL_FIELDS.qty]: yup
      .string()
      .nullable()
      .transform((v, o) => (o === '' ? null : v))
      .matches(numericWithDotAndHypen, t('common:fieldErrors.VALID001', { validation: numericWithDotAndHypenVal }))
      .min(1, t('common:fieldErrors.LEN001', { length: '(1-15)' }))
      .max(15, t('common:fieldErrors.LEN001', { length: '(1-15)' })),
    [DENTAL_FIELDS.desc]: yup
      .string()
      .nullable()
      .transform((v, o) => (o === '' ? null : v))
      .matches(alphaNumericWithSpecChar, t('common:fieldErrors.VALID001', { validation: alphaNumericWithSpecCharVal }))
      .min(1, t('common:fieldErrors.LEN001', { length: '(1-60)' }))
      .max(60, t('common:fieldErrors.LEN001', { length: '(1-60)' })),
    [DENTAL_FIELDS.fee]: yup
      .string()
      .nullable()
      .transform((v, o) => (o === '' ? null : v))
      .matches(decimalsWithMinus, t('common:fieldErrors.VALID001', { validation: decimalsWithMinusVal }))
      .min(1, t('common:fieldErrors.LEN001', { length: '(1-18)' }))
      .max(18, t('common:fieldErrors.LEN001', { length: '(1-18)' }))
      .required(t('common:fieldErrors.MUST001'))
  });
type GetYupValidator = (t: TFunction, fieldName: string) => Rule;
export const getYupValidator: GetYupValidator = (t, fieldName) => {
  return {
    validator: async (_, value: StoreValue) => {
      await getDentalSchema(t).validateAt(
        fieldName,
        { ...getFormProvidersDataFromGlobalObject(), [fieldName]: value },
        // @ts-ignore
        { context: { path: _.field } }
      );
    }
  };
};
type GetYupValidatorServiceTable = (t: TFunction, fieldName: string) => Rule;
export const getYupValidatorServiceTable: GetYupValidatorServiceTable = (t, fieldName) => {
  return {
    validator: async (_, value: StoreValue) => {
      const schema = getDentalServiceTableShape(t);

      await schema.validateAt(
        fieldName,
        { ...getFormProvidersDataFromGlobalObject(), [fieldName]: value },
        // @ts-ignore
        { context: { path: _.field } }
      );
    }
  };
};

export const getYupWarning: GetYupValidator = (t, fieldName) => {
  return {
    validator: async (_, value: StoreValue) => {
      if (knownWarnings.includes(fieldName))
        getDentalWarningSchema(t).validateSyncAt(
          fieldName,
          { ...getFormProvidersDataFromGlobalObject(), [fieldName]: value }, // @ts-ignore
          { context: { path: _.field } }
        );
    },
    warningOnly: true
  };
};
