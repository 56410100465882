// import './form-error-list.scss';

import { FieldError } from '@datum/react-components';

import React, { useEffect, useState } from 'react';
import ValidationErrors from './ValidationErrors';
import ValidationWarnings from './ValidationWarnings';

type FormErrorListProps = {
  errorInfo?: any;
  fullErrorInfo: any[];
  isInStep?: boolean;
  tableName: string;
  fullWarningInfo: any[];
};

function FormErrorList({ errorInfo, fullErrorInfo, isInStep = false, tableName, fullWarningInfo }: FormErrorListProps) {
  const [validationWarning, setValidationWarning] = React.useState<FieldError[]>([]);
  const [warningWarning, setWarningWarning] = useState<FieldError[]>([]);

  useEffect(() => {
    if (!fullErrorInfo || !fullErrorInfo.length) {
      return;
    }

    let validationWarningMessage: FieldError[] = [];
    const nonTableErrors = fullErrorInfo.filter((e) => !e.path.includes(tableName));
    const tableErrors = fullErrorInfo.filter((e) => e.path.includes(tableName) && e.path !== tableName);
    const tableRequiredError = fullErrorInfo.filter((e) => e.path === tableName);

    const nonTableErrorsNotDuplicated = nonTableErrors.reduce((_prev, _curr) => {
      if (!_prev.some((_err: any) => _err.path === _curr.path)) _prev.push(_curr);
      return _prev;
    }, []);

    const tableErrorsNotDuplicated = tableErrors.reduce((_prev, _curr) => {
      if (!_prev.some((_err: any) => _err.path === _curr.path)) _prev.push(_curr);
      return _prev;
    }, []);

    console.log('nonTableErrors: ', nonTableErrors);
    console.log('tableErrors: ', tableErrors);
    console.log('tableRequiredErrors: ', tableRequiredError);

    if (nonTableErrorsNotDuplicated?.length) {
      validationWarningMessage.push({
        errorMessage:
          'You have ' +
          nonTableErrorsNotDuplicated.length +
          ' error messages that need to be corrected before the claim can be submitted!',
        propLabel: ''
      });
    }
    if (tableErrorsNotDuplicated?.length) {
      validationWarningMessage.push({
        errorMessage:
          'You have ' +
          tableErrorsNotDuplicated.length +
          ' error messages inside Table that need to be corrected before the claim can be submitted!',
        propLabel: ''
      });
    }
    if (tableRequiredError?.length) {
      validationWarningMessage.push({
        errorMessage: 'Service table is required. Please fill in at least one row',
        propLabel: ''
      });
    }

    setValidationWarning(validationWarningMessage);
  }, [fullErrorInfo]);

  useEffect(() => {
    if (!fullWarningInfo || !fullWarningInfo.length) {
      return;
    }

    const errorList: FieldError[] = [];

    fullWarningInfo.forEach((error: any) => {
      errorList.push({
        errorMessage: error.message,
        propLabel: error.path
      });
    });

    const warningsNotDuplicate = fullWarningInfo.reduce((_prev, _curr) => {
      if (!_prev.some((_err: any) => _err.path === _curr.path)) _prev.push(_curr);
      return _prev;
    }, []);

    let validationWarningMessage: FieldError[] = [];
    if (warningsNotDuplicate.length)
      validationWarningMessage.push({
        errorMessage: 'You have ' + warningsNotDuplicate.length + ' validation warnings you should correct!',
        propLabel: ''
      });

    setWarningWarning(validationWarningMessage);
  }, [fullWarningInfo]);

  function clearErrors() {
    setValidationWarning([]);
  }

  function clearWarnings() {
    setWarningWarning([]);
  }

  return (
    <>
      {fullErrorInfo && fullErrorInfo.length > 0 && (
        <div className="error-message">
          <ValidationErrors errors={validationWarning} clearErrors={clearErrors} />
        </div>
      )}
      {fullWarningInfo && fullWarningInfo.length > 0 && (
        <div className="warning-message">
          <ValidationWarnings errors={warningWarning} clearErrors={clearWarnings} />
        </div>
      )}
    </>
  );
}

export default FormErrorList;
