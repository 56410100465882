import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Space } from 'antd';
import { CheckSquareOutlined, SendOutlined } from '@ant-design/icons';
import { Button } from '@datum/react-components';
import { ReactComponent as SaveIcon } from '@datum/icons/feather/save.svg';
import { useContext } from 'react';
import { FormContext } from './FormProvider';
import { FORM_ACTIONS } from 'config/constants';
import { FORM_ACTIONS_CONTAINER_ID } from 'utils/hcfa/constants';

type FormActionsProps = {
  onSend?: () => void;
  onValidate?: () => void;
  onSave?: () => void;
  action: FORM_ACTIONS;
};

function FormActions({ onSend, onValidate, onSave, action }: FormActionsProps): JSX.Element {
  const { t } = useTranslation();

  const { loading } = useContext(FormContext);

  const disabled = action === FORM_ACTIONS.VIEW;

  return (
    <Space id={FORM_ACTIONS_CONTAINER_ID} className="mt-1 form-actions">
      <Button
        // loading={loading}
        disabled={disabled}
        variant="outline"
        size="large"
        shape="round"
        leftIcon={<SaveIcon />}
        onClick={onSave}
      >
        {t('save')}
      </Button>
      <Button
        // loading={loading}
        variant="primary"
        size="large"
        shape="round"
        leftIcon={<SendOutlined />}
        onClick={onSend}
        disabled={disabled}
      >
        {t('send')}
      </Button>
      {onValidate && (
        <Button
          // loading={loading}
          type="default"
          size="large"
          shape="round"
          leftIcon={<CheckSquareOutlined />}
          onClick={onValidate}
          disabled={disabled}
        >
          {t('validate')}
        </Button>
      )}
    </Space>
  );
}

export default FormActions;
