export enum ClaimStatusEnum {
  IN_PROGRESS = 'IN_PROGRESS',
  SUBMIT_STARTED = 'SUBMIT_STARTED',
  SUBMIT_FINISHED = 'SUBMIT_FINISHED',
  SUBMIT_ERROR = 'SUBMIT_ERROR',
  EXTERNAL_EXPORT_FINISHED = 'EXTERNAL_EXPORT_FINISHED',
  REJECTED = 'REJECTED',
  EXPORT_MQ_ERROR = 'EXPORT_MQ_ERROR',
  SNG_837_SUBMISSION_IN_PROGRESS = '837_SUBMISSION_IN_PROGRESS',
  SNG_837_SUBMITTED_AND_WAITING_FOR_ACK = '837_SUBMITTED_AND_WAITING_FOR_ACK',
  SNG_837_SUCCESSFULLY_SUBMITTED = '837_SUCCESSFULLY_SUBMITTED',
  RESUBMITTED = 'RESUBMITTED'
}
