import React, { PropsWithChildren, useContext, useEffect, useRef, useState } from 'react';
import { Form, FormInstance, Modal, notification } from 'antd';

import ExclamationCircleOutlined from '@ant-design/icons/ExclamationCircleOutlined';
import axios from 'axios';
import { ValidationError } from 'yup';

import { useLoading } from 'context/loading.context';
import { getHcfaSchema } from 'utils/hcfa/validations';
import { getDentalSchema } from 'utils/dental/validations';
import { getUb04Schema } from 'utils/ub04/validataions';
import { useTranslation } from 'react-i18next';

import uuid from 'react-uuid';
import { useOidcAccessToken } from '@axa-fr/react-oidc';

import { QuestionCircleOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';

import { AttachmentDTO, FileRepresentationsDTO } from 'models/DTO/AttachmentDTO';
import { deleteStorageFilesUponSave } from 'api/files';
import { ClaimDTO } from 'models/DTO/ClaimDTO';
import { ClaimStatusEnum } from 'models/enums/ClaimStatusEnum';

import { putFormInstanceInGlobalObject } from 'utils/hcfa/putFormInstanceInGlobalObject';
import { ValidationsDependenciesMap as ValidationsDependenciesMapHCFA } from 'utils/hcfa/customRules';
import { ValidationsDependenciesMap as ValidationsDependenciesMapDental } from 'utils/dental/customRules';
import { ValidationsDependenciesMap as ValidationsDependenciesMapUB04 } from 'utils/ub04/customRules';
import { mapFormValuesToClaimData } from 'helpers/mapFormValuesToClaimData';
import { getHcfaWarningSchema } from 'utils/hcfa/warningSchema';
import { getDentalWarningSchema } from 'utils/dental/warningSchema';
import { getUb04WarningSchema } from 'utils/ub04/warningSchema';
import {
  setFieldsDataErrorsFromYupErrors,
  setFieldsDataWarningsFromYupErrors
} from 'helpers/setFieldsDataFromYupErrors';
import {
  getFormProvidersDataFromGlobalObject,
  putFormProvidersDataInGlobalObject
} from 'utils/hcfa/putFormProvidersDataInGlobalObject';

export type FormType = 'hcfa' | 'ub04' | 'dental';

interface FormContextType {
  loading: boolean;
  form?: FormInstance;
  // data: Record<string, any>;
  setData: (data: Record<string, any>) => void;
  // updateData: (data: Record<string, any>) => void;
  attachmentsNew: AttachmentDTO[];
  setAttachmentsNew: React.Dispatch<React.SetStateAction<AttachmentDTO[]>>;
  handleSave: (formType: FormType) => void;
  handleQuickSave: (formType: FormType) => void;
  handleSend: (formType: FormType) => void;
  fileRepresentationsList: FileRepresentationsDTO[];
  setFileRepresentationsList: React.Dispatch<React.SetStateAction<FileRepresentationsDTO[]>>;
  deleteFilePaths: string[];
  setDeleteFilePaths: React.Dispatch<React.SetStateAction<string[]>>;
  errorInfo: any;
  setErrorInfo: any;
  setFullErrorInfo: React.Dispatch<React.SetStateAction<any[]>>;
  fullErrorInfo: any[];
  setFullWarningInfo: React.Dispatch<React.SetStateAction<any[]>>;
  fullWarningInfo: any[];
  claimId?: string | null;
  setClaimId: (id: string | null) => void;
  firstValidationPerformed: boolean;
  setFirstValidationPerformed: React.Dispatch<React.SetStateAction<boolean>>;
  isFormDirty: boolean;
  setIsFormDirty: React.Dispatch<React.SetStateAction<boolean>>;
  revalidateForm: (fieldName: string, formType: FormType) => void;
}

export const FormContext = React.createContext<FormContextType>({
  loading: false,
  // data: {},
  setData: () => {},
  // updateData: () => {},

  handleSave: () => {},
  handleQuickSave: () => {},
  handleSend: () => {},
  errorInfo: [],
  setErrorInfo: () => [],
  setFullErrorInfo: () => [],
  setClaimId: () => {},
  fullErrorInfo: [],
  attachmentsNew: [],
  setAttachmentsNew: () => {},
  deleteFilePaths: [],
  fileRepresentationsList: [],
  setDeleteFilePaths: () => {},
  setFileRepresentationsList: () => {},
  revalidateForm: () => {},
  setFirstValidationPerformed: () => {},
  firstValidationPerformed: false,
  setIsFormDirty: () => {},
  isFormDirty: false,
  fullWarningInfo: [],
  setFullWarningInfo: () => []
});

export const FormProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const { t } = useTranslation();

  const [claimId, setClaimId] = useState<string | null>(null);
  // const [data, setData] = useState({});
  const dataRef = useRef<Record<string, any>>({});
  const [firstValidationPerformed, setFirstValidationPerformed] = useState(false);
  const [isFormDirty, setIsFormDirty] = useState(false);

  const [attachmentsNew, setAttachmentsNew] = useState<AttachmentDTO[]>([]);
  const [fileRepresentationsList, setFileRepresentationsList] = useState<FileRepresentationsDTO[]>([]);
  const [deleteFilePaths, setDeleteFilePaths] = useState<string[]>([]);
  const [errorInfo, setErrorInfo] = useState<any>([]);
  const [fullErrorInfo, setFullErrorInfo] = useState<any[]>([]);
  const [fullWarningInfo, setFullWarningInfo] = useState<any[]>([]);
  const { accessToken } = useOidcAccessToken();

  const navigate = useNavigate();

  const { loading, startLoading, finishLoading } = useLoading();

  const [form] = Form.useForm();

  function setData(data: Record<string, any>) {
    putFormProvidersDataInGlobalObject(data);
    dataRef.current = data;
  }

  useEffect(() => {
    /**RECONSIDER THIS */
    putFormInstanceInGlobalObject(form);
  }, [form]);

  const displayErrorNotification = () =>
    notification.error({
      message: 'Something went wrong',
      description: 'Contact system administrator for more info'
    });

  const handleYupValidate = async (values: any, formType: FormType) => {
    setErrorInfo([]);
    setFullErrorInfo([]);
    setFirstValidationPerformed(true);

    // form.validateFields();
    // const start = Date.now();
    try {
      const schema =
        formType === 'hcfa' ? getHcfaSchema(t) : formType === 'dental' ? getDentalSchema(t) : getUb04Schema(t, values);
      await schema.validate(values, { abortEarly: false });
    } catch (e: any) {
      if (e instanceof ValidationError) {
        setErrorInfo(e.errors);
        setFullErrorInfo(e.inner);
        finishLoading();
        setFieldsDataErrorsFromYupErrors(e, formType);
        // console.log('ALLL ERRRORS ', JSON.parse(JSON.stringify(e)));
        throw new Error('Validation error');
      }
    }
    // finally {
    //   console.log('TIMEEEEE TOOK ', Date.now() - start);
    // }
  };

  const handleYupValidateWarnings = async (values: any, formType: FormType) => {
    setFullWarningInfo([]);
    try {
      const warningSchema =
        formType === 'hcfa'
          ? getHcfaWarningSchema(t)
          : formType === 'dental'
          ? getDentalWarningSchema(t)
          : getUb04WarningSchema(t);

      await warningSchema.validate(values, { abortEarly: false });
    } catch (e: any) {
      console.log('WARNINGS ', e.inner);
      setFieldsDataWarningsFromYupErrors(e, formType);
      setFullWarningInfo(e.inner);
    }
  };

  const revalidateForm = (fieldName: string, formType: FormType) => {
    let _dependencyMap: Record<string, string[]> = {};
    switch (formType) {
      case 'hcfa': {
        _dependencyMap = ValidationsDependenciesMapHCFA;
        break;
      }
      case 'dental': {
        _dependencyMap = ValidationsDependenciesMapDental;
        break;
      }
      case 'ub04': {
        _dependencyMap = ValidationsDependenciesMapUB04;
        break;
      }
    }

    // if (!firstValidationPerformed) return;
    if (!_dependencyMap[fieldName]) {
      return;
    }

    form.validateFields(_dependencyMap[fieldName]);
  };

  const setClaimName = (claimJson: ClaimDTO, formType: FormType) => {
    claimJson.documents?.forEach((doc) => (doc.name = formType));
  };

  const handleSave = async (formType: FormType) => {
    handleSaveCB(formType);
  };

  const handleQuickSave = async (formType: FormType) => {
    const claimJson = new ClaimDTO(claimId ? claimId : uuid(), ClaimStatusEnum.IN_PROGRESS, [
      {
        fields: [],
        tables: [
          {
            id: uuid(),
            name: 'SERVICE TABLE',
            rows: []
          }
        ]
      }
    ]);

    setClaimName(claimJson, formType);

    let formValues = form ? { ...form.getFieldsValue() } : getFormProvidersDataFromGlobalObject();
    /**Map form state to request payload */
    mapFormValuesToClaimData(formValues, formType, claimJson);

    claimJson.attachments = attachmentsNew;
    claimJson.fileRepresentations = fileRepresentationsList;

    try {
      await axios.post(window._env_.API_URL + `/api/v1/claims/${formType}`, claimJson, {
        headers: { Authorization: `Bearer ${accessToken}` }
      });
      setClaimId(claimJson.claimId);

      notification.success({
        message: (
          <div>
            <b>Data saved!</b>
            <br />
            <b>Claim ID: </b>
            {claimJson.claimId}
          </div>
        ),
        description: `${formType.toUpperCase()} form data was successfully saved`
      });
      setIsFormDirty(false);
    } catch (e) {
      displayErrorNotification();
    }
  };

  const handleSaveCB = async (formType: FormType) => {
    // startLoading();

    const claimJson = new ClaimDTO(claimId ? claimId : uuid(), ClaimStatusEnum.IN_PROGRESS, [
      {
        fields: [],
        tables: [
          {
            id: uuid(),
            name: 'SERVICE TABLE',
            rows: []
          }
        ]
      }
    ]);

    setClaimName(claimJson, formType);

    let formValues = form ? { ...form.getFieldsValue() } : getFormProvidersDataFromGlobalObject();
    /**Map form state to request payload */
    mapFormValuesToClaimData(formValues, formType, claimJson);
    try {
      await handleYupValidate(formValues, formType);
    } catch (e) {
      /**Continue with save even if has validation errors */
      console.warn(e);
    }

    handleYupValidateWarnings(formValues, formType);

    claimJson.attachments = attachmentsNew;
    claimJson.fileRepresentations = fileRepresentationsList;

    try {
      await axios.post(window._env_.API_URL + `/api/v1/claims/${formType}`, claimJson, {
        headers: { Authorization: `Bearer ${accessToken}` }
      });
      setClaimId(claimJson.claimId);
      const _id = claimJson.claimId;
      try {
        deleteStorageFilesUponSave({ deletePaths: deleteFilePaths, token: accessToken });
      } catch (e) {}
      setIsFormDirty(false);
      // notification.success({
      //   message: (
      //     <div>
      //       <b>Data saved!</b>
      //       <br />
      //       <b>Claim ID: </b>
      //       {claimJson.claimId}
      //     </div>
      //   ),
      //   description: `${formType.toUpperCase()} form data was successfully saved`
      // });
      const _actionModal = Modal.confirm({
        className: 'claim-submission-modal',
        width: 630,
        title: (
          <span>
            The {formType.toUpperCase()} claim with ID <b>{_id}</b> claim has been saved.
            <br /> What action do you want to perform next?
          </span>
        ),
        icon: <QuestionCircleOutlined />,
        okButtonProps: { disabled: true },
        cancelButtonProps: { disabled: true },
        keyboard: false,
        content: (
          <div className="ant-modal-confirm-btns">
            <button
              className="ant-btn ant-btn-default"
              onClick={async () => {
                try {
                  await navigator.clipboard.writeText(_id);
                  notification.info({ message: t('common:copyToClipboard.success') });
                  _actionModal?.destroy();
                } catch (e) {
                  notification.warning({ message: t('common:copyToClipboard.error') });
                  _actionModal?.destroy();
                }
              }}
            >
              Copy Claim ID
            </button>
            <button
              autoFocus={true}
              className="ant-btn ant-btn-primary"
              onClick={() => {
                _actionModal?.destroy();
              }}
            >
              Close
            </button>
          </div>
        )
      });
    } catch (e) {
      displayErrorNotification();
    } finally {
      // finishLoading();
      setIsFormDirty(false);
    }
  };

  const handleSend = async (formType: FormType) => {
    const formValues = form ? { ...form.getFieldsValue() } : getFormProvidersDataFromGlobalObject();
    try {
      await handleYupValidate(formValues, formType);
    } catch (e) {
      notification.error({ message: 'Make sure that form is valid before submitting!!!' });
      return;
    }

    const _id = claimId ? claimId : uuid();
    Modal.confirm({
      title: 'Do you want to submit the claim?',
      icon: <ExclamationCircleOutlined />,
      okText: 'Submit',
      cancelText: 'Cancel',
      onOk: async () => {
        startLoading();

        const formValues = form ? { ...form.getFieldsValue() } : getFormProvidersDataFromGlobalObject();
        const claimJson = new ClaimDTO(_id, ClaimStatusEnum.IN_PROGRESS, [
          {
            fields: [],
            tables: [
              {
                id: uuid(),
                name: 'SERVICE TABLE',
                rows: []
              }
            ]
          }
        ]);

        setClaimName(claimJson, formType);
        mapFormValuesToClaimData(formValues, formType, claimJson);

        claimJson.status = ClaimStatusEnum.SUBMIT_STARTED;

        await handleYupValidate(formValues, formType);

        // const formData = getFormData(values);

        claimJson.attachments = attachmentsNew;
        claimJson.fileRepresentations = fileRepresentationsList;

        try {
          await axios.post(window._env_.API_URL + `/api/v1/claims/${formType}/submit`, claimJson, {
            headers: { Authorization: `Bearer ${accessToken}` }
          });

          notification.success({
            message: 'Submit success!',
            description: <b>Your claim is successfully submitted</b>
          });

          try {
            deleteStorageFilesUponSave({ deletePaths: deleteFilePaths, token: accessToken });
          } catch (e) {}

          setIsFormDirty(false);

          const _actionModal = Modal.confirm({
            className: 'claim-submission-modal',
            width: 630,
            title: (
              <span>
                The {formType.toUpperCase()} claim with ID <b>{_id}</b> has been submitted.
                <br /> What action do you want to perform next?
              </span>
            ),
            icon: <QuestionCircleOutlined />,
            okButtonProps: { disabled: true },
            cancelButtonProps: { disabled: true },
            keyboard: false,
            content: (
              <div className="ant-modal-confirm-btns">
                <button
                  className="ant-btn ant-btn-default"
                  onClick={async () => {
                    try {
                      await navigator.clipboard.writeText(_id);
                      notification.info({ message: t('common:copyToClipboard.success') });
                      _actionModal?.destroy();
                      navigate(`/landing/tab-${formType.replace('04', '')}?fromSubmit=true`);
                    } catch (error) {
                      console.error(error);
                      notification.warning({ message: t('common:copyToClipboard.error') });
                    }
                  }}
                >
                  {t('common:copyToClipboard.label')}
                </button>
                <button
                  className="ant-btn ant-btn-default"
                  onClick={() => {
                    navigate(`/${formType}/new`);
                    form?.resetFields();
                    setData({});
                    setErrorInfo([]);
                    setFullErrorInfo([]);
                    setAttachmentsNew([]);
                    setFileRepresentationsList([]);
                    setDeleteFilePaths([]);
                    setFirstValidationPerformed(false);
                    setIsFormDirty(false);
                    _actionModal?.destroy();
                  }}
                >
                  Create New {formType.toUpperCase()} Claim
                </button>
                <button
                  autoFocus={true}
                  className="ant-btn ant-btn-primary"
                  onClick={() => {
                    navigate(`/landing/tab-${formType.replace('04', '')}?fromSubmit=true`);
                    _actionModal?.destroy();
                  }}
                >
                  Back to Search Page
                </button>
              </div>
            )
          });
        } catch (e) {
          displayErrorNotification();
        } finally {
          finishLoading();
        }
      }
    });
  };

  return (
    <FormContext.Provider
      value={{
        loading,
        form,
        // data,
        setData,
        // updateData: handleUpdateData,
        handleSave,
        handleQuickSave,
        handleSend,
        errorInfo,
        setErrorInfo,
        setFullErrorInfo,
        claimId,
        setClaimId,
        fullErrorInfo,
        setAttachmentsNew,
        attachmentsNew,
        deleteFilePaths,
        fileRepresentationsList,
        setFileRepresentationsList,
        setDeleteFilePaths,
        revalidateForm,
        setFirstValidationPerformed,
        firstValidationPerformed,
        setIsFormDirty,
        isFormDirty,
        fullWarningInfo,
        setFullWarningInfo
      }}
    >
      {children}
    </FormContext.Provider>
  );
};

export function useResetFormProvider() {
  const {
    form,
    setData,
    setClaimId,
    setErrorInfo,
    setFullErrorInfo,
    setAttachmentsNew,
    setFileRepresentationsList,
    setDeleteFilePaths,
    setFirstValidationPerformed,
    setIsFormDirty,
    setFullWarningInfo
  } = useContext(FormContext);
  useEffect(() => {
    form?.resetFields();
    setData({});
    setErrorInfo([]);
    setFullErrorInfo([]);
    setAttachmentsNew([]);
    setFileRepresentationsList([]);
    setDeleteFilePaths([]);
    setFirstValidationPerformed(false);
    setIsFormDirty(false);
    setFullWarningInfo([]);
    return () => {
      form?.resetFields();
      setData({});
      setErrorInfo([]);
      setClaimId(null);
      setFullErrorInfo([]);
      setAttachmentsNew([]);
      setFileRepresentationsList([]);
      setDeleteFilePaths([]);
      setFirstValidationPerformed(false);
      setIsFormDirty(false);
      setFullWarningInfo([]);
    };
  }, []);
}
