import { ClaimStatus } from '../ClaimStatus';

export enum OriginTypeEnum {
  DIRECT_UPLOAD = 'DIRECT_UPLOAD',
  DDE_PORTAL = 'DDE_PORTAL',
  COPY_PORTAL = 'COPY_PORTAL',
  RESUBMITTED = 'RESUBMITTED',
  RC = 'RC',
  CORRECTED = 'CORRECTED'
}
export interface SearchClaim {
  id: string;
  groupId: string;
  docId: string;
  creationDate: number;
  modifiedDate: number;
  createdBy: string;
  originalId: string;
  currentVersion: boolean;
  rejectReasons: string | null;
  providerId: string;
  userId: string;
  creationReason: string;
  sourceChannel: string;
  patientFirstName: string | null;
  patientLastName: string | null;
  patientDob: number | null;
  insuredId: string | null;
  insuredFirstName: string | null;
  insuredLastName: string | null;
  insuredDob: number | null;
  dateOfService: number | null;
  totalCharges: number | null;
  status: ClaimStatus;
  dcn: string;
  lockedBy: string | null;
  originType: OriginTypeEnum;
  resubmittedId: string | null;
}
