import { useOidcAccessToken } from '@axa-fr/react-oidc';
import { getAuditTrailByClaimId } from 'api/claims';
import { AuditTrailDTO } from 'models/DTO/AuditTrailDTO';
import React, { useEffect, useState } from 'react';
import './AuditTrail.scss';
import { Table, TableProps, notification } from 'antd';
import moment from 'moment';

interface Props {
  claimId: string;
}

const AuditTrail: React.FC<Props> = ({ claimId }) => {
  const [audit, setAudit] = useState<(AuditTrailDTO & { key: number })[]>([]);
  const [loading, setLoading] = useState(false);
  const { accessToken } = useOidcAccessToken();
  useEffect(() => {
    async function fetchAudit() {
      setLoading(true);
      try {
        const auditData = await getAuditTrailByClaimId(claimId, accessToken);
        setAudit(auditData.map((a, i) => ({ ...a, key: i })));
      } catch (e) {
        notification.error({ message: 'Failed to fetch audit trail' });
      } finally {
        setLoading(false);
      }
    }
    fetchAudit();
  }, [claimId]);

  const columns: TableProps<AuditTrailDTO & { key: number }>['columns'] = [
    {
      title: 'DCN',
      dataIndex: 'dcn',
      key: 'dcn',
      render: (val: string | null) => <div>{val ?? 'EMPTY'}</div>
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status'
    },
    {
      title: 'Created by',
      dataIndex: 'createdBy',
      key: 'createdBy'
    },
    {
      title: 'Created',
      dataIndex: 'createdOn',
      key: 'createdOn',
      render: (val) => <div>{moment(val).format('DD MMMM YYYY')}</div>
    }
  ];

  return (
    <div>
      <Table loading={loading} columns={columns} dataSource={audit} pagination={false} />
    </div>
  );
};

export default AuditTrail;
