import * as React from 'react';

import { Page } from 'components/common/Page';
import { sections } from 'config/sections';
import { MainMenu } from '@datum/react-components';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

function Home(): JSX.Element {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const parseSections = React.useMemo(() => {
    return sections.map((section) => {
      return {
        ...section,
        label: t(section.label),
        description: section.description ? t(section.description) : undefined
      };
    });
  }, [t]);

  return (
    <Page pageHelmet={t(`pages.home`)}>
      <Page.Header title={t(`pages.home`)} description={t('pages.homeDescription')}></Page.Header>
      <Page.Content>
        <MainMenu
          sections={parseSections}
          // navigateToSection={(route: string) => {
          //   navigate(route);
          // }}
          labels={{
            go: t(`go`),
            expand: t(`expand`),
            collapse: t(`collapse`)
          }}
          componentName={process.env.REACT_APP_NAME + '_home'}
        />
      </Page.Content>
    </Page>
  );
}

export default Home;
