export enum KeycloakDataEnum {
  providerNameOidc = 'provider_name',
  userAddressOidc = 'address',
  userBillingOidc = 'provider_id',
  providerAddressOidc = 'provider_address1',
  providerAddressOtherOidc = 'provider_address2',
  providerCityOidc = 'provider_city',
  providerStateOidc = 'provider_state',
  providerZipCodeOidc = 'provider_zip_code',
  npiOidc = 'NPI',
  LOBOidc = 'LOB',
  taxID = 'tax_id'
}
