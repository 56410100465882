import * as React from 'react';
import { Col, Form, Input, Row, Select } from 'antd';
import { useTranslation } from 'react-i18next';
import { DIAGNOSIS_CODES, UB04_FIELDS } from '../../../utils/ub04';
import { getYupValidator, getYupWarning } from 'utils/ub04/validataions';

type Fields = 'codeName' | 'codeSelect';

type FieldsetDiagnosisCodeProps = {
  codeNameId: string;
  codeSelectId: string;
  prefix?: string;
  placeholder?: string;
  labels?: Partial<Record<Fields, string | React.ReactElement>>;
  isRequired?: Partial<Record<Fields, boolean>>;
  isPaperForm?: boolean;
};

function FieldsetDiagnosisCode(props: FieldsetDiagnosisCodeProps): JSX.Element {
  const { codeNameId, codeSelectId, prefix, labels, placeholder, isPaperForm } = props;
  const { t } = useTranslation();

  return (
    <Row gutter={0} style={{ display: ' flex', alignItems: 'flex-end' }}>
      <Col span={isPaperForm ? 24 : 16}>
        <Form.Item
          label={labels?.codeName || 'Code'}
          name={codeNameId}
          rules={[getYupValidator(t, codeNameId), getYupWarning(t, codeNameId)]}
        >
          <Input
            prefix={prefix}
            placeholder={placeholder}
            onInput={(e: any) => (e.target.value = e.target.value.toUpperCase())}
          />
        </Form.Item>
      </Col>
      <Col span={isPaperForm ? 24 : 16}>
        <Form.Item label={labels?.codeSelect || 'Poa'} name={codeSelectId} rules={[getYupValidator(t, codeSelectId)]}>
          <Input
            prefix={prefix}
            placeholder={placeholder}
            onInput={(e: any) => (e.target.value = e.target.value.toUpperCase())}
          />
        </Form.Item>
      </Col>
    </Row>
  );
}

export default FieldsetDiagnosisCode;
