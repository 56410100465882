export const DATE_FORMAT = ['MMDDYYYY', 'MMDDYY'];

export const PLACE_OF_SERVICES = [
  '01',
  '02',
  '03',
  '04',
  '05',
  '06',
  '07',
  '08',
  '09',
  '10',
  '11',
  '12',
  '13',
  '14',
  '15',
  '16',
  '17',
  '18',
  '19',
  '20',
  '21',
  '22',
  '23',
  '24',
  '25',
  '26',
  '31',
  '32',
  '33',
  '34',
  '41',
  '42',
  '49',
  '50',
  '51',
  '52',
  '53',
  '54',
  '55',
  '56',
  '57',
  '58',
  '60',
  '61',
  '62',
  '65',
  '71',
  '72',
  '81',
  '99'
];

export const PLACE_SERVICE_FOR_AMBULANCE = ['41', '42'];
export const PROCEDURE_CODE_FOR_AMBULANCE = [
  'A0100',
  'A0140',
  'A0225',
  'A0426',
  'A0427',
  'A0428',
  'A0429',
  'A0430',
  'A0431',
  'A0432',
  'A0433',
  'A0434',
  'A0998',
  'A0999',
  'S0209',
  'T2001',
  'T2002',
  'T2003',
  'T2004',
  'T2005'
];
export const EMGS = ['Y', 'N'];
export const EPSDTS = ['Y', 'AB', 'S2', 'ST', 'NU', 'N', '03', '11'];
export const EPSDT = ['Y', 'N', 'NU', 'AV', 'S2', 'ST'];
export const FAMILY_PLANS = ['Y'];
export const QUALITY_IDS = ['0B', '1G', 'G2', 'LU', 'ZZ'];
// export const QUALITY_CODES = ['431', '439', '484'];
export const QUALITY_CODES = ['431', '484'];
export const DIAGNOSIS_CODES = ['0', '9'];
export const REFERRING_PROVIDER_QUALITY_CODES = ['DN', 'DQ', 'DK'];
export const REFERRING_PROVIDER_ID_QUALITY_CODES = ['0B', '1G', 'G2', 'LU', 'ZZ'];
export const CLAIM_QUALITY_CODES = ['9A', '9C', '9D', 'F8', 'G1', 'T4', 'Y4'];
export const OTHER_QUALITY_CODES = ['454', '304', '453', '439', '455', '471', '297', '296', '090', '091', '444', '374'];
export const OTHER_QUALITY_CODES_HCFA = ['454', '304', '453', '439', '455', '471', '090', '091', '444'];
export const RESUBMISSION_CODES = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', 'A', 'B', 'C', 'D', 'E'];
export const RESUBMISSION_CODES_HCFA = ['1', '6', '7', '8'];
export const DIAGNOSIS_POINTER = ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12'];
export const FACILITY_ID_QUALITY_CODES = ['0B', '1G', 'G2', 'AR', 'LU'];
export const FACILITY_ID_QUALITY_CODES_HCFA = ['0B', '1G', 'G2', 'LU', 'ZZ'];
export const BILLING_PROVIDER_ID_QUALITY_CODES_HCFA = ['0B', 'G2', 'ZZ'];
export const NDC_UNIT_CODES = ['F2', 'GR', 'ME', 'ML', 'UN'];
export const SIGNATURE_OPTIONS = [
  /**Do not change this */
  { label: 'SIGNED', value: 'Signed' },
  { label: 'UNSIGNED', value: '' }
];
export const MODIFIERS = [
  '01',
  '02',
  '03',
  '04',
  '0A',
  '0B',
  '0C',
  '0D',
  '0E',
  '0F',
  '0G',
  '0H',
  '0I',
  '0J',
  '0K',
  '0L',
  '0M',
  '0N',
  '0O',
  '0P',
  '0Z',
  '1A',
  '1B',
  '1C',
  '1D',
  '1E',
  '1F',
  '1G',
  '1H',
  '1I',
  '1J',
  '1P',
  '1Z',
  '22',
  '23',
  '24',
  '25',
  '26',
  '27',
  '2A',
  '2B',
  '2C',
  '2D',
  '2E',
  '2F',
  '2G',
  '2H',
  '2I',
  '2J',
  '2K',
  '2L',
  '2M',
  '2N',
  '2O',
  '2P',
  '2Q',
  '2R',
  '2S',
  '2T',
  '2Z',
  '32',
  '33',
  '3A',
  '3B',
  '3C',
  '3D',
  '3E',
  '3F',
  '3G',
  '3H',
  '3I',
  '3J',
  '3K',
  '3P',
  '3Z',
  '47',
  '4A',
  '4B',
  '4C',
  '4D',
  '4E',
  '4F',
  '4G',
  '4H',
  '4I',
  '4J',
  '4K',
  '4L',
  '4M',
  '4N',
  '4O',
  '4P',
  '4Q',
  '4R',
  '4S',
  '4T',
  '4U',
  '4Z',
  '50',
  '51',
  '52',
  '53',
  '54',
  '55',
  '56',
  '57',
  '58',
  '59',
  '5A',
  '5B',
  '5C',
  '5D',
  '5E',
  '5F',
  '5G',
  '5H',
  '5I',
  '5J',
  '5K',
  '5L',
  '5M',
  '5N',
  '5O',
  '5Z',
  '62',
  '63',
  '66',
  '6A',
  '6B',
  '6C',
  '6D',
  '6E',
  '6F',
  '6Z',
  '73',
  '74',
  '76',
  '77',
  '78',
  '79',
  '7A',
  '7B',
  '7C',
  '7D',
  '7E',
  '7F',
  '7Z',
  '80',
  '81',
  '82',
  '8A',
  '8B',
  '8C',
  '8P',
  '8Z',
  '90',
  '91',
  '92',
  '93',
  '95',
  '96',
  '97',
  '99',
  '9A',
  '9B',
  '9C',
  '9D',
  '9L',
  '9M',
  '9N',
  '9O',
  '9P',
  '9Q',
  '9Z',
  'A1',
  'A2',
  'A3',
  'A4',
  'A5',
  'A6',
  'A7',
  'A8',
  'A9',
  'AA',
  'AD',
  'AE',
  'AF',
  'AG',
  'AH',
  'AI',
  'AJ',
  'AK',
  'AM',
  'AO',
  'AP',
  'AQ',
  'AR',
  'AS',
  'AT',
  'AU',
  'AV',
  'AW',
  'AX',
  'AY',
  'AZ',
  'BA',
  'BL',
  'BO',
  'BP',
  'BR',
  'BU',
  'CA',
  'CB',
  'CC',
  'CD',
  'CE',
  'CF',
  'CG',
  'CH',
  'CI',
  'CJ',
  'CK',
  'CL',
  'CM',
  'CN',
  'CO',
  'CP',
  'CQ',
  'CR',
  'CS',
  'CT',
  'CQ',
  'DA',
  'DC',
  'DD',
  'DE',
  'DF',
  'DG',
  'DH',
  'DI',
  'DJ',
  'DN',
  'DO',
  'DP',
  'DR',
  'DS',
  'DU',
  'DW',
  'DX',
  'E1',
  'E2',
  'E3',
  'E4',
  'EA',
  'EB',
  'EC',
  'ED',
  'EE',
  'EF',
  'EG',
  'EH',
  'EI',
  'EJ',
  'EM',
  'EN',
  'EO',
  'EP',
  'ER',
  'ES',
  'ET',
  'EU',
  'EW',
  'EX',
  'EY',
  'F1',
  'F2',
  'F3',
  'F4',
  'F5',
  'F6',
  'F7',
  'F8',
  'F9',
  'FA',
  'FB',
  'FC',
  'FP',
  'FQ',
  'FR',
  'FS',
  'FT',
  'FX',
  'FY',
  'G0',
  'G1',
  'G2',
  'G3',
  'G4',
  'G5',
  'G6',
  'G7',
  'G8',
  'G9',
  'GA',
  'GB',
  'GC',
  'GD',
  'GE',
  'GF',
  'GG',
  'GH',
  'GI',
  'GJ',
  'GK',
  'GL',
  'GM',
  'GN',
  'GO',
  'GP',
  'GQ',
  'GR',
  'GS',
  'GT',
  'GU',
  'GV',
  'GW',
  'GX',
  'GY',
  'GZ',
  'H9',
  'HA',
  'HB',
  'HC',
  'HD',
  'HE',
  'HF',
  'HG',
  'HH',
  'HI',
  'HJ',
  'HK',
  'HL',
  'HM',
  'HN',
  'HO',
  'HP',
  'HQ',
  'HR',
  'HS',
  'HT',
  'HU',
  'HV',
  'HW',
  'HX',
  'HY',
  'HZ',
  'IC',
  'ID',
  'IE',
  'IF',
  'IG',
  'IH',
  'II',
  'IJ',
  'IN',
  'IO',
  'IP',
  'IR',
  'IS',
  'IU',
  'IW',
  'IX',
  'J1',
  'J2',
  'J3',
  'J4',
  'J5',
  'JA',
  'JB',
  'JC',
  'JD',
  'JE',
  'JF',
  'JG',
  'JH',
  'JI',
  'JJ',
  'JN',
  'JO',
  'JP',
  'JR',
  'JS',
  'JU',
  'JW',
  'JX',
  'K0',
  'K1',
  'K2',
  'K3',
  'K4',
  'KA',
  'KB',
  'KC',
  'KD',
  'KE',
  'KF',
  'KG',
  'KH',
  'KI',
  'KJ',
  'KK',
  'KL',
  'KM',
  'KN',
  'KO',
  'KP',
  'KQ',
  'KR',
  'KS',
  'KT',
  'KU',
  'KV',
  'KW',
  'KX',
  'KY',
  'KZ',
  'L1',
  'LC',
  'LD',
  'LL',
  'LM',
  'LR',
  'LS',
  'LT',
  'M2',
  'MA',
  'MB',
  'MC',
  'MD',
  'ME',
  'MF',
  'MG',
  'MH',
  'MS',
  'NB',
  'NC',
  'ND',
  'NE',
  'NF',
  'NG',
  'NH',
  'NI',
  'NJ',
  'NN',
  'NO',
  'NP',
  'NR',
  'NS',
  'NU',
  'NW',
  'NX',
  'P1',
  'P2',
  'P3',
  'P4',
  'P5',
  'P6',
  'PA',
  'PB',
  'PC',
  'PD',
  'PE',
  'PF',
  'PG',
  'PH',
  'PI',
  'PJ',
  'PL',
  'PM',
  'PN',
  'PO',
  'PP',
  'PR',
  'PS',
  'PT',
  'PU',
  'PW',
  'PX',
  'Q0',
  'Q1',
  'Q2',
  'Q3',
  'Q4',
  'Q5',
  'Q6',
  'Q7',
  'Q8',
  'Q9',
  'QA',
  'QB',
  'QC',
  'QD',
  'QE',
  'QF',
  'QG',
  'QH',
  'QJ',
  'QK',
  'QL',
  'QM',
  'QN',
  'QP',
  'QQ',
  'QR',
  'QS',
  'QT',
  'QW',
  'QX',
  'QY',
  'QZ',
  'RA',
  'RB',
  'RC',
  'RD',
  'RE',
  'RF',
  'RG',
  'RH',
  'RI',
  'RJ',
  'RN',
  'RO',
  'RP',
  'RR',
  'RS',
  'RT',
  'RU',
  'RW',
  'RX',
  'SA',
  'SB',
  'SC',
  'SD',
  'SE',
  'SF',
  'SG',
  'SH',
  'SI',
  'SJ',
  'SK',
  'SL',
  'SM',
  'SN',
  'SO',
  'SP',
  'SQ',
  'SR',
  'SS',
  'ST',
  'SU',
  'SV',
  'SW',
  'SX',
  'SY',
  'SZ',
  'T1',
  'T2',
  'T3',
  'T4',
  'T5',
  'T6',
  'T7',
  'T8',
  'T9',
  'TA',
  'TB',
  'TC',
  'TD',
  'TE',
  'TF',
  'TG',
  'TH',
  'TJ',
  'TK',
  'TL',
  'TM',
  'TN',
  'TP',
  'TQ',
  'TR',
  'TS',
  'TT',
  'TU',
  'TV',
  'TW',
  'U1',
  'U2',
  'U3',
  'U4',
  'U5',
  'U6',
  'U7',
  'U8',
  'U9',
  'UA',
  'UB',
  'UC',
  'UD',
  'UE',
  'UF',
  'UG',
  'UH',
  'UJ',
  'UK',
  'UN',
  'UP',
  'UQ',
  'UR',
  'US',
  'V1',
  'V2',
  'V3',
  'V4',
  'V5',
  'V6',
  'V7',
  'V8',
  'V9',
  'VM',
  'VP',
  'VY',
  'WU',
  'WX',
  'X1',
  'X2',
  'X3',
  'X4',
  'X5',
  'XE',
  'XP',
  'XS',
  'XU',
  'Z1',
  'Z2',
  'ZA',
  'ZB',
  'ZC'
];

export enum HCFA33PhysicianEnum {
  physician = 'physician',
  supplier = 'supplier'
}

export enum HCFA23NumberType {
  priorAuthnumber = 'priorAuthnumber',
  cliaNumber = 'cliaNumber',
  zipCode = 'zipCode'
}

export const FORM_ACTIONS_CONTAINER_ID = 'FORM_ACTIONS_CONTAINER_ID';
