import React, { useContext } from 'react';
import { Col, DatePicker, Form, Input, Row, Select } from 'antd';
import { useTranslation } from 'react-i18next';
import { DATE_FORMAT } from 'utils/hcfaConstants';
import { getYupValidator, getYupWarning } from 'utils/ub04/validataions';

import { toAlphaNumUppercase } from 'helpers/toAlphaNumUppercase';
import DatePickerAutoPopulate from 'components/common/DatePickerAutoPopulate';
import { FormContext } from 'components/common/FormProvider';

type Fields = 'code' | 'date';

type FieldsetOcurrenceProps = {
  codeId: string;
  dateId: string;
  labels?: Partial<Record<Fields, string>>;
  isRequired?: Partial<Record<Fields, boolean>>;
};

function FieldsetOccurrence(props: FieldsetOcurrenceProps): JSX.Element {
  const { codeId, dateId, labels } = props;
  const { t } = useTranslation();
  const { form } = useContext(FormContext);

  return (
    <Row gutter={0}>
      <Col span={10}>
        <Form.Item
          className="field--first"
          label={labels?.code || 'Code'}
          name={codeId}
          rules={[getYupValidator(t, codeId)]}
        >
          <Input onInput={toAlphaNumUppercase} />
        </Form.Item>
      </Col>
      <Col span={14}>
        <Form.Item
          label={labels?.date || 'Date'}
          name={dateId}
          rules={[getYupValidator(t, dateId), getYupWarning(t, dateId)]}
        >
          <DatePickerAutoPopulate
            suffixIcon={null}
            style={{ width: '100%' }}
            panelRender={() => false}
            format={DATE_FORMAT}
            placeholder={t('common:fields.dateFormat')}
            name={dateId}
            form={form!}
          />
        </Form.Item>
      </Col>
    </Row>
  );
}

export default FieldsetOccurrence;
