import * as yup from 'yup';
import { TFunction, t } from 'i18next';

import { HCFA_FIELDS } from './fields';
import { Rule } from 'antd/lib/form';
import { StoreValue } from 'antd/lib/form/interface';
import {
  testAccidentStateWhenAutoAccident,
  testNameToNPI32Supplier,
  testAmountPaidToTotal,
  testCurrentDate14ToAccidentAndQualifier,
  testCurrentIllnessBeforeDOSFrom,
  testFutureDate,
  testHospitalizationToAfterFrom,
  testIndividualCharge,
  testIndividualInsuredAddressFieldsToAddress,
  testIsBeforeBirthDate,
  testIsPositive,
  testIsToAfterFrom,
  testIsValidCurrency,
  testLabCharges,
  testModifiersToCPT1,
  testModifiersToCPT2,
  testModifiersToCPT3,
  testModifiersToCPT4,
  // testNPIforLuhnAlghoritm,
  testOriginalRefNumToRessubmission,
  testOtherDateBeforeDOSFrom,
  testOtherDateToBOx10,
  testOtherDateToQualifier,
  testOtherQualifierToOtherDate,
  testOtherQualityToBox10,
  testPatientInsuredRegardingRelation,
  testProviderNameToPhysician,
  testProviderNameToSupplier,
  testQualifierToCurrentDate,
  testQualifierToRenderingProviderIdInTable,
  testRefferingNPIToRefferingFirstLastName,
  testRefferingProviderFirstLastNameToNPIId,
  testRelationRegardingPatientInsured,
  testSignature,
  // testTableUnits,
  testTotalChargeToTableFields,
  testAddressToNPI32,
  testNameToNPI32Physician,
  testFacilityIdQualityToNPIandId,
  testIsBillingIdAndIdBlank,
  testAmbulanceFromStateLocation,
  testAmbulanceToStateLocation,
  testBillingIdQualityToNPIandId,
  // testPhysicalAddress,
  testTableUnits,
  diagnosisCodeLookup,
  zipCodeLookup,
  testAuthorizationNumber,
  testProcedureCodeLookup,
  testFacilityAddressAndCityToFacilityNPI,
  testFacilityStateToName,
  testUnableToWorkToAfterFrom,
  testInsuredIdNumeric,
  testInsuredIdAlphanumeric,
  testNIPIsRequired,
  testFullAddressDependency,
  testDOBRequired,
  testAmb001,
  testAmb004,
  testPhysicalAddressRequired,
  testIsDateValid,
  testDiagnosisIllnesAndPointer,
  testZipCode5or9IgnoreHyphens,
  testNPIforLuhnAlghoritm,
  testPhysicalAddress
} from './customRules';
import { getHcfaWarningSchema, getHcfaWarningServiceTableShape, knownWarnings } from './warningSchema';
import { getFormProvidersDataFromGlobalObject } from './putFormProvidersDataInGlobalObject';

const alphaWithSpecChar = /^[A-Za-z !@#$%^&*()_+{}\[\]:;<>,.?~\\/'"-]+$/;
const alphaNumericWithSpecChar = /^[A-Za-z0-9 !@#$%^&*()_+{}\[\]:;<>,.?~\\/'"-]+$/;
const alphaNumericWithDot = /^[A-Za-z0-9 .]+$/;
export const alphaNum = /^[a-zA-Z0-9]+$/;
export const alphaNumSpaces = /^[a-zA-Z0-9\s]+$/;
const alphaNumWithSpaces = /^[a-zA-Z0-9\s]+$/;
const diagnosisPointerAL = /^(?:[A-L]|[A-L]{2,})-?(?:[1-9]|1[0-2])?[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]*$/;
const diagnosisPointer = /^([A-L]+|[1-9]|1[0-2]+)$/;
export const cliaNumber = /^\d{2}D\d{7}$/;
const alpha = /^[A-Za-z]+$/;
const alphaSpace = /^[A-Za-z ]+$/;
const numeric = /^[0-9]+$/;
// const alphaNumWithDecimal = /^[A-Z]\d{2}[A-Z0-9]{0,4}(\.\d+)?$/;
export const numericLengthFiveOrNine = /^(\d{5}|\d{9})$/;
const statePattern = /^[a-zA-Z]{2}$/;
const alphaNumLengthTwo = /^[a-zA-Z0-9]{2}$/;
const numericLengthEleven = /^[0-9]{11}$/;
const alphaNumLengthTwelveEndedNumber = /^[a-zA-Z0-9]{10}[0]{1}[0-9]{1}$/;

const alphaWithSpecCharVal = '[A-Z spec char]';
const alphaNumericWithSpecCharVal = '[A-Z 0-9 spec char]';
const alphaNumVal = '[A-Z and 0-9]';
const alphaNumWithSpacesVal = '[A-Z 0-9]';
const alphaNumWithDotVal = '[A-Z 0-9 and dot]';
const diagnosisPointerVal = '[A-L] or [1-12]';
const diagnosisPointerALVal = '[A-L], [1-12]';
const cliaNumberVal = '10 digits and 3rd character has to be "D"';
const alphaVal = '[A-Z]';
const alphaSpaceVal = '[A-Z]';
const numericVal = '[0-9]';
export const diagnoseCodeLookupMsg = 'Code was not found';
const icd10Regex = /^[a-zA-Z0-9]+(?:\.[0-9]+)?$/;
const alphaNumWithDecimalVal = '[A-Z 0-9 with decimals]';
const alphaNumStartsEndsWithNumberVal = 'that starts and ends with a number and contains only leters and numbers';
export const dateInvalidMsg =
  'Field does not meet length requirement, please re-enter correct value[6/8] (MMDDYY, MMDDYYYY)';

const commonNameValidation = (t: TFunction, maxLength: number) =>
  yup
    .string()
    .nullable()
    .transform((v, o) => (o === '' ? null : v))
    .matches(alphaWithSpecChar, t('common:fieldErrors.VALID001', { validation: alphaWithSpecCharVal }))
    .min(1, t('common:fieldErrors.LEN001', { length: `(1-${maxLength})` }))
    .max(maxLength, t('common:fieldErrors.LEN001', { length: `(1-${maxLength})` }));

const commonAddressValidation = (t: TFunction) =>
  yup
    .string()
    .nullable()
    .transform((v, o) => (o === '' ? null : v))
    .matches(alphaNumericWithSpecChar, t('common:fieldErrors.VALID001', { validation: alphaNumericWithSpecCharVal }))
    .min(1, t('common:fieldErrors.LEN001', { length: '(1-55)' }))
    .max(55, t('common:fieldErrors.LEN001', { length: '(1-55)' }));

const commonCityValidation = (t: TFunction) =>
  yup
    .string()
    .nullable()
    .transform((v, o) => (o === '' ? null : v))
    .matches(alphaSpace, t('common:fieldErrors.VALID001', { validation: alphaSpaceVal }))
    .min(1, t('common:fieldErrors.LEN001', { length: '(1-30)' }))
    .max(30, t('common:fieldErrors.LEN001', { length: '(1-30)' }));

export const commonZipcodeValidation = (t: TFunction) =>
  yup
    .string()
    .nullable()
    .transform((v, o) => (o === '' ? null : v))
    // .matches(numericLengthFiveOrNine, t('common:fieldErrors.ZIPCODE'))
    .test('common-zipCode', t('common:fieldErrors.ZIPCODE'), testZipCode5or9IgnoreHyphens);
// .test(
//   'zip-lookup',
//   'Please review the entered value, it is not found in the standard list of values',
//   zipCodeLookup
// );
// const commonDiagnosisCodeValidation = (t: TFunction) =>
//   yup
//     .string()
//     .nullable()
//     .transform((v, o) => (o === '' ? null : v))
//     .matches(icd10Regex, t('common:fieldErrors.VALID001', { validation: alphaNumWithDecimalVal }))
//     .min(3, t('common:fieldErrors.LEN001', { length: '(3-7)' }))
//     .max(7, t('common:fieldErrors.ICD001'));
// const commonDiagnosisCodeValidation09 = (t: TFunction) =>
//   yup
//     .string()
//     .nullable()
//     .transform((v, o) => (o === '' ? null : v))
//     .matches(icd10Regex, t('common:fieldErrors.VALID001', { validation: alphaNumWithDecimalVal }))
//     .min(3, t('common:fieldErrors.LEN001', { length: '(3-5)' }))
//     .max(5, t('common:fieldErrors.ICD001'));
const insuredFirstNameSchema = (t: TFunction, maxLength: number) =>
  yup
    .string()
    .nullable()
    .transform((v, o) => (o === '' ? null : v))
    .matches(alphaWithSpecChar, t('common:fieldErrors.VALID001', { validation: alphaWithSpecCharVal }))
    .required(t('common:fieldErrors.MUST001'))
    .min(1, t('common:fieldErrors.LEN001', { length: `(1-${maxLength})` }))
    .max(maxLength, t('common:fieldErrors.LEN001', { length: `(1-${maxLength})` }))
    .test('custom-insuredFirstName', t('common:fieldErrors.MUST001'), testPatientInsuredRegardingRelation);

const getHcfaServiceTableShape = (t: TFunction) =>
  yup.object().shape({
    '24b_place_of_service': yup
      .string()
      .nullable()
      .transform((v, o) => (o === '' ? null : v))
      .required(t('common:fieldErrors.MUST001')),
    [HCFA_FIELDS.procedure24]: yup
      .string()
      .nullable()
      .transform((v, o) => (o === '' ? null : v))
      .required(t('common:fieldErrors.MUST001'))
      .matches(alphaNum, t('common:fieldErrors.VALID001', { validation: alphaNumVal }))
      .min(5, t('common:fieldErrors.LEN001', { length: '(5)' }))
      .max(5, t('common:fieldErrors.LEN001', { length: '(5)' })),
    // .test(
    //   'procedure-lookup24',
    //   'Please review the entered value, it is not found in the standard list of values',
    //   testProcedureCodeLookup
    // ),
    [HCFA_FIELDS.diagnosisPointer]: yup
      .string()
      .nullable()
      .transform((v, o) => (o === '' ? null : v))
      .required(t('common:fieldErrors.MUST001'))
      .matches(diagnosisPointer, t('common:fieldErrors.VALID001', { validation: diagnosisPointerVal }))
      .min(1, t('common:fieldErrors.LEN001', { length: '(1-30)' }))
      .max(30, t('common:fieldErrors.LEN001', { length: '(1-30)' }))
      .test('custom-diagnosisPointer', t('common:fieldErrors.ICD002'), testDiagnosisIllnesAndPointer)
      .transform((v, o) => (o === '' ? null : v)),
    [HCFA_FIELDS.chargesf24]: yup
      .string()
      .nullable()
      .required(t('common:fieldErrors.MUST001'))
      .max(11, t('common:fieldErrors.LEN001', { length: '(11)' }))
      //.test('custom-chargesf24', 'Max charge is 15000 $', testIndividualCharge)
      .transform((v, o) => (o === '' ? null : v)),
    '24g_units': yup
      .string()
      .nullable()
      .transform((v, o) => (o === '' ? null : v))
      .required(t('common:fieldErrors.MUST001'))
      .min(1, t('common:fieldErrors.LEN001', { length: '(1-15)' }))
      .max(15, t('common:fieldErrors.LEN001', { length: '(1-15)' }))
      .test('custom-24g_units', 'Days or Units value less than 1', testIsPositive),
    /**NDC field still not in form */
    // .test('custom-24g_units', 'Field cannot be left blank when NDC Code is entered', testTableUnits),
    '24i_render_id_qual': yup.string().nullable(),
    // .test(
    //   'custom-24i_render_id_qual',
    //   'Qualifier present without Rendering Provider ID',
    //   testQualifierToRenderingProviderIdInTable
    // ),
    // .test('custom-24i_render_id_qual', 'Field cannot be left blank when NDC Code is entered', testTableUnits),
    [HCFA_FIELDS.epsdt24]: yup
      .string()
      .nullable()
      .transform((v, o) => (o === '' ? null : v))
      .matches(alphaNum, t('common:fieldErrors.VALID001', { validation: alphaNumVal }))
      .min(1, t('common:fieldErrors.LEN001', { length: '(1-4)' }))
      .max(4, t('common:fieldErrors.LEN001', { length: '(1-4)' })),
    [HCFA_FIELDS.renderId24]: yup
      .string()
      .nullable()
      .transform((v, o) => (o === '' ? null : v))
      .matches(alphaNum, t('common:fieldErrors.VALID001', { validation: alphaNumVal }))
      .min(2, t('common:fieldErrors.LEN001', { length: '(2-50)' }))
      .max(50, t('common:fieldErrors.LEN001', { length: '(2-50)' })),
    [HCFA_FIELDS.renderNPI24]: yup
      .string()
      .nullable()
      .transform((v, o) => (o === '' ? null : v))
      .matches(numeric, t('common:fieldErrors.VALID001', { validation: numericVal }))
      .min(10, t('common:fieldErrors.LEN001', { length: '(10)' }))
      .max(10, t('common:fieldErrors.LEN001', { length: '(10)' }))
      .test('custom-renderNPI24', 'NPI is not valid', testNPIforLuhnAlghoritm),
    // .test('custom-requiredNPI24', t('common:fieldErrors.MUST001'), testNIPIsRequired),
    '24a_dos_from': yup
      .string()
      .nullable()
      .required(t('common:fieldErrors.MUST001'))
      .test('custom-24a_dos_fromfuture', t('common:fieldErrors.DATE001'), testFutureDate)
      .test(
        'custom-24a_dos_from-afterBirth',
        "Date entered cannot be earlier than Patient's Date of Birth, please re-enter correct value or correct Patient Date of Birth",
        testIsBeforeBirthDate
      )
      .test('custom-validDate', dateInvalidMsg, testIsDateValid),
    '24a_dos_to': yup
      .string()
      .nullable()
      .required(t('common:fieldErrors.MUST001'))
      .test('custom-24a_dos_tofuture', t('common:fieldErrors.DATE001'), testFutureDate)
      .test(
        'custom-24a_dos_to-afterBirth',
        "Date entered cannot be earlier than Patient's Date of Birth, please re-enter correct value or correct Patient Date of Birth",
        testIsBeforeBirthDate
      )
      .test(
        'custom-24a_dos_to-afterFrom',
        'Date entered is earlier than From Date, please re-enter correct value',
        testIsToAfterFrom
      )
      .test('custom-validDate', dateInvalidMsg, testIsDateValid),
    '24d_modifier_1': yup
      .string()
      .nullable()
      .test('custom-24dModifier_1', 'Duplicate Modifier, please check value', testModifiersToCPT1),
    '24d_modifier_2': yup
      .string()
      .nullable()
      .test('custom-24dModifier_1', 'Duplicate Modifier, please check value', testModifiersToCPT2),
    '24d_modifier_3': yup
      .string()
      .nullable()
      .test('custom-24dModifier_1', 'Duplicate Modifier, please check value', testModifiersToCPT3),
    '24d_modifier_4': yup
      .string()
      .nullable()
      .test('custom-24dModifier_1', 'Duplicate Modifier, please check value', testModifiersToCPT4),
    [HCFA_FIELDS.ndcCode]: yup
      .string()
      .nullable()
      .transform((v, o) => (o === '' ? null : v))
      .min(11, t('common:fieldErrors.LEN001', { length: '(11)' }))
      .max(11, t('common:fieldErrors.LEN001', { length: '(11)' }))
      .matches(numericLengthEleven, t('common:fieldErrors.VALID001', { validation: '[0-9] 11 chars' })),
    [HCFA_FIELDS.ndcQuantity]: yup
      .string()
      .nullable()
      .transform((v, o) => (o === '' ? null : v))
      .matches(alphaNumLengthTwo, t('common:fieldErrors.VALID001', { validation: '[A-Z and 0-9] 2 chars' }))
      .test('custom-ndcQuantity', 'Field cannot be left blank when NDC Code is entered', testTableUnits),
    [HCFA_FIELDS.ndcUnit]: yup
      .string()
      .nullable()
      .transform((v, o) => (o === '' ? null : v))
      .min(1, t('common:fieldErrors.LEN001', { length: '(1-15)' }))
      .max(15, t('common:fieldErrors.LEN001', { length: '(1-15)' }))
      .test('custom-ndcUnit', 'Field cannot be left blank when NDC Code is entered', testTableUnits)
  });

export const getHcfaSchema = (t: TFunction) =>
  yup.object().shape({
    [HCFA_FIELDS.insuranceType]: yup.string().nullable(),
    [HCFA_FIELDS.patRelInfo6]: yup
      .string()
      .nullable()
      .transform((v, o) => (o === '' ? null : v))
      .test('custom-patRelInfo6', t('common:fieldErrors.REL002'), testRelationRegardingPatientInsured),
    [HCFA_FIELDS.servicesTable]: yup
      .array()
      .min(1, 'At least one item is required in the Service list')
      .required(t('common:fieldErrors.MUST001'))
      .of(getHcfaServiceTableShape(t)),
    [HCFA_FIELDS.patientLastName]: yup
      .string()
      .nullable()
      .transform((v, o) => (o === '' ? null : v))
      .required(t('common:fieldErrors.MUST001'))
      .matches(alphaWithSpecChar, t('common:fieldErrors.VALID001', { validation: alphaWithSpecCharVal }))
      .min(1, t('common:fieldErrors.LEN001', { length: '(1-60)' }))
      .max(60, t('common:fieldErrors.LEN001', { length: '(1-60)' }))
      .test('custom-patientLastName', t('common:fieldErrors.MUST001'), testPatientInsuredRegardingRelation),
    [HCFA_FIELDS.patientFirstName]: yup
      .string()
      .nullable()
      .transform((v, o) => (o === '' ? null : v))
      .required(t('common:fieldErrors.MUST001'))
      .matches(alphaWithSpecChar, t('common:fieldErrors.VALID001', { validation: alphaWithSpecCharVal }))
      .min(1, t('common:fieldErrors.LEN001', { length: '(1-35)' }))
      .max(35, t('common:fieldErrors.LEN001', { length: '(1-35)' }))
      .test('custom-patientFirstName', t('common:fieldErrors.MUST001'), testPatientInsuredRegardingRelation),
    [HCFA_FIELDS.patientMiddleInitial]: yup
      .string()
      .nullable()
      .transform((v, o) => (o === '' ? null : v))
      .matches(alpha, t('common:fieldErrors.VALID001', { validation: alphaVal }))
      .min(1, t('common:fieldErrors.LEN001', { length: '(1-25)' }))
      .max(25, t('common:fieldErrors.LEN001', { length: '(1-25)' })),
    [HCFA_FIELDS.otherInsuredId]: yup.string().required(t('common:fieldErrors.MUST001')).nullable(),
    [HCFA_FIELDS.pat_addr2]: commonAddressValidation(t),
    [HCFA_FIELDS.ins_addr2]: commonAddressValidation(t),

    [HCFA_FIELDS.patientGen]: yup
      .string()
      .nullable()
      .transform((v, o) => (o === '' ? null : v)),
    [HCFA_FIELDS.patientSex]: yup
      .string()
      .nullable()
      .transform((v, o) => (o === '' ? null : v)),
    [HCFA_FIELDS.patientAddress]: commonAddressValidation(t).required(t('common:fieldErrors.MUST001')),
    [HCFA_FIELDS.patientCity]: commonCityValidation(t).required(t('common:fieldErrors.MUST001')),
    [HCFA_FIELDS.patientState]: yup
      .string()
      .nullable()
      .transform((v, o) => (o === '' ? null : v))
      .required(t('common:fieldErrors.MUST001'))
      .matches(statePattern, 'State field should be 2 characters'),
    [HCFA_FIELDS.patientZip]: commonZipcodeValidation(t).required(t('common:fieldErrors.MUST001')),
    [HCFA_FIELDS.physicianLastName]: yup
      .string()
      .nullable()
      .transform((v, o) => (o === '' ? null : v))
      .matches(alphaWithSpecChar, t('common:fieldErrors.VALID001', { validation: alphaWithSpecCharVal }))
      .min(1, t('common:fieldErrors.LEN001', { length: '(1-60)' }))
      .max(60, t('common:fieldErrors.LEN001', { length: '(1-60)' })),
    [HCFA_FIELDS.physicianFirstName]: yup
      .string()
      .nullable()
      .transform((v, o) => (o === '' ? null : v))
      .matches(alphaWithSpecChar, t('common:fieldErrors.VALID001', { validation: alphaWithSpecCharVal }))
      .min(1, t('common:fieldErrors.LEN001', { length: '(1-35)' }))
      .max(35, t('common:fieldErrors.LEN001', { length: '(1-35)' })),
    [HCFA_FIELDS.physicianMiddleInitial]: yup
      .string()
      .nullable()
      .transform((v, o) => (o === '' ? null : v))
      .matches(alpha, t('common:fieldErrors.VALID001', { validation: alphaVal }))
      .min(1, t('common:fieldErrors.LEN001', { length: '(1-25)' }))
      .max(25, t('common:fieldErrors.LEN001', { length: '(1-25)' })),
    [HCFA_FIELDS.physicianGeneration]: yup
      .string()
      .nullable()
      .transform((v, o) => (o === '' ? null : v)),
    [HCFA_FIELDS.HCFA33Generation]: yup
      .string()
      .nullable()
      .transform((v, o) => (o === '' ? null : v)),
    [HCFA_FIELDS.physicianMiddleName]: yup
      .string()
      .nullable()
      .transform((v, o) => (o === '' ? null : v))
      .matches(alpha, t('common:fieldErrors.VALID001', { validation: alphaVal }))
      .min(1, t('common:fieldErrors.LEN001', { length: '(1-25)' }))
      .max(25, t('common:fieldErrors.LEN001', { length: '(1-25)' })),
    [HCFA_FIELDS.physicianSuffix]: yup
      .string()
      .nullable()
      .transform((v, o) => (o === '' ? null : v))
      .matches(alpha, t('common:fieldErrors.VALID001', { validation: alphaVal }))
      .min(1, t('common:fieldErrors.LEN001', { length: '(1-10)' }))
      .max(10, t('common:fieldErrors.LEN001', { length: '(1-10)' })),
    [HCFA_FIELDS.physicianCredential]: yup
      .string()
      .nullable()
      .transform((v, o) => (o === '' ? null : v))
      .matches(alpha, t('common:fieldErrors.VALID001', { validation: alphaVal }))
      .min(1, t('common:fieldErrors.LEN001', { length: '(1-10)' }))
      .max(10, t('common:fieldErrors.LEN001', { length: '(1-10)' })),

    [HCFA_FIELDS.providersPhysicianLastname]: yup
      .string()
      .nullable()
      .transform((v, o) => (o === '' ? null : v))
      .matches(alphaWithSpecChar, t('common:fieldErrors.VALID001', { validation: alphaWithSpecCharVal }))
      .min(1, t('common:fieldErrors.LEN001', { length: '(1-60)' }))
      .max(60, t('common:fieldErrors.LEN001', { length: '(1-60)' }))
      .test('custom-providersPhysicianLastname', t('common:fieldErrors.NPI001'), testProviderNameToPhysician)
      .test('custom-providersPhysicianLastnameNPI', t('common:fieldErrors.NPI001'), testNameToNPI32Physician),
    [HCFA_FIELDS.providersPhysicianFirstname]: yup
      .string()
      .nullable()
      .transform((v, o) => (o === '' ? null : v))
      .matches(alphaWithSpecChar, t('common:fieldErrors.VALID001', { validation: alphaWithSpecCharVal }))
      .min(1, t('common:fieldErrors.LEN001', { length: '(1-35)' }))
      .max(35, t('common:fieldErrors.LEN001', { length: '(1-35)' }))
      .test('custom-providersPhysicianFirstname', t('common:fieldErrors.NPI001'), testProviderNameToPhysician)
      .test('custom-providersPhysicianFirstnameNPI', t('common:fieldErrors.NPI001'), testNameToNPI32Physician),
    [HCFA_FIELDS.providersPhysicianMiddleIntial]: yup
      .string()
      .nullable()
      .transform((v, o) => (o === '' ? null : v))
      .matches(alpha, t('common:fieldErrors.VALID001', { validation: alphaVal }))
      .min(1, t('common:fieldErrors.LEN001', { length: '(1-25)' }))
      .max(25, t('common:fieldErrors.LEN001', { length: '(1-25)' })),

    [HCFA_FIELDS.patientPhone]: yup
      .string()
      .nullable()
      .transform((v, o) => (o === '' ? null : v))
      .matches(numeric, t('common:fieldErrors.VALID001', { validation: numericVal }))
      .min(10, t('common:fieldErrors.LEN001', { length: '(10)' }))
      .max(10, t('common:fieldErrors.LEN001', { length: '(10)' })),

    [HCFA_FIELDS.patientSignature]: yup
      .string()
      .nullable()
      .test('custom-signatureOfPhysician', t('common:fieldErrors.MUST001'), testSignature),
    [HCFA_FIELDS.insuredSignature]: yup
      .string()
      .nullable()
      .test('custom-signatureOfPhysician', t('common:fieldErrors.MUST001'), testSignature),
    [HCFA_FIELDS.insuredSignatureDate]: yup
      .string()
      .nullable()
      .test('custom-patientSignedDatefuture', t('common:fieldErrors.DATE001'), testFutureDate)
      .test('custom-validDate', dateInvalidMsg, testIsDateValid),
    [HCFA_FIELDS.otherCoverage]: yup.string().nullable(),
    [HCFA_FIELDS.insuredId]: yup
      .string()
      .nullable()
      .transform((v, o) => (o === '' ? null : v))
      .required(t('common:fieldErrors.MUST001'))
      .test('custom-insuredState', t('common:fieldErrors.VALID001', { validation: numericVal }), testInsuredIdNumeric)
      .test('custom-insuredState', t('common:fieldErrors.AlphaInsured'), testInsuredIdAlphanumeric)
      // .test('custom-insuredState', t('common:fieldErrors.LEN001', { length: '(5-15)' }), testInsuredIdLength)
      .min(5, t('common:fieldErrors.LEN001', { length: '(5 - 15)' }))
      .max(15, t('common:fieldErrors.LEN001', { length: '(5 -15)' })),

    [HCFA_FIELDS.insuredLastName]: insuredFirstNameSchema(t, 60),
    [HCFA_FIELDS.insuredFirstName]: insuredFirstNameSchema(t, 35),
    [HCFA_FIELDS.insuredMiddleInitial]: yup
      .string()
      .nullable()
      .transform((v, o) => (o === '' ? null : v))
      .matches(alpha, t('common:fieldErrors.VALID001', { validation: alphaVal }))
      .min(1, t('common:fieldErrors.LEN001', { length: '(1-25)' }))
      .max(25, t('common:fieldErrors.LEN001', { length: '(1-25)' })),
    [HCFA_FIELDS.insuredGen]: yup.string().nullable(),
    [HCFA_FIELDS.insuredAddress]: commonAddressValidation(t),
    [HCFA_FIELDS.insuredCity]: commonCityValidation(t).test(
      'custom-insuredCity',
      'Full Address information is required, please enter all details',
      testIndividualInsuredAddressFieldsToAddress
    ),
    [HCFA_FIELDS.insuredState]: yup
      .string()
      .nullable()
      .transform((v, o) => (o === '' ? null : v))
      .matches(statePattern, 'State field should be 2 characters')
      .test('custom-insuredState', t('common:fieldErrors.MUST001'), testPatientInsuredRegardingRelation)
      .test(
        'custom-insuredState',
        'Full Address information is required, please enter all details',
        testIndividualInsuredAddressFieldsToAddress
      ),

    [HCFA_FIELDS.insuredZip]: commonZipcodeValidation(t).test(
      'custom-insuredZip',
      'Full Address information is required, please enter all details',
      testIndividualInsuredAddressFieldsToAddress
    ),
    [HCFA_FIELDS.insuredPhone]: yup
      .string()
      .nullable()
      .transform((v, o) => (o === '' ? null : v))
      .matches(numeric, t('common:fieldErrors.VALID001', { validation: numericVal }))
      .min(10, t('common:fieldErrors.LEN001', { length: '(10)' }))
      .max(10, t('common:fieldErrors.LEN001', { length: '(10)' })),
    [HCFA_FIELDS.reservedForNuccUse1]: yup
      .string()
      .nullable()
      .transform((v, o) => (o === '' ? null : v))
      // .matches(numeric, t('common:fieldErrors.VALID001', { validation: numericVal }))
      .matches(alphaNumericWithSpecChar, t('common:fieldErrors.VALID001', { validation: alphaNumericWithSpecCharVal }))
      .min(1, t('common:fieldErrors.LEN001', { length: '(1-10)' }))
      .max(10, t('common:fieldErrors.LEN001', { length: '(1-10)' })),
    [HCFA_FIELDS.otherInsuredId]: yup
      .string()
      .nullable()
      .transform((v, o) => (o === '' ? null : v))
      .matches(alphaNumSpaces, t('common:fieldErrors.VALID001', { validation: alphaNumWithSpacesVal }))
      .min(1, t('common:fieldErrors.LEN001', { length: '(1-60)' }))
      .max(60, t('common:fieldErrors.LEN001', { length: '(1-60)' })),
    [HCFA_FIELDS.otherInsuredLastName]: commonNameValidation(t, 60).when(HCFA_FIELDS.otherInsuredId, {
      is: 'YES',
      then: (schema) => schema.required(t('common:fieldErrors.MUST001'))
    }),
    [HCFA_FIELDS.otherInsuredFirstName]: commonNameValidation(t, 35).when(HCFA_FIELDS.otherInsuredId, {
      is: 'YES',
      then: (schema) => schema.required(t('common:fieldErrors.MUST001'))
    }),
    [HCFA_FIELDS.otherInsuredMiddleInitial]: yup
      .string()
      .nullable()
      .transform((v, o) => (o === '' ? null : v))
      .matches(alpha, t('common:fieldErrors.VALID001', { validation: alphaVal }))
      .min(1, t('common:fieldErrors.LEN001', { length: '(1-25)' }))
      .max(25, t('common:fieldErrors.LEN001', { length: '(1-25)' })),
    [HCFA_FIELDS.otherInsuredGen]: yup.string().nullable(),
    [HCFA_FIELDS.reservedForNuccUse2]: yup
      .string()
      .nullable()
      .transform((v, o) => (o === '' ? null : v))
      .matches(alphaNumericWithSpecChar, t('common:fieldErrors.VALID001', { validation: alphaNumericWithSpecCharVal }))
      .min(1, t('common:fieldErrors.LEN001', { length: '(1-10)' }))
      .max(10, t('common:fieldErrors.LEN001', { length: '(1-10)' })),
    [HCFA_FIELDS.reservedForNuccUse3]: yup
      .string()
      .nullable()
      .transform((v, o) => (o === '' ? null : v))
      .matches(alphaNumericWithSpecChar, t('common:fieldErrors.VALID001', { validation: alphaNumericWithSpecCharVal }))
      .min(1, t('common:fieldErrors.LEN001', { length: '(1-30)' }))
      .max(30, t('common:fieldErrors.LEN001', { length: '(1-30)' })),
    [HCFA_FIELDS.otherInsuredPlanName]: yup
      .string()
      .nullable()
      .transform((v, o) => (o === '' ? null : v))
      .when(HCFA_FIELDS.otherInsuredId, {
        is: 'YES',
        then: (schema) => schema.required(t('common:fieldErrors.MUST001'))
      })
      .matches(alphaNumericWithSpecChar, t('common:fieldErrors.VALID001', { validation: alphaNumericWithSpecCharVal }))
      .min(1, t('common:fieldErrors.LEN001', { length: '(1-60)' }))
      .max(60, t('common:fieldErrors.LEN001', { length: '(1-60)' })),
    [HCFA_FIELDS.employmentStatus]: yup.string().nullable(),

    [HCFA_FIELDS.referringProviderLastName]: yup
      .string()
      .nullable()
      .transform((v, o) => (o === '' ? null : v))
      .matches(alphaWithSpecChar, t('common:fieldErrors.VALID001', { validation: alphaWithSpecCharVal }))
      .min(1, t('common:fieldErrors.LEN001', { length: '(1-35)' }))
      .max(35, t('common:fieldErrors.LEN001', { length: '(1-35)' }))
      .test(
        'custom-referringProviderLastName',
        t('common:fieldErrors.NPI001'),
        testRefferingProviderFirstLastNameToNPIId
      ),
    [HCFA_FIELDS.referringProviderFirstName]: yup
      .string()
      .nullable()
      .transform((v, o) => (o === '' ? null : v))
      .matches(alphaWithSpecChar, t('common:fieldErrors.VALID001', { validation: alphaWithSpecCharVal }))
      .min(1, t('common:fieldErrors.LEN001', { length: '(1-35)' }))
      .max(35, t('common:fieldErrors.LEN001', { length: '(1-35)' }))
      .test(
        'custom-referringProviderFirstName',
        t('common:fieldErrors.NPI001'),
        testRefferingProviderFirstLastNameToNPIId
      ),
    [HCFA_FIELDS.referringProviderMiddleInitial]: yup
      .string()
      .nullable()
      .transform((v, o) => (o === '' ? null : v))
      .matches(alpha, t('common:fieldErrors.VALID001', { validation: alphaVal }))
      .min(1, t('common:fieldErrors.LEN001', { length: '(1-25)' }))
      .max(25, t('common:fieldErrors.LEN001', { length: '(1-25)' })),
    [HCFA_FIELDS.referringNpi]: yup
      .string()
      .nullable()
      .transform((v, o) => (o === '' ? null : v))
      .matches(alphaNum, t('common:fieldErrors.VALID001', { validation: alphaNumVal }))
      .min(10, t('common:fieldErrors.LEN001', { length: '(10)' }))
      .max(10, t('common:fieldErrors.LEN001', { length: '(10)' }))
      .test('custom-renderNPI17', 'NPI is not valid', testNPIforLuhnAlghoritm)
      .test(
        'custom-referringNpiToName',
        'Provider NPI cannot be blank, please enter all details',
        testRefferingNPIToRefferingFirstLastName
      ),
    [HCFA_FIELDS.additionalClaim19]: yup
      .string()
      .nullable()
      .transform((v, o) => (o === '' ? null : v))
      .matches(alphaNumWithSpaces, t('common:fieldErrors.VALID001', { validation: alphaNumWithSpacesVal }))
      .min(2, t('common:fieldErrors.LEN001', { length: '(2-80)' }))
      .max(80, t('common:fieldErrors.LEN001', { length: '(2-80)' })),
    [HCFA_FIELDS.signatureOfPhysician]: yup.string().nullable(),
    // .test('custom-signatureOfPhysician', t('common:fieldErrors.MUST001'), testSignature),
    [HCFA_FIELDS.referringProviderGen]: yup.string().nullable(),

    [HCFA_FIELDS.facilityAddress]: commonAddressValidation(t).test(
      'custom-facilityAddress',
      'FacilityNPI is present but Address is blank',
      testFacilityAddressAndCityToFacilityNPI
    ),
    [HCFA_FIELDS.facilityCity]: commonCityValidation(t)
      .test(
        'custom-facilityAddress',
        'FacilityNPI is present but City is blank',
        testFacilityAddressAndCityToFacilityNPI
      )
      .test('custom-responsiblePartyCity', t('common:fieldErrors.ADD001'), (value, context) =>
        testFullAddressDependency(value, context, HCFA_FIELDS.facilityAddress)
      ),
    [HCFA_FIELDS.facilityState]: yup
      .string()
      .nullable()
      .transform((v, o) => (o === '' ? null : v))
      .test('custom-responsiblePartyCity', t('common:fieldErrors.ADD001'), (value, context) =>
        testFullAddressDependency(value, context, HCFA_FIELDS.facilityAddress)
      )
      .test('custom-facilityState', 'State is required if name or address is present', testFacilityStateToName)
      .matches(statePattern, 'State field should be 2 characters'),
    [HCFA_FIELDS.facilityZip]: commonZipcodeValidation(t).test(
      'custom-responsiblePartyCity',
      t('common:fieldErrors.ADD001'),
      (value, context) => testFullAddressDependency(value, context, HCFA_FIELDS.facilityAddress)
    ),
    [HCFA_FIELDS.facilityNpi]: yup
      .string()
      .nullable()
      .transform((v, o) => (o === '' ? null : v))
      .matches(numeric, t('common:fieldErrors.VALID001', { validation: numericVal }))
      .min(10, t('common:fieldErrors.LEN001', { length: '(10)' }))
      .max(10, t('common:fieldErrors.LEN001', { length: '(10)' }))
      .test('custom-renderNPI32', 'NPI is not valid', testNPIforLuhnAlghoritm),
    [HCFA_FIELDS.facilityId]: yup
      .string()
      .nullable()
      .transform((v, o) => (o === '' ? null : v))
      .matches(alphaNum, t('common:fieldErrors.VALID001', { validation: alphaNumVal }))
      .min(1, t('common:fieldErrors.LEN001', { length: '(1-28)' }))
      .max(28, t('common:fieldErrors.LEN001', { length: '(1-28)' })),
    [HCFA_FIELDS.facilityIdQuality]: yup
      .string()
      .nullable()
      .test(
        'custom-facilityIdQuality',
        'Qualifier alone is present but NPI or ID is not.',
        testFacilityIdQualityToNPIandId
      ),
    [HCFA_FIELDS.billingIdQuality]: yup
      .string()
      .nullable()
      .test(
        'custom-billingIdQuality',
        'Qualifier alone is present but NPI or ID is not.',
        testBillingIdQualityToNPIandId
      ),
    [HCFA_FIELDS.billingAddress]: commonAddressValidation(t)
      .test('custom-billingAddressPOBox', t('common:fieldErrors.MUST001'), testPhysicalAddressRequired)
      .test('custom-billingAddress', t('common:fieldErrors.MUST001'), testAddressToNPI32)
      .test('custom-billingAddressPOBox', 'Physical Address Required for Billing Provider', testPhysicalAddress),
    [HCFA_FIELDS.billingCity]: commonCityValidation(t)
      .test('custom-responsiblePartyCity', t('common:fieldErrors.ADD001'), (value, context) =>
        testFullAddressDependency(value, context, HCFA_FIELDS.billingAddress)
      )
      .required(t('common:fieldErrors.MUST001')),
    [HCFA_FIELDS.billingState]: yup
      .string()
      .nullable()
      .transform((v, o) => (o === '' ? null : v))
      .matches(statePattern, 'State field should be 2 characters')
      .test('custom-responsiblePartyCity', t('common:fieldErrors.ADD001'), (value, context) =>
        testFullAddressDependency(value, context, HCFA_FIELDS.billingAddress)
      )
      .required(t('common:fieldErrors.MUST001')),
    [HCFA_FIELDS.billingZip]: commonZipcodeValidation(t)
      .test('custom-responsiblePartyCity', t('common:fieldErrors.ADD001'), (value, context) =>
        testFullAddressDependency(value, context, HCFA_FIELDS.billingAddress)
      )
      .required(t('common:fieldErrors.MUST001')),
    [HCFA_FIELDS.billingPhone]: yup
      .string()
      .nullable()
      .transform((v, o) => (o === '' ? null : v))
      .matches(numeric, t('common:fieldErrors.VALID001', { validation: numericVal }))
      .min(10, t('common:fieldErrors.LEN001', { length: '(10)' }))
      .max(10, t('common:fieldErrors.LEN001', { length: '(10)' })),
    [HCFA_FIELDS.rsvdForNuccUse]: yup
      .string()
      .nullable()
      .transform((v, o) => (o === '' ? null : v))
      .min(2, t('common:fieldErrors.LEN001', { length: '(2-15)' }))
      .max(15, t('common:fieldErrors.LEN001', { length: '(2-15)' })),
    // .transform((v, o) => (o === '' ? null : v))
    // .matches(numeric, t('common:fieldErrors.VALID001', { validation: numericVal }))
    // .min(10, t('common:fieldErrors.LEN001', { length: '(10)' }))
    // .max(10, t('common:fieldErrors.LEN001', { length: '(10)' })),

    [HCFA_FIELDS.billingNpi]: yup
      .string()
      .nullable()
      .transform((v, o) => (o === '' ? null : v))
      // .required(t('common:fieldErrors.MUST001'))
      .min(10, t('common:fieldErrors.LEN001', { length: '(10)' }))
      .max(10, t('common:fieldErrors.LEN001', { length: '(10)' }))
      .matches(numeric, t('common:fieldErrors.VALID001', { validation: numericVal }))
      .test('custom-renderNPI33', 'NPI is not valid', testNPIforLuhnAlghoritm)
      .test('custom-billingNpiId', 'Billing NPI and ID are blank/illegible', testIsBillingIdAndIdBlank),
    [HCFA_FIELDS.billingId]: yup
      .string()
      .nullable()
      .transform((v, o) => (o === '' ? null : v))
      .min(1, t('common:fieldErrors.LEN001', { length: '(1-28)' }))
      .max(28, t('common:fieldErrors.LEN001', { length: '(1-28)' }))
      .matches(alphaNum, t('common:fieldErrors.VALID001', { validation: alphaNumVal }))
      .test('custom-billingId', 'Billing NPI and ID are blank/illegible', testIsBillingIdAndIdBlank),

    [HCFA_FIELDS.insurancePlanProgramName]: yup
      .string()
      .nullable()
      .transform((v, o) => (o === '' ? null : v))
      .matches(alphaNumericWithSpecChar, t('common:fieldErrors.VALID001', { validation: alphaNumericWithSpecCharVal }))
      .min(1, t('common:fieldErrors.LEN001', { length: '(1-60)' }))
      .max(60, t('common:fieldErrors.LEN001', { length: '(1-60)' })),
    [HCFA_FIELDS.insuredPolicyGroup]: yup
      .string()
      .nullable()
      .transform((v, o) => (o === '' ? null : v))
      .matches(alphaNumSpaces, t('common:fieldErrors.VALID001', { validation: alphaNumWithSpacesVal }))
      .min(1, t('common:fieldErrors.LEN001', { length: '(1-50)' }))
      .max(50, t('common:fieldErrors.LEN001', { length: '(1-50)' })),
    // .when([HCFA_FIELDS.insuredFirstName, HCFA_FIELDS.insuredLastName], {
    //   is: true,
    //   then: (schema) => schema.required(t('common:fieldErrors.MUST001'))
    // }),
    [HCFA_FIELDS.otherClaimId]: yup
      .string()
      .nullable()
      .transform((v, o) => (o === '' ? null : v))
      .matches(alphaNum, t('common:fieldErrors.VALID001', { validation: alphaNumVal }))
      .min(1, t('common:fieldErrors.LEN001', { length: '(1-35)' }))
      .max(35, t('common:fieldErrors.LEN001', { length: '(1-35)' })),
    [HCFA_FIELDS.referringProviderId]: yup
      .string()
      .nullable()
      .transform((v, o) => (o === '' ? null : v))
      .matches(alphaNum, t('common:fieldErrors.VALID001', { validation: alphaNumVal }))
      .min(1, t('common:fieldErrors.LEN001', { length: '(1-50)' }))
      .max(50, t('common:fieldErrors.LEN001', { length: '(1-50)' })),

    [HCFA_FIELDS.dxCodeA]: yup
      .string()
      .nullable()
      .required(t('common:fieldErrors.MUST001'))
      .matches(alphaNumericWithDot, t('common:fieldErrors.VALID001', { validation: alphaNumWithDotVal }))
      .min(3, t('common:fieldErrors.LEN001', { length: '(3-7)' }))
      .max(7, t('common:fieldErrors.ICD001')),
    // .when(HCFA_FIELDS.icdInd, {
    //   is: '9',
    //   then: commonDiagnosisCodeValidation09(t).required(t('common:fieldErrors.MUST001')),
    //   otherwise: commonDiagnosisCodeValidation(t).required(t('common:fieldErrors.MUST001'))
    // }),
    // .test('dxCodeA-lookup', diagnoseCodeLookupMsg, diagnosisCodeLookup),
    [HCFA_FIELDS.dxCodeB]: yup
      .string()
      .nullable()
      .transform((v, o) => (o === '' ? null : v))
      .matches(alphaNumericWithDot, t('common:fieldErrors.VALID001', { validation: alphaNumWithDotVal }))
      .min(3, t('common:fieldErrors.LEN001', { length: '(3-7)' }))
      .max(7, t('common:fieldErrors.ICD001')),
    // .when(HCFA_FIELDS.icdInd, {
    //   is: '9',
    //   then: commonDiagnosisCodeValidation09(t),
    //   otherwise: commonDiagnosisCodeValidation(t)
    // }),
    // .test('dxCodeB-lookup', diagnoseCodeLookupMsg, diagnosisCodeLookup),
    [HCFA_FIELDS.dxCodeC]: yup
      .string()
      .nullable()
      .transform((v, o) => (o === '' ? null : v))
      .matches(alphaNumericWithDot, t('common:fieldErrors.VALID001', { validation: alphaNumWithDotVal }))
      .min(3, t('common:fieldErrors.LEN001', { length: '(3-7)' }))
      .max(7, t('common:fieldErrors.ICD001')),
    // .when(HCFA_FIELDS.icdInd, {
    //   is: '9',
    //   then: commonDiagnosisCodeValidation09(t),
    //   otherwise: commonDiagnosisCodeValidation(t)
    // }),
    // .test('dxCodeC-lookup', diagnoseCodeLookupMsg, diagnosisCodeLookup),
    [HCFA_FIELDS.dxCodeD]: yup
      .string()
      .nullable()
      .transform((v, o) => (o === '' ? null : v))
      .matches(alphaNumericWithDot, t('common:fieldErrors.VALID001', { validation: alphaNumWithDotVal }))
      .min(3, t('common:fieldErrors.LEN001', { length: '(3-7)' }))
      .max(7, t('common:fieldErrors.ICD001')),
    // .when(HCFA_FIELDS.icdInd, {
    //   is: '9',
    //   then: commonDiagnosisCodeValidation09(t),
    //   otherwise: commonDiagnosisCodeValidation(t)
    // }),
    // .test('dxCodeD-lookup', diagnoseCodeLookupMsg, diagnosisCodeLookup),
    [HCFA_FIELDS.dxCodeE]: yup
      .string()
      .nullable()
      .transform((v, o) => (o === '' ? null : v))
      .matches(alphaNumericWithDot, t('common:fieldErrors.VALID001', { validation: alphaNumWithDotVal }))
      .min(3, t('common:fieldErrors.LEN001', { length: '(3-7)' }))
      .max(7, t('common:fieldErrors.ICD001')),
    // .when(HCFA_FIELDS.icdInd, {
    //   is: '9',
    //   then: commonDiagnosisCodeValidation09(t),
    //   otherwise: commonDiagnosisCodeValidation(t)
    // }),
    // .test('dxCodeE-lookup', diagnoseCodeLookupMsg, diagnosisCodeLookup),
    [HCFA_FIELDS.dxCodeF]: yup
      .string()
      .nullable()
      .transform((v, o) => (o === '' ? null : v))
      .matches(alphaNumericWithDot, t('common:fieldErrors.VALID001', { validation: alphaNumWithDotVal }))
      .min(3, t('common:fieldErrors.LEN001', { length: '(3-7)' }))
      .max(7, t('common:fieldErrors.ICD001')),
    // .when(HCFA_FIELDS.icdInd, {
    //   is: '9',
    //   then: commonDiagnosisCodeValidation09(t),
    //   otherwise: commonDiagnosisCodeValidation(t)
    // }),
    // .test('dxCodeF-lookup', diagnoseCodeLookupMsg, diagnosisCodeLookup),
    [HCFA_FIELDS.dxCodeG]: yup
      .string()
      .nullable()
      .transform((v, o) => (o === '' ? null : v))
      .matches(alphaNumericWithDot, t('common:fieldErrors.VALID001', { validation: alphaNumWithDotVal }))
      .min(3, t('common:fieldErrors.LEN001', { length: '(3-7)' }))
      .max(7, t('common:fieldErrors.ICD001')),
    // .when(HCFA_FIELDS.icdInd, {
    //   is: '9',
    //   then: commonDiagnosisCodeValidation09(t),
    //   otherwise: commonDiagnosisCodeValidation(t)
    // }),
    // .test('dxCodeG-lookup', diagnoseCodeLookupMsg, diagnosisCodeLookup),
    [HCFA_FIELDS.dxCodeH]: yup
      .string()
      .nullable()
      .transform((v, o) => (o === '' ? null : v))
      .matches(alphaNumericWithDot, t('common:fieldErrors.VALID001', { validation: alphaNumWithDotVal }))
      .min(3, t('common:fieldErrors.LEN001', { length: '(3-7)' }))
      .max(7, t('common:fieldErrors.ICD001')),
    // .when(HCFA_FIELDS.icdInd, {
    //   is: '9',
    //   then: commonDiagnosisCodeValidation09(t),
    //   otherwise: commonDiagnosisCodeValidation(t)
    // }),
    // .test('dxCodeH-lookup', diagnoseCodeLookupMsg, diagnosisCodeLookup),
    [HCFA_FIELDS.dxCodeI]: yup
      .string()
      .nullable()
      .transform((v, o) => (o === '' ? null : v))
      .matches(alphaNumericWithDot, t('common:fieldErrors.VALID001', { validation: alphaNumWithDotVal }))
      .min(3, t('common:fieldErrors.LEN001', { length: '(3-7)' }))
      .max(7, t('common:fieldErrors.ICD001')),
    // .when(HCFA_FIELDS.icdInd, {
    //   is: '9',
    //   then: commonDiagnosisCodeValidation09(t),
    //   otherwise: commonDiagnosisCodeValidation(t)
    // }),
    // .test('dxCodeI-lookup', diagnoseCodeLookupMsg, diagnosisCodeLookup),
    [HCFA_FIELDS.dxCodeJ]: yup
      .string()
      .nullable()
      .transform((v, o) => (o === '' ? null : v))
      .matches(alphaNumericWithDot, t('common:fieldErrors.VALID001', { validation: alphaNumWithDotVal }))
      .min(3, t('common:fieldErrors.LEN001', { length: '(3-7)' }))
      .max(7, t('common:fieldErrors.ICD001')),
    // .when(HCFA_FIELDS.icdInd, {
    //   is: '9',
    //   then: commonDiagnosisCodeValidation09(t),
    //   otherwise: commonDiagnosisCodeValidation(t)
    // }),
    // .test('dxCodeJ-lookup', diagnoseCodeLookupMsg, diagnosisCodeLookup),
    [HCFA_FIELDS.dxCodeK]: yup
      .string()
      .nullable()
      .transform((v, o) => (o === '' ? null : v))
      .matches(alphaNumericWithDot, t('common:fieldErrors.VALID001', { validation: alphaNumWithDotVal }))
      .min(3, t('common:fieldErrors.LEN001', { length: '(3-7)' }))
      .max(7, t('common:fieldErrors.ICD001')),
    // .when(HCFA_FIELDS.icdInd, {
    //   is: '9',
    //   then: commonDiagnosisCodeValidation09(t),
    //   otherwise: commonDiagnosisCodeValidation(t)
    // }),
    // .test('dxCodeL-lookup', diagnoseCodeLookupMsg, diagnosisCodeLookup),
    [HCFA_FIELDS.dxCodeL]: yup
      .string()
      .nullable()
      .transform((v, o) => (o === '' ? null : v))
      .matches(alphaNumericWithDot, t('common:fieldErrors.VALID001', { validation: alphaNumWithDotVal }))
      .min(3, t('common:fieldErrors.LEN001', { length: '(3-7)' }))
      .max(7, t('common:fieldErrors.ICD001')),
    // .when(HCFA_FIELDS.icdInd, {
    //   is: '9',
    //   then: commonDiagnosisCodeValidation09(t),
    //   otherwise: commonDiagnosisCodeValidation(t)
    // }),
    // .test('dxCodeL-lookup', diagnoseCodeLookupMsg, diagnosisCodeLookup),
    [HCFA_FIELDS.originalRefNumber]: yup
      .string()
      .nullable()
      .transform((v, o) => (o === '' ? null : v))
      .length(12, t('common:fieldErrors.LEN001', { length: '(12)' }))
      .matches(
        alphaNumLengthTwelveEndedNumber,
        'Value needs to be alphanumeric, 12 characters and should end with 00/01/02/03/04/05/06/07/08/09'
      )
      // .min(12, t('common:fieldErrors.LEN001', { length: '(12)' }))
      // .max(12, t('common:fieldErrors.LEN001', { length: '(12)' }))
      .test('custom-originalRefNumber', t('common:fieldErrors.RES001'), testOriginalRefNumToRessubmission),
    [HCFA_FIELDS.authorizationNumber]: yup
      .string()
      .nullable()
      .transform((v, o) => (o === '' ? null : v))
      .matches(alphaNum, t('common:fieldErrors.VALID001', { validation: alphaNumVal }))
      .min(1, t('common:fieldErrors.LEN001', { length: '(1-50)' }))
      .max(50, t('common:fieldErrors.LEN001', { length: '(1-50)' }))
      .test('custom-authNumber', '', testAuthorizationNumber),

    [HCFA_FIELDS.federalTaxId]: yup
      .string()
      .nullable()
      .transform((v, o) => (o === '' ? null : v))
      .required(t('common:fieldErrors.MUST001'))
      .min(9, t('common:fieldErrors.LEN001', { length: '(9)' }))
      .max(9, t('common:fieldErrors.LEN001', { length: '(9)' })),

    [HCFA_FIELDS.totalCharges]: yup
      .string()
      .nullable()
      .transform((v, o) => (o === '' ? null : v))
      .min(2, t('common:fieldErrors.LEN001', { length: '(2-15)' }))
      .max(15, t('common:fieldErrors.LEN001', { length: '(2-15)' }))
      .test(
        'custom-totalCharge',
        'Total Charge not matching to sum of line charges, please verify all charges',
        testTotalChargeToTableFields
      ),
    //.test('custom-totalChargeMax', 'Max charge is 15000 $', testIndividualCharge),
    [HCFA_FIELDS.amountPaid]: yup
      .string()
      .nullable()
      .transform((v, o) => (o === '' ? null : v))
      .min(2, t('common:fieldErrors.LEN001', { length: '(2-15)' }))
      .max(15, t('common:fieldErrors.LEN001', { length: '(2-15)' }))
      .test('custom-amountPaidIsCurrency', 'Amount Paid is blank or illegible', testIsValidCurrency),
    [HCFA_FIELDS.patientAccountNumber]: yup
      .string()
      .nullable()
      .transform((v, o) => (o === '' ? null : v))
      .matches(alphaNumericWithSpecChar, t('common:fieldErrors.VALID001', { validation: alphaNumericWithSpecCharVal }))
      .min(1, t('common:fieldErrors.LEN001', { length: '(1-38)' }))
      .max(38, t('common:fieldErrors.LEN001', { length: '(1-38)' })),
    [HCFA_FIELDS.facilityName]: yup
      .string()
      .nullable()
      .transform((v, o) => (o === '' ? null : v))
      .min(1, t('common:fieldErrors.LEN001', { length: '(1-60)' }))
      .max(60, t('common:fieldErrors.LEN001', { length: '(1-60)' }))
      .matches(alphaNumericWithSpecChar, t('common:fieldErrors.VALID001', { validation: alphaNumericWithSpecCharVal })),
    [HCFA_FIELDS.billingName]: yup
      .string()
      .nullable()
      .transform((v, o) => (o === '' ? null : v))
      .min(1, t('common:fieldErrors.LEN001', { length: '(1-60)' }))
      .max(60, t('common:fieldErrors.LEN001', { length: '(1-60)' }))
      .matches(alphaNumericWithSpecChar, t('common:fieldErrors.VALID001', { validation: alphaNumericWithSpecCharVal }))
      .test('custom-billingName', t('common:fieldErrors.NPI001'), testProviderNameToSupplier)
      .test('custom-billingNameNpi', t('common:fieldErrors.NPI001'), testNameToNPI32Supplier),
    // [HCFA_FIELDS.pat_addr2]: yup
    //   .string()
    //   .nullable()
    //   .transform((v, o) => (o === '' ? null : v))
    //   .matches(alphaNumericWithSpecChar, t('common:fieldErrors.VALID001', { validation: alphaNumericWithSpecCharVal }))
    //   .min(1, t('common:fieldErrors.LEN001', { length: '(1-55)' }))
    //   .max(55, t('common:fieldErrors.LEN001', { length: '(1-55)' })),
    // [HCFA_FIELDS.ins_addr2]: yup
    //   .string()
    //   .nullable()
    //   .transform((v, o) => (o === '' ? null : v))
    //   .matches(alphaNumericWithSpecChar, t('common:fieldErrors.VALID001', { validation: alphaNumericWithSpecCharVal }))
    //   .min(1, t('common:fieldErrors.LEN001', { length: '(1-55)' }))
    //   .max(55, t('common:fieldErrors.LEN001', { length: '(1-55)' })),
    [HCFA_FIELDS.useAddressTwo]: yup
      .string()
      .nullable()
      .transform((v, o) => (o === '' ? null : v))
      .max(54, t('common:fieldErrors.LEN001'))
      .matches(alphaNumericWithSpecChar, t('common:fieldErrors.VALID001', { validation: alphaNumericWithSpecCharVal })),
    [HCFA_FIELDS.box32Address2]: yup
      .string()
      .nullable()
      .transform((v, o) => (o === '' ? null : v))
      .max(55, t('common:fieldErrors.LEN001', { length: '(1-55)' }))
      .matches(alphaNumericWithSpecChar, t('common:fieldErrors.VALID001', { validation: alphaNumericWithSpecCharVal })),
    [HCFA_FIELDS.box32FromFacilityAddress2]: yup
      .string()
      .nullable()
      .transform((v, o) => (o === '' ? null : v))
      .max(55, t('common:fieldErrors.LEN001', { length: '(1-55)' }))
      .matches(alphaNumericWithSpecChar, t('common:fieldErrors.VALID001', { validation: alphaNumericWithSpecCharVal })),
    [HCFA_FIELDS.box32ToFacilityAddress2]: yup
      .string()
      .nullable()
      .transform((v, o) => (o === '' ? null : v))
      .max(55, t('common:fieldErrors.LEN001', { length: '(1-55)' }))
      .matches(alphaNumericWithSpecChar, t('common:fieldErrors.VALID001', { validation: alphaNumericWithSpecCharVal })),
    [HCFA_FIELDS.icdInd]: yup.string().required(t('common:fieldErrors.MUST001')).nullable(),
    [HCFA_FIELDS.accidentState]: yup
      .string()
      .nullable()
      .transform((v, o) => (o === '' ? null : v))
      .matches(statePattern, 'State field should be 2 characters')
      .test(
        'custom-accidentState',
        'Field cannot be left blank when auto accident is selected',
        testAccidentStateWhenAutoAccident
      ),
    [HCFA_FIELDS.insuredDateOfBirth]: yup
      .string()
      .nullable()
      .test('custom-insuredDateOfBirth', t('common:fieldErrors.MUST001'), testPatientInsuredRegardingRelation)
      .test('custom-insuredDateOfBirthfuture', t('common:fieldErrors.DATE001'), testFutureDate)
      .test('custom-validDate', dateInvalidMsg, testIsDateValid),
    [HCFA_FIELDS.currentIllness]: yup
      .string()
      .nullable()
      .test('custom-currentIllnessfuture', t('common:fieldErrors.DATE001'), testFutureDate)
      .test(
        'custom-currentIllnessDosFrom',
        'Date of Current Illness cannot be after Date of Service',
        testCurrentIllnessBeforeDOSFrom
      )
      .test('custom-validDate', dateInvalidMsg, testIsDateValid),
    [HCFA_FIELDS.quality]: yup
      .string()
      .nullable()
      .test('custom-quality', t('common:fieldErrors.MUST001'), testQualifierToCurrentDate),
    [HCFA_FIELDS.otherDate]: yup
      .string()
      .nullable()
      .test('custom-custom-otherDatefuture', t('common:fieldErrors.DATE001'), testFutureDate)
      .test('custom-otherDate', t('common:fieldErrors.MUST001'), testOtherDateToQualifier)
      .test('custom-currentIllness', t('common:fieldErrors.MUST001'), testCurrentDate14ToAccidentAndQualifier)
      .test('custom-otherDateTo10', 'Accident date with no accident indicator', testOtherDateToBOx10)
      .test('custom-otherDateToDosFrom', 'Other date cannot be after Date of Service', testOtherDateBeforeDOSFrom)
      .test('custom-validDate', dateInvalidMsg, testIsDateValid),
    [HCFA_FIELDS.otherQuality]: yup
      .string()
      .nullable()
      .test('custom-otherQuality', t('common:fieldErrors.MUST001'), testOtherQualifierToOtherDate)
      .test('custom-otherQualityTo10', 'Qualifier 439 is required', testOtherQualityToBox10),
    [HCFA_FIELDS.labCharges]: yup
      .string()
      .nullable()
      .transform((v, o) => (o === '' ? null : v))
      .min(2, t('common:fieldErrors.LEN001', { length: '(2-10)' }))
      .max(10, t('common:fieldErrors.LEN001', { length: '(2-10)' }))
      .test('custom-labCharges', t('common:fieldErrors.MUST001'), testLabCharges),
    '3_pat_dob': yup
      .string()
      .nullable()
      .required(t('common:fieldErrors.MUST001'))
      // .test('custom-3_pat_dobrequired', t('common:fieldErrors.MUST001'), testDOBRequired)
      .test('custom-3_pat_dobfuture', t('common:fieldErrors.DATE001'), testFutureDate)
      .test('custom-validDate', dateInvalidMsg, testIsDateValid),
    [HCFA_FIELDS.hospitalizationFromDate]: yup
      .string()
      .nullable()
      .test('custom-hospitalizationFromDatefuture', t('common:fieldErrors.DATE001'), testFutureDate)
      .test('custom-validDate', dateInvalidMsg, testIsDateValid),
    [HCFA_FIELDS.hospitalizationToDate]: yup
      .string()
      .nullable()
      .test('custom-hospitalizationToDatefuture', t('common:fieldErrors.DATE001'), testFutureDate)
      .test(
        'custom-hospitalizationToDate',
        'Value cannot be before from date, please re-enter correct value',
        testHospitalizationToAfterFrom
      )
      .test('custom-validDate', dateInvalidMsg, testIsDateValid),
    [HCFA_FIELDS.unableToWorkFromDate]: yup
      .string()
      .nullable()
      .test('custom-unableToWorkFromDatefuture', t('common:fieldErrors.DATE001'), testFutureDate)
      .test('custom-validDate', dateInvalidMsg, testIsDateValid),
    [HCFA_FIELDS.unableToWorkToDate]: yup
      .string()
      .nullable()
      .test('custom-unableToWorkToDatefuture', t('common:fieldErrors.DATE001'), testFutureDate)
      .test(
        'custom-unableToWorkToDateAfterFrom',
        t('Value cannot be before from date, please re-enter correct value'),
        testUnableToWorkToAfterFrom
      )
      .test('custom-validDate', dateInvalidMsg, testIsDateValid),
    [HCFA_FIELDS.signatureOfPhysicianDate]: yup
      .string()
      .nullable()
      .test('custom-signatureOfPhysicianDatefuture', t('common:fieldErrors.DATE001'), testFutureDate)
      .test('custom-validDate', dateInvalidMsg, testIsDateValid),
    [HCFA_FIELDS.HCFA33Address2]: commonAddressValidation(t),
    [HCFA_FIELDS.hcfaFromAddress]: commonAddressValidation(t),
    [HCFA_FIELDS.hcfaFromCity]: commonCityValidation(t).test(
      'custom-responsiblePartyCity',
      t('common:fieldErrors.ADD001'),
      (value, context) => testFullAddressDependency(value, context, HCFA_FIELDS.hcfaFromAddress)
    ),
    [HCFA_FIELDS.hcfaFromState]: yup
      .string()
      .nullable()
      .transform((v, o) => (o === '' ? null : v))
      .test('custom-responsiblePartyCity', t('common:fieldErrors.ADD001'), (value, context) =>
        testFullAddressDependency(value, context, HCFA_FIELDS.hcfaFromAddress)
      )
      .matches(statePattern, 'State field should be 2 characters')
      .test(
        'custom-hcfaFromState',
        'Ambulance State is blank - cannot be blank if Amb From Name is present',
        testAmbulanceFromStateLocation
      ),
    [HCFA_FIELDS.hcfaFromZip]: commonZipcodeValidation(t).test(
      'custom-responsiblePartyCity',
      t('common:fieldErrors.ADD001'),
      (value, context) => testFullAddressDependency(value, context, HCFA_FIELDS.hcfaFromAddress)
    ),

    [HCFA_FIELDS.hcfaToAddress]: commonAddressValidation(t),
    [HCFA_FIELDS.hcfaToCity]: commonCityValidation(t).test(
      'custom-responsiblePartyCity',
      t('common:fieldErrors.ADD001'),
      (value, context) => testFullAddressDependency(value, context, HCFA_FIELDS.hcfaToAddress)
    ),
    [HCFA_FIELDS.hcfaToState]: yup
      .string()
      .nullable()
      .transform((v, o) => (o === '' ? null : v))
      .test('custom-responsiblePartyCity', t('common:fieldErrors.ADD001'), (value, context) =>
        testFullAddressDependency(value, context, HCFA_FIELDS.hcfaToAddress)
      )
      .matches(statePattern, 'State field should be 2 characters')
      .test(
        'custom-hcfaToState',
        'Ambulance State is blank - cannot be blank if Amb To Name is present',
        testAmbulanceToStateLocation
      ),
    [HCFA_FIELDS.hcfaToZip]: commonZipcodeValidation(t)
      .test('custom-responsiblePartyCity', t('common:fieldErrors.ADD001'), (value, context) =>
        testFullAddressDependency(value, context, HCFA_FIELDS.hcfaToAddress)
      )
      .test('custom-hcfaToZip', t('common:fieldErrors.AMB001'), testAmb001)
      .test('custom-hcfaToZip', t('common:fieldErrors.AMB004'), testAmb004),
    [HCFA_FIELDS.HCFA33Credentials]: yup.string().nullable(),
    [HCFA_FIELDS.hcfaAmbToLocation]: yup
      .string()
      .nullable()
      .transform((v, o) => (o === '' ? null : v))
      .matches(alphaNumericWithSpecChar, t('common:fieldErrors.VALID001', { validation: alphaNumericWithSpecCharVal }))
      .min(1, t('common:fieldErrors.LEN001', { length: '(1-60)' }))
      .max(60, t('common:fieldErrors.LEN001', { length: '(1-60)' })),
    [HCFA_FIELDS.hcfaAmbFromLocation]: yup
      .string()
      .nullable()
      .transform((v, o) => (o === '' ? null : v))
      .matches(alphaNumericWithSpecChar, t('common:fieldErrors.VALID001', { validation: alphaNumericWithSpecCharVal }))
      .min(1, t('common:fieldErrors.LEN001', { length: '(1-60)' }))
      .max(60, t('common:fieldErrors.LEN001', { length: '(1-60)' })),
    [HCFA_FIELDS.patientSignedDate]: yup
      .string()
      .nullable()
      .test('custom-patientSignedDatefuture', t('common:fieldErrors.DATE001'), testFutureDate)
      .test('custom-validDate', dateInvalidMsg, testIsDateValid)
  });

export const getYupWarning: GetYupValidator = (t, fieldName) => {
  return {
    validator: async (_, value: StoreValue) => {
      if (knownWarnings.includes(fieldName))
        getHcfaWarningSchema(t).validateSyncAt(
          fieldName,
          { ...getFormProvidersDataFromGlobalObject(), [fieldName]: value }, // @ts-ignore
          { context: { path: _.field } }
        );
    },
    warningOnly: true
  };
};

export const getYupWarningServiceTable: GetYupValidator = (t, fieldName) => {
  return {
    validator: async (_, value: StoreValue) => {
      getHcfaWarningServiceTableShape(t).validateSyncAt(
        fieldName,
        { ...getFormProvidersDataFromGlobalObject(), [fieldName]: value }, // @ts-ignore
        { context: { path: _.field } }
      );
    },
    warningOnly: true
  };
};

type GetYupValidator = (t: TFunction, fieldName: string) => Rule;
export const getYupValidator: GetYupValidator = (t, fieldName) => {
  return {
    validator: async (_, value: StoreValue) => {
      await getHcfaSchema(t).validateAt(
        fieldName,
        { ...getFormProvidersDataFromGlobalObject(), [fieldName]: value },
        // @ts-ignore
        { context: { path: _.field } }
      );
    }
  };
};

type GetYupValidatorServiceTable = (t: TFunction, fieldName: string) => Rule;
export const getYupValidatorServiceTable: GetYupValidatorServiceTable = (t, fieldName) => {
  return {
    validator: async (_, value: StoreValue) => {
      const schema = getHcfaServiceTableShape(t);

      await schema.validateAt(
        fieldName,
        { ...getFormProvidersDataFromGlobalObject(), [fieldName]: value },
        // @ts-ignore
        { context: { path: _.field } }
      );
    }
  };
};
