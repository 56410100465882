import { useOidcAccessToken } from '@axa-fr/react-oidc';
import { EditOutlined } from '@ant-design/icons';
import { Button, Divider, Form, Input, Modal, Radio, Select, Table } from 'antd';
import React, { useEffect, useState, useCallback } from 'react';
import { useAccessTokenRoleInfo } from 'utils/hooks/useAccessTokenRoleInfo';
import { useAccessTokenProvidedInfo } from 'utils/hooks/useAccessTokenProvidedInfo';
import { useUserData } from 'utils/hooks/useUserData';
import NotAllowedPage from 'components/app/NotAllowedPage';
import { getGroups, getRoles, getUsers } from 'api/admin';
import { capitalize } from 'utils/common';
import { useCanViewProfiles } from 'utils/hooks/useCanViewPofiles';
function Profile() {
  const canViewProfiles = useCanViewProfiles();
  const { accessTokenPayload, accessToken } = useOidcAccessToken();
  const { userRoles } = useAccessTokenRoleInfo();
  const { isUserRole, isAdmin, isProviderAdmin, isProvidersAdmin } = useUserData();
  const [users, setUsers] = useState<[]>();
  const [groups, setGroups] = useState<[]>();
  const [roles, setRoles] = useState<Record<string, string>[]>();
  const {
    providerAddressOidc,
    providerNameOidc,
    providerCityOidc,
    providerZipCodeOidc,
    providerStateOidc,
    providerAddressOtherOidc,
    npiOidc,
    LobOidc
  } = useAccessTokenProvidedInfo();
  const searchFields = [
    {
      name: 'providerType',
      title: 'Provider Type',
      key: 'providerType',
      type: 'radio',
      options: ['users', 'groups']
    },
    {
      name: 'provider_name',
      title: 'Provider Name',
      key: 'provider_name'
    },
    {
      name: 'provider_id',
      title: 'ProviderID',
      key: 'provider_id'
    },

    {
      name: 'tax_id',
      title: 'Tax ID',
      key: 'tax_id'
    },
    {
      name: 'login_id',
      title: 'Login ID',
      key: 'login_id'
    }
    // ,
    // {
    //   name: 'role',
    //   title: 'Role',
    //   key: 'role',
    //   type: 'select',
    //   options: roles
    // }
  ];
  const profileFields = [
    {
      name: 'loginID',
      title: 'LoginID',
      dataIndex: ['attributes', 'login_id', 0],
      key: 'login_id',
      value: accessTokenPayload.login_id
    },
    {
      name: 'lob',
      title: 'LOB',
      dataIndex: ['attributes', 'LOB', 0],
      key: 'LOB',
      value: LobOidc
    },
    {
      name: 'providerId',
      title: 'ProviderID',
      dataIndex: ['attributes', 'provider_id', 0],
      key: 'provider_id',
      value: accessTokenPayload.provider_id
    },
    {
      name: 'userState',
      title: 'User State',
      dataIndex: ['attributes', 'user_state', 0],
      key: 'user_state',
      value: accessTokenPayload.user_state
    },
    {
      name: 'taxId',
      title: 'TaxID',
      dataIndex: ['attributes', 'tax_id', 0],
      key: 'tax_id',
      value: accessTokenPayload.tax_id
    },
    {
      name: 'npi',
      title: 'NPI',
      dataIndex: ['attributes', 'NPI', 0],
      key: 'NPI',
      value: npiOidc
    },
    {
      name: 'providerName',
      title: 'Provider Name',
      dataIndex: ['attributes', 'provider_name', 0],
      key: 'provider_name',
      value: providerNameOidc
    },
    {
      name: 'providerAdd1',
      title: 'Provider Address1',
      dataIndex: ['attributes', 'provider_address1', 0],
      key: 'provider_address1',
      value: providerAddressOidc
    },
    {
      name: 'providerAdd2',
      title: 'Provider Address2',
      dataIndex: ['attributes', 'provider_address2', 0],
      key: 'provider_address2',
      value: providerAddressOtherOidc
    },
    {
      name: 'providerCity',
      title: 'Provider City',
      dataIndex: ['attributes', 'provider_city', 0],
      key: 'provider_city',
      value: providerCityOidc
    },
    {
      name: 'providerState',
      title: 'Provider State',
      dataIndex: ['attributes', 'provider_state', 0],
      key: 'provider_state',
      value: providerStateOidc
    },
    {
      name: 'providerZipCode',
      title: 'Provider Zip Code',
      dataIndex: ['attributes', 'provider_zip_code', 0],
      key: 'provider_zip_code',
      value: providerZipCodeOidc
    }
    // ,
    // {
    //   name: 'role',
    //   title: 'Role',
    //   dataIndex: ['attributes', 'role', 0],
    //   key: 'role',
    //   value: userRoles
    // }
  ];
  const tableColumns = [
    {
      title: 'First Name',
      dataIndex: 'firstName',
      key: 'firstName'
    },
    {
      title: 'Last Name',
      dataIndex: 'lastName',
      key: 'lastName'
    },
    ...profileFields
  ];

  const parseOptions = useCallback((options: Record<string, string>[]) => {
    const _options: Record<string, string>[] = [];
    options.forEach((element: Record<string, string>) => {
      _options.push({
        value: element.name,
        label: capitalize(element.name)
      });
    });
    setRoles(_options);
  }, []);
  const fetchUsers = useCallback(
    async (data?: any) => {
      try {
        const response = await getUsers(accessToken, data);
        setUsers(response);
      } catch (error) {
        console.error(error);
      }
    },
    [accessToken]
  );
  const fetchGroups = useCallback(
    async (data?: any) => {
      try {
        const response = await getGroups(accessToken, data);
        setGroups(response);
      } catch (error) {
        console.error(error);
      }
    },
    [accessToken]
  );
  const fetchRoles = useCallback(async () => {
    try {
      const response = await getRoles(accessToken);
      parseOptions(response);
    } catch (error) {
      console.error(error);
    }
  }, [accessToken, parseOptions]);
  const onFinish = useCallback(
    (data: any) => {
      if (data.providerType === 'groups') {
        fetchGroups(data);
      } else {
        fetchUsers(data);
      }
    },
    [fetchUsers, fetchGroups]
  );

  useEffect(() => {
    if (!users && (isAdmin || isProviderAdmin || isProvidersAdmin)) {
      fetchUsers();
    }
    // if (!roles && (isAdmin || isProviderAdmin || isProvidersAdmin)) {
    //   fetchRoles();
    // }
  }, [accessToken, users, isAdmin, roles, fetchUsers, fetchRoles, isProviderAdmin, isProvidersAdmin]);
  if (isUserRole || !canViewProfiles) return <NotAllowedPage />;
  if (isAdmin || isProviderAdmin || isProvidersAdmin)
    return (
      <div className="branding-wrapper">
        <div className="box-wrapper">
          <div style={{ padding: '1rem 3rem 0em' }}>
            <Divider orientation="left">Search User</Divider>
          </div>
          <div className="box">
            <Form
              name="profile_form"
              labelCol={{ span: 8 }}
              wrapperCol={{ span: 16 }}
              style={{ maxWidth: 960 }}
              onFinish={onFinish}
              initialValues={{ providerType: 'users' }}
            >
              {searchFields.map((field) => {
                return (
                  <>
                    {!field.type && (
                      <Form.Item label={field.title} name={field.name}>
                        <Input />
                      </Form.Item>
                    )}
                    {field.type === 'radio' && field.options && (
                      <Form.Item label={field.title} name={field.name}>
                        <Radio.Group disabled>
                          {field.options.map((option) => (
                            <Radio value={option}>{capitalize(option as string)}</Radio>
                          ))}
                        </Radio.Group>
                      </Form.Item>
                    )}
                    {field.type === 'select' && (
                      <Form.Item label={field.title} name={field.name}>
                        <Select
                          dropdownStyle={{ position: 'fixed' }}
                          style={{ width: 300 }}
                          allowClear
                          options={roles}
                        />
                      </Form.Item>
                    )}
                  </>
                );
              })}
              <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
                <Button type="primary" htmlType="submit">
                  Search
                </Button>
              </Form.Item>
            </Form>
          </div>
          {/* <Button onClick={fetchData}>Search</Button> */}
          <div className="box">
            {/* <Form
              name="profile_form"
              disabled
              labelCol={{ span: 8 }}
              wrapperCol={{ span: 16 }}
              style={{ maxWidth: 960 }}
            ></Form> */}

            {users && <Table bordered dataSource={users} columns={tableColumns} />}
          </div>
        </div>
      </div>
    );
  return (
    <div className="branding-wrapper">
      <div className="box-wrapper">
        <div style={{ padding: '1rem 3rem 0em' }}>
          <Divider orientation="left">PROFILE</Divider>
          <p>Welcome to your Profile Page</p>
        </div>
        <div className="box">
          <Form name="profile_form" disabled labelCol={{ span: 8 }} wrapperCol={{ span: 16 }} style={{ maxWidth: 960 }}>
            {profileFields.map((field) => {
              return (
                <Form.Item label={field.title} name={field.name} initialValue={field.value}>
                  <Input />
                </Form.Item>
              );
            })}
          </Form>
        </div>
      </div>
    </div>
  );
}

export default Profile;
