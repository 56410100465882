import React, { createContext, useContext, ReactNode, useEffect, useState } from 'react';
import defaultColorsJson from '../../styles/defaultColors.json';

export interface Branding {
  /**JSON string */
  colorPalette: string;
  id: string;
  lob: string | null;
  logoStorageId: null | string;
  // providerId: string | null;
  state: string | null;
  title: string | null;
}

export interface BrandingContextProps {
  branding: Branding | null;
  setBranding: React.Dispatch<React.SetStateAction<Branding | null>>;
  allBrands: Branding | null;
  setAllBrands: React.Dispatch<React.SetStateAction<Branding | null>>;

  scssVariables: string;
  setScssVariables: React.Dispatch<React.SetStateAction<string>>;

  setBrandingLob: (lob: string) => void;
}

const BrandingContext = createContext<BrandingContextProps | undefined>(undefined);

interface BrandingContextProviderProps {
  children: ReactNode;
}

function getScssStringFromColorPalette(colorPalette: any) {
  return Object.entries(colorPalette)
    .map(([key, value]) => `--${key}: ${value};`)
    .join('\n');
}

export const BrandingContextProvider: React.FC<BrandingContextProviderProps> = ({ children }) => {
  const [branding, setBranding] = useState<Branding | null>(null);
  const [allBrands, setAllBrands] = useState<Branding | null>(null);
  const [scssVariables, setScssVariables] = useState<string>(getScssStringFromColorPalette(defaultColorsJson));
  // const [brandingLob, setBrandingLob] = useState<string>();

  function setBrandingLob(lob: string) {
    sessionStorage.setItem('lob', lob);
  }
  useEffect(() => {
    handleBrandColors();
  }, [branding]);

  function handleBrandColors() {
    // coloring json example
    const jsonData = branding?.colorPalette ? JSON.parse(branding?.colorPalette) : defaultColorsJson;

    // Convert JSON to SCSS
    const scssString = getScssStringFromColorPalette(jsonData);

    setScssVariables(scssString);
  }

  return (
    <BrandingContext.Provider
      value={{
        branding,
        setBranding,
        allBrands,
        setAllBrands,
        scssVariables,
        setScssVariables,
        setBrandingLob
      }}
    >
      {children}
    </BrandingContext.Provider>
  );
};

export const useBrandingContext = () => {
  const context = useContext(BrandingContext);
  if (!context) {
    throw new Error('useMyContext must be used within a MyContextProvider');
  }
  return context;
};
