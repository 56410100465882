import React from 'react';
import { getFormInstanceFromGlobalObject } from 'utils/hcfa/putFormInstanceInGlobalObject';
import {
  getFormProvidersDataFromGlobalObject,
  putFormProvidersDataInGlobalObject
} from 'utils/hcfa/putFormProvidersDataInGlobalObject';

export function nullifyRadioGroup(ev: React.MouseEvent<HTMLElement>) {
  const form = getFormInstanceFromGlobalObject();
  const target = ev.target as HTMLInputElement;
  const name = target.name;

  const currentValue = form.getFieldValue(target.name);

  if (currentValue === target.value) {
    putFormProvidersDataInGlobalObject({ ...getFormProvidersDataFromGlobalObject(), name: null });
    form.setFieldValue(name, null);
  }
}
