import * as React from 'react';

type LoadingProviderProps = { children: React.ReactNode };

type UseLoading = {
  loading: boolean;
  startLoading: (description?: string) => void;
  finishLoading: () => void;
  description?: string;
};

type State = { loading: boolean; description?: string };

type Action = { type: 'START_LOADING'; description?: string } | { type: 'FINISH_LOADING' };

const LoadingStateContext = React.createContext<State | undefined>(undefined);
const LoadingDispatchContext = React.createContext<React.Dispatch<Action> | undefined>(undefined);

const reducer = (state: State, action: Action): State => {
  switch (action.type) {
    case 'START_LOADING':
      return {
        loading: true,
        description: action.description
      };
    case 'FINISH_LOADING':
      return {
        loading: false,
        description: undefined
      };
    default:
      return state;
  }
};

function LoadingProvider({ children }: LoadingProviderProps): JSX.Element {
  const [state, dispatch] = React.useReducer(reducer, {
    loading: false,
    description: undefined
  });

  return (
    <LoadingStateContext.Provider value={state}>
      <LoadingDispatchContext.Provider value={dispatch}>{children}</LoadingDispatchContext.Provider>
    </LoadingStateContext.Provider>
  );
}

function useLoading(): UseLoading {
  const state = React.useContext(LoadingStateContext);
  const dispatch = React.useContext(LoadingDispatchContext);

  if (state === undefined || dispatch === undefined) {
    throw new Error('useLoading must be used within a LoadingProvider');
  }
  const startLoading = React.useCallback(
    (_description?: string) => dispatch({ type: 'START_LOADING', description: _description }),
    [dispatch]
  );
  const finishLoading = React.useCallback(() => dispatch({ type: 'FINISH_LOADING' }), [dispatch]);
  return { loading: state.loading, startLoading, finishLoading, description: state.description };
}

export { LoadingProvider, useLoading };
