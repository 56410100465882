import { ArrowRightOutlined, BgColorsOutlined } from '@ant-design/icons';
import NotAllowedPage from 'components/app/NotAllowedPage';
import React from 'react';
//import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useUserData } from 'utils/hooks/useUserData';

function Admin() {
  //const { t } = useTranslation();
  const navigate = useNavigate();
  const { isAdmin } = useUserData();

  function handleGoToBranding() {
    navigate(`/admin/branding`);
  }
  if (!isAdmin) return <NotAllowedPage />;
  return (
    <div className="admin-wrapper">
      <ul className="main-menu-sections">
        <li className="navigation-card">
          <div className="navigation-card__wrapper">
            <div className="navigation-card__content">
              <div className="navigation-card__icon" role="presentation">
                <BgColorsOutlined />
              </div>
              <div>
                <h3 className="navigation-card__title">Branding</h3>
                <div>
                  Customize your brand's visual identity effortlessly with our branding panel. Change logos, colors, and
                  fonts to reflect your unique style
                </div>
              </div>
            </div>
            <div className="navigation-card__actions">
              <button
                className="rapid-button rapid-button--primary-primary"
                data-size="md"
                onClick={handleGoToBranding}
              >
                <span>Go</span>
                <ArrowRightOutlined />
              </button>
            </div>
          </div>
        </li>
      </ul>
    </div>
  );
}

export default Admin;
