import * as React from 'react';
import { Select } from 'antd';
import { FormContext } from 'components/common/FormProvider';

import STATES from '../../../utils/us-states.json';
import { useContext } from 'react';
import { onSearchSelect } from 'utils/search/search';

const { Option } = Select;

type FormStateSelectProps = {
  value?: string;
  // Implicitly passed by form.item do not pass
  onChange?: (value: any) => void;

  placeholder?: string;
  disabled?: boolean;
  inputKey: string;
};

function SelectState({ value, onChange, placeholder, disabled, inputKey }: FormStateSelectProps): JSX.Element {
  const { form: formInstance } = useContext(FormContext);

  return (
    <Select
      disabled={disabled}
      showSearch={!disabled}
      filterOption={(input, option) =>
        option && (option.key.toLowerCase() || option.value).startsWith(input.toLowerCase())
      }
      placeholder={placeholder}
      //style={{ minWidth: 130 }}
      value={value}
      allowClear={!disabled}
      onChange={onChange}
      open={disabled ? false : undefined}
      onSearch={(input) => {
        onSearchSelect(
          formInstance,
          inputKey,
          input,
          STATES.map((state) => state.abbreviation),
          onChange
        );
      }}
    >
      {STATES.map((state) => (
        <Option key={state.abbreviation} value={state.abbreviation}>
          {state.abbreviation}
        </Option>
      ))}
    </Select>
  );
}

export default SelectState;
