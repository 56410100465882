import React, { useContext, useEffect, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { FORM_ACTIONS, SECTIONS } from '../config/constants';
import { serializeFormData } from '../utils/common';
import { getClaimData, getClaimDataBySteps } from '../utils/search/search';
import { useFormProvider } from '../context/form.context';
import { Page } from '../components/common/Page';
import { FormContext, useResetFormProvider } from '../components/common/FormProvider';
import { useOidcAccessToken } from '@axa-fr/react-oidc';

import { Tabs } from 'antd';
import { getClaimdataById } from 'api/claims';
import FormActions from 'components/common/FormActions';
import PaperFormUb from 'components/ub04/PaperFormUb';
// import FormAttachmentsNew from 'components/common/FormAttachmentsNew';
import { ATTACHMENTS_STEP } from 'config/search';
import PageViewerWithAttachments from 'components/common/PageViewerWithAttachments';
import AuditTrail from 'components/common/AuditTrail';

function Ub04(): JSX.Element {
  const { t } = useTranslation();

  const { id, action, category } = useParams();
  const disabled = action === FORM_ACTIONS.VIEW;
  const [formState] = useFormProvider();

  const { form, setClaimId, setData, handleSave, handleSend, setFileRepresentationsList, setAttachmentsNew } =
    useContext(FormContext);

  const hasClaim = action !== FORM_ACTIONS.NEW;

  const { accessToken } = useOidcAccessToken();

  const pageTitle = useMemo(() => {
    switch (action) {
      case FORM_ACTIONS.EDIT:
        return 'Edit UB Claim';
      case FORM_ACTIONS.COPY:
        return 'New UB Claim from existing';
      default:
        return t('common:newItem', { item: 'UB' });
    }
  }, [action, t]);

  const tabsItems = [
    {
      label: 'Form',
      key: 'form',
      children: <PaperFormUb section={SECTIONS.UB04} />
    },
    {
      label: 'Attachments',
      key: 'Attachments',
      children: <PageViewerWithAttachments form={'ub04'} readOnly={disabled} />
    }
  ];

  if (hasClaim) {
    tabsItems.push({
      label: 'Audit trail',
      key: 'AuditTrail',
      children: <AuditTrail claimId={id} />
    });
  }

  useResetFormProvider();

  useEffect(() => {
    const handleEffect = async () => {
      if (id) setClaimId(id);

      if (action === FORM_ACTIONS.EDIT || action === FORM_ACTIONS.VIEW) {
        const claimData = await getClaimdataById(
          id!,
          SECTIONS.UB04,
          accessToken,
          category === 'archive' ? true : false
        );
        if (claimData) {
          const _data = serializeFormData(getClaimData(claimData, 'ub04'), 'ub04');
          setFileRepresentationsList(claimData.fileRepresentations);
          setAttachmentsNew(claimData.attachments);
          setData(_data);

          form && form.setFieldsValue(_data);
        }
      }
      if (action === FORM_ACTIONS.COPY) {
        if (formState.filters) {
          const claimData = await getClaimdataById(
            formState.claimId!,
            SECTIONS.UB04,
            accessToken,
            category === 'archive' ? true : false
          );
          if (claimData) {
            const _data = serializeFormData(getClaimDataBySteps(SECTIONS.UB04, claimData, formState.filters), 'ub04');

            setData(_data);
            if (formState?.filters?.includes(ATTACHMENTS_STEP)) {
              setAttachmentsNew(claimData.attachments);
            }
            form && form.setFieldsValue(_data);
          }
        }
      }
    };
    handleEffect();
  }, [action, form, formState, id]);

  return (
    <Page pageHelmet={pageTitle}>
      <Page.Back url={`/${SECTIONS.UB04}`} />
      <Page.Header title={pageTitle} description={t(`ub04:formDescription`)}>
        <div>
          <div className="rapid-toggle-group-form-view-wrapper"> </div>
        </div>
      </Page.Header>

      <Page.Content>
        <Tabs className="claim-wrapper" items={tabsItems} />

        <FormActions
          onSend={() => handleSend('ub04')}
          onSave={() => handleSave('ub04')}
          action={action as FORM_ACTIONS}
        />
      </Page.Content>
    </Page>
  );
}

export default Ub04;
