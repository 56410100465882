import React, { useContext } from 'react';
import { Button, List, Select, Modal } from 'antd';
import { DeleteOutlined, FileTwoTone } from '@ant-design/icons';
import { AttachmentDTO, FileRepresentationsDTO } from 'models/DTO/AttachmentDTO';
import { FormContext } from './FormProvider';
import { downloadFile } from 'api/files';
import { useOidcAccessToken } from '@axa-fr/react-oidc';
import { useParams } from 'react-router';
import { FORM_ACTIONS, SECTIONS } from 'config/constants';
import { AttachmentOptionsEnum } from 'models/enums/AttachmentOptionsEnum';
// import Checkbox from 'antd/lib/checkbox/Checkbox';
// import { CheckboxChangeEvent } from 'antd/es/checkbox';

interface Props {
  attachment: AttachmentDTO | FileRepresentationsDTO;
  setAction:
    | React.Dispatch<React.SetStateAction<AttachmentDTO[]>>
    | React.Dispatch<React.SetStateAction<FileRepresentationsDTO[]>>;
  readOnly: boolean;
  isSimple: boolean;
  form: 'ub04' | 'hcfa' | 'dental' | SECTIONS;
  setSelectedAttachment?: React.Dispatch<React.SetStateAction<AttachmentDTO | FileRepresentationsDTO | undefined>>;
}

const { confirm } = Modal;

const FormAttachmentsListItemNew: React.FC<Props> = ({
  attachment,
  setAction,
  readOnly,
  isSimple,
  form,
  setSelectedAttachment
}) => {
  const { setDeleteFilePaths } = useContext(FormContext);
  const { accessToken } = useOidcAccessToken();

  function onChangeCategory(value: string) {
    setAction((atts: any[]) => {
      return atts?.map((att) => {
        if (att.path === attachment.path) return { ...att, attachmentType: value };
        return { ...att };
      });
    });
  }

  function onChangeCategorySimple(value: string[]) {
    setAction((atts: any[]) => {
      return atts?.map((att) => {
        if (att.path === attachment.path) return { ...att, documentTypes: value };
        return { ...att };
      });
    });
  }

  // function onChangeIncludesClaim(attachment: AttachmentDTO | FileRepresentationsDTO, change: CheckboxChangeEvent) {
  //   setAction((atts: any[]) => {
  //     return atts.map((att) => {
  //       if (att.path === attachment.path) return { ...att, containsOriginalClaim: change.target.checked };
  //       return { ...att, containsOriginalClaim: false };
  //     });
  //   });
  // }

  function onDelete() {
    confirm({
      content: 'Are you sure you want to delete file?',
      icon: <DeleteOutlined color="red" />,
      onOk: async () => {
        onDeleteOk();
      }
    });
  }
  async function onDeleteOk() {
    setDeleteFilePaths((paths) => [...paths, attachment.path]);
    setAction((atts: any[]) => atts.filter((att) => att.path !== attachment.path));
  }

  async function onViewAtt() {
    if (!isSimple) {
      setSelectedAttachment && setSelectedAttachment(attachment);
      return;
    }
    const file = await downloadFile({ path: attachment.path, token: accessToken });
    const objURL = URL.createObjectURL(new Blob([file], { type: attachment.mimeType }));
    window.open(objURL, '_blank');
  }

  const title = (
    <button style={{ background: 'none', border: 'none', cursor: 'pointer' }} onClick={onViewAtt}>
      {attachment.fileName ? attachment.fileName : 'Attachment'}
    </button>
  );

  const attachmentOptions = [
    {
      value: AttachmentOptionsEnum.EOB,
      label: 'EOB'
    },
    {
      value: AttachmentOptionsEnum.MEDICAL_RECORD,
      label: 'Medical Records'
    },
    {
      value: AttachmentOptionsEnum.OPERATIVE_NOTES,
      label: 'Operative Notes'
    },
    {
      value: AttachmentOptionsEnum.MEMBER_CONSENT,
      label: 'Member Consent'
    },
    {
      value: AttachmentOptionsEnum.ITEMIZED_BILLS,
      label: 'Itemized Bills'
    },
    {
      value: AttachmentOptionsEnum.SUPPORTING_DOCS,
      label: 'Supporting Docs'
    },
    {
      value: AttachmentOptionsEnum.EMAIL_FAX_PAGE,
      label: 'Email Fax Page'
    }
  ];

  const { action } = useParams();
  const disabled = action === FORM_ACTIONS.VIEW;

  return (
    <List.Item>
      <List.Item.Meta avatar={<FileTwoTone style={{ fontSize: 20 }} />} title={title} />
      {/* {isSimple && (
        <Checkbox
          onChange={(ev) => onChangeIncludesClaim(attachment, ev)}
          checked={Boolean(attachment.containsOriginalClaim)}
          style={{ marginRight: 5 }}
        >
          Includes {form?.toUpperCase()}
        </Checkbox>
      )} */}
      {isSimple ? (
        // <Select
        //   placeholder="Select if file contains attachments"
        //   className="mr-1"
        //   style={{ width: 400 }}
        //   size="small"
        //   // @ts-ignore
        //   value={attachment.documentTypes}
        //   disabled={readOnly}
        //   showSearch
        //   options={attachmentOptions}
        //   onChange={onChangeCategorySimple}
        //   mode="multiple"
        // />
        <></>
      ) : (
        <Select
          placeholder="Category"
          className="mr-1"
          style={{ width: 200 }}
          size="small"
          value={attachment.attachmentType}
          disabled={readOnly}
          showSearch
          options={attachmentOptions}
          onChange={onChangeCategory}
        />
      )}

      <Button size="small" danger icon={<DeleteOutlined />} type="text" onClick={onDelete} disabled={disabled} />
    </List.Item>
  );
};

export default FormAttachmentsListItemNew;
