import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { Form, FormInstance, Radio, Select } from 'antd';
import { UB04_FIELDS } from '../../../utils/ub04';
import { nullifyRadioGroup } from 'helpers/nullifyRadioGroup';
import { onSearchSelect } from 'utils/search/search';
import { FormContext } from 'components/common/FormProvider';

type Props = {
  id: string;
  hideLabel?: boolean;
};

function FormItemSex({ hideLabel }: Props) {
  const { t } = useTranslation();
  const { form } = useContext(FormContext);
  return (
    <Form.Item
      label={<label className="form_label_color form_label_botton">{hideLabel ? '' : t('common:fields.sex')}</label>}
      name={UB04_FIELDS.patientSex}
      required
      rules={[{ required: true, message: t('common:fieldErrors.MUST001') }]}
    >
      <Select
        showSearch
        allowClear
        filterOption={(input, option) => (`${option?.value}` ?? '').toLowerCase().includes(input?.toLowerCase())}
        dropdownStyle={{
          minWidth: '45px'
        }}
        onSearch={(input) => {
          onSearchSelect(form, UB04_FIELDS.patientSex, input, ['M', 'F', 'U']);
        }}
      >
        <Select.Option value="M">{t('common:fields.maleAbbr')}</Select.Option>
        <Select.Option value="F">{t('common:fields.femaleAbbr')}</Select.Option>
        <Select.Option value="U">{t('common:fields.undeclaredAbbr')}</Select.Option>
      </Select>
    </Form.Item>
  );
}

export default FormItemSex;
