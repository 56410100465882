import React, { useMemo, useState } from 'react';

import { SearchClaim } from 'models/DTO/SearchClaim';
import { ClaimStatusEnum } from 'models/enums/ClaimStatusEnum';
import { Badge } from '@datum/react-components';
import { Button, Modal, Table, Tooltip } from 'antd';
import moment from 'moment';
import { DATE_FORMAT } from 'utils/hcfaConstants';
import { MdAttachMoney, MdDateRange } from 'react-icons/md';
import { BiSolidEdit } from 'react-icons/bi';
import { VscDiffModified } from 'react-icons/vsc';
import {
  AimOutlined,
  EyeOutlined,
  IdcardOutlined,
  InfoCircleOutlined,
  LockOutlined,
  SafetyOutlined,
  UserOutlined
} from '@ant-design/icons';
import { SECTIONS } from 'config/constants';
import { dentalFields, hcfaFields, ub04Fields } from 'config/search';
import claimSearchData from 'sampleData/claimSearchData.json';
import { getOriginClaimdataByOriginId, searchClaims } from 'api/claims';
import { useOidcAccessToken } from '@axa-fr/react-oidc';
import ActionsTableRow from './ActionsTableRow';
import { AiOutlineStop } from 'react-icons/ai';

interface Props {
  record: SearchClaim;
  text: string;
  form: SECTIONS;
}

type SectionProps = {
  properties: Record<any, any>[];
  tableViews: Record<any, any>[];
  data: Record<any, any>[];
};

const ClaimInfoRow: React.FC<Props> = ({ record, text, form }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleOk = () => {
    setIsModalOpen(false);
    setData([]);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
    setData([]);
  };

  const rejections = useMemo(() => {
    if (!record.rejectReasons) return null;
    return record.rejectReasons.split(';');
  }, [record.rejectReasons]);

  const showRejections = record.status?.externalId === ClaimStatusEnum.REJECTED && Boolean(record.rejectReasons);

  const [data, setData] = React.useState<SearchClaim[]>([]);
  const { accessToken } = useOidcAccessToken();

  const showModal = async (originalId: string) => {
    setIsModalOpen(true);
    const claimData = await getOriginClaimdataByOriginId(originalId!, form, accessToken, false);
    if (claimData) {
      setData(claimData);
    }
  };

  const showResubmittedModal = async (ressubmittedId: string) => {
    setIsModalOpen(true);
    const claimData = await searchClaims({ claimId: ressubmittedId, page: 0, size: 1 }, form, false, accessToken);
    if (claimData.content) {
      setData(claimData.content);
    }
  };

  const sectionProps = React.useMemo(() => {
    let _sectionProps: SectionProps = {
      properties: [],
      tableViews: [],
      data: []
    };
    switch (form) {
      case SECTIONS.HCFA: {
        _sectionProps = {
          ..._sectionProps,
          properties: hcfaFields,
          data: claimSearchData.hfca,
          tableViews: [
            {
              title: <span className="table-title">{<InfoCircleOutlined />} Claim Info</span>,
              dataIndex: 'docId',
              key: 'docId',
              fixed: 'left',
              width: 250,
              render: (text: any, record: SearchClaim, index: any) => {
                // Display the sibling data in the 'Sibling Age' column
                return <ClaimInfoRow form={form} record={record} text={text} />;
              }
            },
            {
              title: (
                <span className="table-title">
                  <UserOutlined /> Patient Info
                </span>
              ),
              width: 225,
              dataIndex: 'patientFirstName',
              key: 'patientFirstName',
              render: (text: any, record: SearchClaim, index: any) => {
                // Display the sibling data in the 'Sibling Age' column
                return (
                  <div className="claims-info">
                    <div>
                      <p title={text + ' ' + record.patientLastName}>
                        {text} {record.patientLastName}
                      </p>
                    </div>
                    <div>
                      <p>
                        {/* <Tooltip title="Address">
                          <TiLocationOutline title="Address" />
                        </Tooltip> */}
                        {/* <span title={`ADDRESS`}>
                          <b>Address |</b> ADDRESS
                        </span> */}
                      </p>
                    </div>
                  </div>
                );
              }
            },
            {
              title: (
                <span className="table-title">
                  <SafetyOutlined /> Insured Info
                </span>
              ),
              width: 225,
              dataIndex: 'insuredId',
              key: 'insuredId',

              render: (text: any, record: SearchClaim, index: any) => {
                // Display the sibling data in the 'Sibling Age' column
                return (
                  <div className="insured-info">
                    {record.insuredId && (
                      <div>
                        <small>Insured ID: {record.insuredId && record.insuredId}</small>
                        <p title={record.insuredFirstName + ' ' + record.insuredLastName}>
                          {record.insuredFirstName} {record.insuredLastName}
                        </p>
                      </div>
                    )}

                    {record.insuredDob && (
                      <div>
                        <p>
                          <Tooltip title="Date of Birth">
                            <MdDateRange title="Date of Birth" />
                          </Tooltip>
                          <span title={`${record.insuredDob && moment(record.insuredDob).format(DATE_FORMAT[0])}`}>
                            <b>Dob |</b> {record.insuredDob && moment(record.insuredDob).format(DATE_FORMAT[0])}
                          </span>
                        </p>
                      </div>
                    )}
                  </div>
                );
              }
            },
            {
              title: 'Actions',
              dataIndex: '',
              key: 'x',
              fixed: 'right',
              width: 200,
              render: (_: any, row: SearchClaim) => <ActionsTableRow row={row} section={form} setData={setData} />
            }
          ]
        };
        break;
      }
      case SECTIONS.DENTAL: {
        _sectionProps = {
          ..._sectionProps,
          properties: dentalFields,
          data: claimSearchData.hfca,
          tableViews: [
            {
              title: <span className="table-title">{<InfoCircleOutlined />} Claim Info</span>,
              dataIndex: 'docId',
              key: 'docId',
              fixed: 'left',
              width: 250,
              render: (text: any, record: SearchClaim, index: any) => {
                // Display the sibling data in the 'Sibling Age' column
                return <ClaimInfoRow form={form} record={record} text={text} />;
              }
            },
            {
              title: (
                <span className="table-title">
                  <UserOutlined /> Patient Info
                </span>
              ),
              width: 250,
              dataIndex: 'patientFirstName',
              key: 'patientFirstName',
              render: (text: any, record: SearchClaim, index: any) => {
                // Display the sibling data in the 'Sibling Age' column
                return (
                  <div className="claims-info">
                    <div>
                      <p title={text + ' ' + record.patientLastName}>
                        {text} {record.patientLastName}
                      </p>
                    </div>
                    <div>
                      <p>
                        {/* <Tooltip title="Address">
                          <TiLocationOutline title="Address" />
                        </Tooltip> */}
                        {/* <span title={`ADDRESS`}>
                          <b>Address |</b> ADDRESS
                        </span> */}
                      </p>
                    </div>
                  </div>
                );
              }
            },
            {
              title: (
                <span className="table-title">
                  <SafetyOutlined /> Insured Info
                </span>
              ),
              width: 250,
              dataIndex: 'insuredId',
              key: 'insuredId',

              render: (text: any, record: SearchClaim, index: any) => {
                // Display the sibling data in the 'Sibling Age' column
                return (
                  <div className="insured-info">
                    {record.insuredId && (
                      <div>
                        <small>Insured ID: {record.insuredId && record.insuredId}</small>
                        <p title={record.insuredFirstName + ' ' + record.insuredLastName}>
                          {record.insuredFirstName} {record.insuredLastName}
                        </p>
                      </div>
                    )}
                    {record.insuredDob && (
                      <div>
                        <p>
                          <Tooltip title="Date of Birth">
                            <MdDateRange title="Date of Birth" />
                          </Tooltip>
                          <span title={`${record.insuredDob && moment(record.insuredDob).format(DATE_FORMAT[0])}`}>
                            <b>Dob |</b> {record.insuredDob && moment(record.insuredDob).format(DATE_FORMAT[0])}
                          </span>
                        </p>
                      </div>
                    )}
                  </div>
                );
              }
            },
            {
              title: 'Actions',
              dataIndex: '',
              key: 'x',
              fixed: 'right',
              width: 200,
              render: (_: any, row: SearchClaim) => <ActionsTableRow row={row} section={form} setData={setData} />
            }
          ]
        };
        break;
      }
      case SECTIONS.UB04: {
        _sectionProps = {
          ..._sectionProps,
          properties: ub04Fields,
          data: claimSearchData.ub,
          tableViews: [
            {
              title: <span className="table-title">{<InfoCircleOutlined />} Claim Info</span>,
              dataIndex: 'docId',
              key: 'docId',
              fixed: 'left',
              width: 250,
              render: (text: any, record: SearchClaim, index: any) => {
                // Display the sibling data in the 'Sibling Age' column
                return <ClaimInfoRow form={form} record={record} text={text} />;
              }
            },
            {
              title: (
                <span className="table-title">
                  <UserOutlined /> Patient Info
                </span>
              ),
              width: 250,
              dataIndex: 'patientFirstName',
              key: 'patientFirstName',
              render: (text: any, record: SearchClaim, index: any) => {
                // Display the sibling data in the 'Sibling Age' column
                return (
                  <div className="claims-info">
                    <div>
                      <p title={text + ' ' + record.patientLastName}>
                        {text} {record.patientLastName}
                      </p>
                    </div>
                    <div>
                      <p>
                        {/* <Tooltip title="Address">
                          <TiLocationOutline title="Address" />
                        </Tooltip> */}
                        {/* <span title={`ADDRESS`}>
                          <b>Address |</b> ADDRESS
                        </span> */}
                      </p>
                    </div>
                  </div>
                );
              }
            },
            {
              title: (
                <span className="table-title">
                  <SafetyOutlined /> Insured Info
                </span>
              ),
              width: 250,
              dataIndex: 'insuredId',
              key: 'insuredId',

              render: (text: any, record: SearchClaim, index: any) => {
                // Display the sibling data in the 'Sibling Age' column
                return (
                  <div className="insured-info">
                    {record.insuredId && (
                      <div>
                        <small>Insured ID: {record.insuredId && record.insuredId}</small>
                        <p title={record.insuredFirstName + ' ' + record.insuredLastName}>
                          {record.insuredFirstName} {record.insuredLastName}
                        </p>
                      </div>
                    )}
                    {record.insuredDob && (
                      <div>
                        <p>
                          <Tooltip title="Date of Birth">
                            <MdDateRange title="Date of Birth" />
                          </Tooltip>
                          <span title={`${moment(record.insuredDob).format(DATE_FORMAT[0])}`}>
                            <b>Dob |</b> {moment(record.insuredDob).format(DATE_FORMAT[0])}
                          </span>
                        </p>
                      </div>
                    )}
                  </div>
                );
              }
            },
            {
              title: 'Actions',
              dataIndex: '',
              key: 'x',
              fixed: 'right',
              width: 200,
              render: (_: any, row: SearchClaim) => <ActionsTableRow row={row} section={form} setData={setData} />
            }
          ]
        };
        break;
      }

      default: {
        break;
      }
    }

    return _sectionProps;
  }, [form]);

  return (
    <div className="claims-info">
      <div className="status">
        {record.status.externalId === ClaimStatusEnum.IN_PROGRESS ? (
          <Badge color="blue">{record.status.externalId}</Badge>
        ) : record.status.externalId === ClaimStatusEnum.SUBMIT_STARTED ? (
          <Badge color="gray">{record.status.externalId}</Badge>
        ) : (
          <Badge color="green">{record.status.externalId}</Badge>
        )}
      </div>
      {/* <div style={{ marginBottom: 0 }}>
        <small>ID: {text}</small>
      </div> */}
      <div>
        <small>Claim ID: {record?.groupId}</small>
      </div>
      <div>
        <p>
          <Tooltip title="Created">
            <BiSolidEdit title="Created" />
          </Tooltip>
          <span title={`${moment(record.creationDate).format('MMMM Do YYYY, HH:mm:ss')} by ${record.createdBy}`}>
            <b> Created |</b> {moment(record.creationDate).format('MMMM Do YYYY, HH:mm:ss')} by {record.createdBy}
          </span>
        </p>
        {record.modifiedDate ? (
          <p>
            <Tooltip title="Modified">
              <VscDiffModified title="Modified" />
            </Tooltip>
            <span title={`${moment(record.modifiedDate).format('MMMM Do YYYY, HH:mm:ss')}`}>
              <b>Modified |</b> {moment(record.modifiedDate).format('MMMM Do YYYY, HH:mm:ss')}
            </span>
          </p>
        ) : null}
        {record.dateOfService ? (
          <p>
            <Tooltip title="Date of Service">
              <MdDateRange title="Date of Service" />
            </Tooltip>
            {record.dateOfService && (
              <span title={`${record.dateOfService && moment(record.dateOfService).format(DATE_FORMAT[0])}`}>
                <b>Date of Service |</b> moment(record.dateOfService).format(DATE_FORMAT[0])
              </span>
            )}
          </p>
        ) : null}
        {record.totalCharges ? (
          <p>
            <Tooltip title="Total Charges">
              <MdAttachMoney title="Total Charges" />
            </Tooltip>
            {record.totalCharges && `${record.totalCharges} $`}
          </p>
        ) : null}
        {record.lockedBy ? (
          <p>
            <Tooltip title={`Locked by ${record.lockedBy}`}>
              <LockOutlined title={`Locked by ${record.lockedBy}`} />
            </Tooltip>
            {record.lockedBy}
          </p>
        ) : null}
        {Boolean(record?.dcn) && (
          <p>
            <IdcardOutlined /> DCN: &nbsp; {record.dcn ?? 'EMPTY'}
          </p>
        )}
        {showRejections && (
          <p>
            <Tooltip
              title={
                <ol>
                  {rejections?.map((r) => {
                    return <li key={r}>{r}</li>;
                  })}
                </ol>
              }
            >
              <AiOutlineStop />
            </Tooltip>

            <span>
              <b>Reject Reasons:</b> &nbsp; {rejections?.[0]}
            </span>
          </p>
        )}
        {record.originType ? (
          <p>
            <Tooltip title={`Origin: ${record.originType}`}>
              <AimOutlined />{' '}
            </Tooltip>
            {record.originType}{' '}
            {record?.originalId && (
              <>
                <Button
                  onClick={() => showModal(record?.originalId)}
                  style={{ marginTop: '-3px' }}
                  size="small"
                  type="link"
                  //icon={<EyeOutlined />}
                >
                  <span style={{ textDecoration: 'underline' }}>Click to Preview Original Claim </span>
                </Button>
                <Modal
                  className="large"
                  destroyOnClose
                  title="PREVIEW CLAIM"
                  open={isModalOpen}
                  onOk={handleOk}
                  onCancel={handleCancel}
                  footer={false}
                >
                  <div className="search-table">
                    <Table columns={sectionProps.tableViews} dataSource={data} pagination={false} scroll={{ x: 960 }} />
                  </div>
                </Modal>
              </>
            )}
          </p>
        ) : null}
        {record?.resubmittedId && (
          <p>
            <EyeOutlined />
            <span>ORIGINAL</span>{' '}
            <Button
              onClick={() => showResubmittedModal(record!.resubmittedId!)}
              style={{ marginTop: '-3px' }}
              size="small"
              type="link"
            >
              <span style={{ textDecoration: 'underline' }}>Preview Corrected Claim</span>
            </Button>
            <Modal
              className="large"
              destroyOnClose
              title="RESUBMITTED CLAIM"
              open={isModalOpen}
              onOk={handleOk}
              onCancel={handleCancel}
              footer={false}
            >
              <div className="search-table">
                <Table columns={sectionProps.tableViews} dataSource={data} pagination={false} scroll={{ x: 960 }} />
              </div>
            </Modal>
          </p>
        )}
      </div>
    </div>
  );
};

export default ClaimInfoRow;
