export const UB04_FIELDS: Record<string, string> = {
  serialNumber: 'serialNumber',
  payer_Name: 'Payer_Name',
  billingName: '1a_billing_name',
  billingAddress: '1b_billing_addr_1',
  billingCity: '1c_billing_city',
  billingState: '1d_billing_state',
  billingZip: '1e_billing_zip',
  billingPhone: '1f_billing_phone',

  billing_addr2: 'billing_addr2',
  pay_to_addr2: 'pay_to_addr2',
  pat_addr2: 'pat_addr2',
  attending_provider_addr2: 'pattending_provider_addr2',

  servicesList: '42Services',
  servicesTable: '24Table',

  rev_cd_42: '42_rev_cd',
  description_43: '43_description',
  hcpcs_rates_44: '44_hcpcs_rates',
  hcpcs_description_44: '44_hcpcs_description',
  field_43_description: '43_description',
  dos_45: '45_dos',
  serv_units_46: '46_serv_units',
  total_charges_47: '47_total_charges',
  ub_total_charges_sum: '49ub_total_charges_sum',
  nonCoveredCharges_48: 'nonCoveredCharges',
  ub49: 'UB49',

  payToName: '2a_pay_to_name',
  payToAddress: '2b_pay_to_address',
  payToCity: '2c_pay_to_city',
  payToState: '2d_pay_to_state',
  payToZip: '2e_pay_to_zip',
  payToPhone: '2f_phone',
  payToCountry: '2g_pay_to_country',

  patientControlNumber: '3a_pat_ctrl_no',
  medicalRecordNumber: '3b_med_rec_no',

  typeOfBill: '4_type_of_bill',
  billingTin: '5_billing_tin',
  federalTaxNumber: '5_federal_tax_number',

  coversPeriodFrom: '6_stmt_from_date',
  coversPeriodTo: '6_stmt_to_date',

  adminNecessaryDays: '7adminNecessaryDays',

  patientId: '8a_pat_id',
  patientLastName: '8b_pat_last_name',
  patientFirstName: '8b_pat_first_name',
  patientMiddleInitial: '8b_pat_mi',
  patientGen: '8b_pat_gen',
  patientBirthdate: '10_pat_dob',
  patientSex: '3_pat_sex',

  patientAddress: '9a_pat_address',
  patientCity: '9b_pat_city',
  patientState: '9c_pat_state',
  patientZip: '9d_pat_zip',

  admissionDate: '12_admit_date',
  admissionHour: '13_admission_hour',
  admissionType: '14_admission_type',
  admissionSource: '15_admission_source',

  dischargeHour: '16_discharge_hr',
  dischargeStatus: '17_discharge_stat',

  conditionCode18: '18_condition_code',
  conditionCode19: '19_condition_code',
  conditionCode20: '20_condition_code',
  conditionCode21: '21_condition_code',
  conditionCode22: '22_condition_code',
  conditionCode23: '23_condition_code',
  conditionCode24: '24_condition_code',
  conditionCode25: '25_condition_code',
  conditionCode26: '26_condition_code',
  conditionCode27: '27_condition_code',
  conditionCode28: '28_condition_code',

  accidentState: '29_accident_state',

  occurrenceCode31A: '31a_occur_code',
  occurrenceDate31A: '31a_occur_date',
  occurrenceCode31B: '31b_occur_code',
  occurrenceDate31B: '31b_occur_date',
  occurrenceCode32A: '32a_occur_code',
  occurrenceDate32A: '32a_occur_date',
  occurrenceCode32B: '32b_occur_code',
  occurrenceDate32B: '32b_occur_date',
  occurrenceCode33A: '33a_occur_code',
  occurrenceDate33A: '33a_occur_date',
  occurrenceCode33B: '33b_occur_code',
  occurrenceDate33B: '33b_occur_date',
  occurrenceCode34A: '34a_occur_code',
  occurrenceDate34A: '34a_occur_date',
  occurrenceCode34B: '34b_occur_code',
  occurrenceDate34B: '34b_occur_date',

  occurrenceSpan35ACode: '35a_span_code',
  occurrenceSpan35AFrom: '35a_span_from',
  occurrenceSpan35ATo: '35a_span_to',
  occurrenceSpan35BCode: '35b_span_code',
  occurrenceSpan35BFrom: '35b_span_from',
  occurrenceSpan35BTo: '35b_span_to',

  occurrenceSpan36ACode: '36a_span_code',
  occurrenceSpan36AFrom: '36a_span_from',
  occurrenceSpan36ATo: '36a_span_to',
  occurrenceSpan36BCode: '36b_span_code',
  occurrenceSpan36BFrom: '36b_span_from',
  occurrenceSpan36BTo: '36b_span_to',

  ub37_a: 'UB37_A',
  ub37_b: 'UB37_B',

  responsiblePartyFirstName: '38_rp_first_name',
  responsiblePartyLastName: '38_rp_last_name',
  responsiblePartyGen: '38_rp_gen',
  responsiblePartyMiddleInitial: '38_rp_mi',
  responsiblePartyAddress: '38_rp_addr',
  responsiblePartyCity: '38_rp_city',
  responsiblePartyState: '38_rp_state',
  responsiblePartyZip: '38_rp_zip_code',
  responsiblePartyAddressTwo: 'UB38_02_01_01',

  value39ACode: '39a_value_code',
  value39AAmount: '39a_value_amount',
  value39BCode: '39b_value_code',
  value39BAmount: '39b_value_amount',
  value39CCode: '39c_value_code',
  value39CAmount: '39c_value_amount',
  value39DCode: '39d_value_code',
  value39DAmount: '39d_value_amount',

  value40ACode: '40a_value_code',
  value40AAmount: '40a_value_amount',
  value40BCode: '40b_value_code',
  value40BAmount: '40b_value_amount',
  value40CCode: '40c_value_code',
  value40CAmount: '40c_value_amount',
  value40DCode: '40d_value_code',
  value40DAmount: '40d_value_amount',

  value41ACode: '41a_value_code',
  value41AAmount: '41a_value_amount',
  value41BCode: '41b_value_code',
  value41BAmount: '41b_value_amount',
  value41CCode: '41c_value_code',
  value41CAmount: '41c_value_amount',
  value41DCode: '41d_value_code',
  value41DAmount: '41d_value_amount',

  payer50A: '50a_payer',
  payer50B: '50b_payer',
  payer50C: '50c_payer',

  healthPlan51A: '51a_plan_id',
  healthPlan51B: '51b_plan_id',
  healthPlan51C: '51c_plan_id',

  releaseInfo52A: '52a_rel_info',
  releaseInfo52B: '52b_rel_info',
  releaseInfo52C: '52c_rel_info',

  assignmentOfBenefits53A: '53a_asg_ben',
  assignmentOfBenefits53B: '53b_asg_ben',
  assignmentOfBenefits53C: '53c_asg_ben',

  priorPayments54A: '54a_prior_pmt',
  priorPayments54B: '54b_prior_pmt',
  priorPayments54C: '54c_prior_pmt',

  estimatedAmount55A: '55a_est_amt_due',
  estimatedAmount55B: '55b_est_amt_due',
  estimatedAmount55C: '55c_est_amt_due',

  billingNpi: '56_billing_npi',

  otherBillingId57A: '57a_billing_id',
  otherBillingId57B: '57b_billing_id',
  otherBillingId57C: '57c_billing_id',

  insured58aLastName: '58a_ins_last_name',
  insured58aFirstName: '58a_ins_first_name',
  insured58aMiddleInitial: '58a_ins_mid_name',
  insured58aGen: '58a_ins_gen',
  insured58bLastName: '58b_ins_last_name',
  insured58bFirstName: '58b_ins_first_name',
  insured58bMiddleInitial: '58b_ins_mid_name',
  insured58bGen: '58b_ins_gen',
  insured58cLastName: '58c_ins_last_name',
  insured58cFirstName: '58c_ins_first_name',
  insured58cMiddleInitial: '58c_ins_mid_name',
  insured58cGen: '58c_ins_gen',

  insured59aRelationship: '59a_pat_rel',
  insured59bRelationship: '59b_pat_rel',
  insured59cRelationship: '59c_pat_rel',

  insured60aUniqueId: '60a_ins_id',
  insured60bUniqueId: '60b_ins_id',
  insured60cUniqueId: '60c_ins_id',

  insured61aGroupName: '61a_grp_name',
  insured61bGroupName: '61b_grp_name',
  insured61cGroupName: '61c_grp_name',

  insured62aGroupNumber: '62a_ins_grp_no',
  insured62bGroupNumber: '62b_ins_grp_no',
  insured62cGroupNumber: '62c_ins_grp_no',

  authorizationCode63a: '63a_auth',
  authorizationCode63b: '63b_auth',
  authorizationCode63c: '63c_auth',

  documentControlNumber64a: '64a_dcn',
  documentControlNumber64b: '64b_dcn',
  documentControlNumber64c: '64c_dcn',

  employerName65a: '65a_employer',
  employerName65b: '65b_employer',
  employerName65c: '65c_employer',

  codeQualifier: '66_dx_ind',

  principalDiagnosePoa: '67_principal_poa',
  principalDiagnoseDx: '67_principal_dx',

  code67aDx: '67a_oth_diag',
  code67aPoa: '67a_oth_poa',
  code67bDx: '67b_oth_diag',
  code67bPoa: '67b_oth_poa',
  code67cDx: '67c_oth_diag',
  code67cPoa: '67c_oth_poa',
  code67dDx: '67d_oth_diag',
  code67dPoa: '67d_oth_poa',
  code67eDx: '67e_oth_diag',
  code67ePoa: '67e_oth_poa',
  code67fDx: '67f_oth_diag',
  code67fPoa: '67f_oth_poa',
  code67gDx: '67g_oth_diag',
  code67gPoa: '67g_oth_poa',
  code67hDx: '67h_oth_diag',
  code67hPoa: '67h_oth_poa',
  code67iDx: '67i_oth_diag',
  code67iPoa: '67i_oth_poa',
  code67jDx: '67j_oth_diag',
  code67jPoa: '67j_oth_poa',
  code67kDx: '67k_oth_diag',
  code67kPoa: '67k_oth_poa',
  code67lDx: '67l_oth_diag',
  code67lPoa: '67l_oth_poa',
  code67mDx: '67m_oth_diag',
  code67mPoa: '67m_oth_poa',
  code67nDx: '67n_oth_diag',
  code67nPoa: '67n_oth_poa',
  code67oDx: '67o_oth_diag',
  code67oPoa: '67o_oth_poa',
  code67pDx: '67p_oth_diag',
  code67pPoa: '67p_oth_poa',
  code67qDx: '67q_oth_diag',
  code67qPoa: '67q_oth_poa',

  ub68A: 'UB68_Cd_A',
  ub68B: 'UB68_Cd_B',

  admittingDiagnosisCode: '69_admit_dx',
  reasonForVisitA: '70a_pat_reason_dx',
  reasonForVisitB: '70b_pat_reason_dx',
  reasonForVisitC: '70c_pat_reason_dx',

  pps: '71_pps',

  codeEci72a: '72a_eci',
  codeEci72aPoa: '72a_poa',
  codeEci72b: '72b_eci',
  codeEci72bPoa: '72b_poa',
  codeEci72c: '72c_eci',
  codeEci72cPoa: '72c_poa',

  ub73_loc: 'ub73_loc',

  principalProcedureCode: '74_prin_proc_code',
  principalProcedureDate: '74_prin_proc_date',

  procedureCode74a: '74a_other_proc_code',
  procedureDate74a: '74a_other_proc_date',
  procedureCode74b: '74b_other_proc_code',
  procedureDate74b: '74b_other_proc_date',
  procedureCode74c: '74c_other_proc_code',
  procedureDate74c: '74c_other_proc_date',
  procedureCode74d: '74d_other_proc_code',
  procedureDate74d: '74d_other_proc_date',
  procedureCode74e: '74e_other_proc_code',
  procedureDate74e: '74e_other_proc_date',

  ub75: 'UB75',

  atten_Phy_Suf_01: 'Atten_Phy_Suf_01',
  attendingProviderFirstName: '76_attn_first_name',
  attendingProviderLastName: '76_attn_last_name',
  attendingProviderMiddleInitial: '76_attn_middle_initial',
  attendingProviderGender: '76_attn_gen',
  attendingProviderId: '76_attn_id',
  attendingProviderQual: '76_attn_qual',
  attendingProviderNpi: '76_attn_npi',

  oper_Phy_Suf_01: 'Oper_Phy_Suf_01',
  operatingProviderFirstName: '77_oper_first_name',
  operatingProviderLastName: '77_oper_last_name',
  operatingProviderMiddleInitial: '77_oper_middle_initial',
  operatingProviderGender: '77_attn_gen',
  operatingProviderId: '77_oper_id',
  operatingProviderQual: '77_oper_qual',
  operatingProviderNpi: '77_oper_npi',

  oth_Phy_Suf_01: 'Oth_Phy_Suf_01',
  otherProvider1FirstName: '78_other_first_name',
  otherProvider1LastName: '78_other_last_name',
  otherProvider1MiddleInitial: '78_other_middle_initial',
  otherProvider1Gender: '78_other_gen',
  otherProvider1Id: '78_other_prov_id',
  otherProvider1Qual: '78_other_prov_qual',
  otherProvider1Npi: '78_other_npi',
  otherType78: '78_other_type',

  oth_Phy_Suf_02: 'Oth_Phy_Suf_02',
  otherProvider2FirstName: '79_other_first_name',
  otherProvider2LastName: '79_other_last_name',
  otherProvider2MiddleInitial: '79_other_middle_initial',
  otherProvider2Gender: '79_other_gen',
  otherProvider2Id: '79_other_prov_id',
  otherProvider2Qual: '79_other_prov_qual',
  otherProvider2Npi: '79_other_npi',
  otherType79: '79_other_type',
  remarks: '80_remarks',

  additionalCodeQualA: '81a_code_qual',
  additionalCodeA: '81a_code',
  additionalCodeValA: '81a_code_val',
  additionalCodeQualB: '81b_code_qual',
  additionalCodeB: '81b_code',
  additionalCodeValB: '81b_code_val',
  additionalCodeQualC: '81c_code_qual',
  additionalCodeC: '81c_code',
  additionalCodeValC: '81c_code_val',
  additionalCodeQualD: '81d_code_qual',
  additionalCodeD: '81d_code',
  additionalCodeValD: '81d_code_val',

  // payer_destination_a: 'payer_destination_a',
  // payer_destination_b: 'payer_destination_b',
  // payer_destination_c: 'payer_destination_c',

  modifier_1: 'modifier_1',
  modifier_2: 'modifier_2',
  modifier_3: 'modifier_3',
  modifier_4: 'modifier_4',
  modifier_5: 'modifier_5',
  modifier_6: 'modifier_6',
  modifier_7: 'modifier_7',
  modifier_8: 'modifier_8',
  modifier_9: 'modifier_9',
  modifier_10: 'modifier_10',

  desc_ndc_code: 'desc_ndc_code',
  desc_ndc_qty: 'desc_ndc_qty',
  desc_ndc_unit: 'desc_ndc_unit'
};
