import React from 'react';
import { useTranslation } from 'react-i18next';
import { Col, Form, Input, Row } from 'antd';
import SelectState from './SelectState';
import { getYupValidator as ub04YupValidator } from 'utils/ub04/validataions';
import { getYupWarning, getYupValidator as hcfaYupValidator } from 'utils/hcfa/validations';
import { getYupValidator as dentalYupValidator } from 'utils/dental/validations';
import { toNum } from '../../../helpers/toNum';

import './FieldsetLocation.scss';
import { uppercaseInputValue } from 'helpers/uppercaseInputValue';
import { toZipCode } from 'helpers/toZipCode';

type Fields = 'address' | 'city' | 'state' | 'zipcode' | 'addressTwo' | 'phone';

type FieldsetFullNameProps = {
  form: 'hcfa' | 'ub04' | 'dental';
  ids: Partial<Record<Fields, string>>;
  labels?: Partial<Record<Fields, string>>;
  breakPoints?: Partial<Record<Fields, number>>;
  labelColoring?: boolean;
  limitedAddress?: boolean;
  consecutiveAddress?: boolean;
};

const validatorsMap = {
  hcfa: hcfaYupValidator,
  ub04: ub04YupValidator,
  dental: dentalYupValidator
};

function FieldsetLocation({
  form,
  ids,
  labels,
  breakPoints,
  labelColoring,
  limitedAddress,
  consecutiveAddress
}: FieldsetFullNameProps): JSX.Element {
  const { t } = useTranslation();

  const yupValidator = validatorsMap[form];

  return (
    <Row gutter={0}>
      <Col lg={24} xl={breakPoints?.address || 12}>
        <Form.Item
          className="field--first field--last"
          label={
            labels?.address && labelColoring ? (
              <span className={form === 'hcfa' ? 'form_label_color' : ''}>{labels?.address}</span>
            ) : (
              <span>
                {t('common:fields.address')} ({t('common:fields.streetNumber')})
              </span>
            )
          }
          name={ids.address}
          rules={ids.address ? [yupValidator(t, ids.address), getYupWarning(t, ids.address)] : [{}]}
        >
          <Input placeholder="ADDRESS" onInput={(e: any) => (e.target.value = e.target.value.toUpperCase())} />
        </Form.Item>
      </Col>
      {consecutiveAddress && ids.addressTwo && (
        <Col lg={24} xl={breakPoints?.city || 12}>
          <Form.Item
            className="field--first field--last"
            label={t('common:fields.addressTwo')}
            name={ids.addressTwo}
            rules={[ids.addressTwo ? yupValidator(t, ids.addressTwo) : {}]}
          >
            <Input placeholder="ADDRESS 2" onInput={(e: any) => (e.target.value = e.target.value.toUpperCase())} />
          </Form.Item>
        </Col>
      )}
      <Col xs={24} md={12} lg={breakPoints?.city || 6} xxl={breakPoints?.city || 12}>
        <div className="FieldsetLocation__disabled-container">
          <Form.Item
            className="field--first"
            label={labels?.city || t('common:fields.city')}
            name={ids.city}
            rules={[ids.city ? yupValidator(t, ids.city) : {}]}
          >
            <Input placeholder="CITY" onInput={uppercaseInputValue} />
          </Form.Item>
        </div>
      </Col>
      <Col xs={24} md={12} lg={breakPoints?.state || 6} xxl={breakPoints?.state || 12} className="field--last">
        <div className="FieldsetLocation__disabled-container">
          <Form.Item
            label={labels?.state || t('common:fields.state')}
            name={ids.state}
            rules={[ids.state ? yupValidator(t, ids.state) : {}]}
          >
            <SelectState placeholder="STATE" inputKey={ids.state as string} />
          </Form.Item>
        </div>
      </Col>
      <Col xs={24} md={12} lg={breakPoints?.zipcode || 6} xxl={breakPoints?.zipcode || 12}>
        <Form.Item
          className="field--first"
          label={labels?.zipcode || t('common:fields.zipCode')}
          name={ids.zipcode}
          rules={[ids.zipcode ? yupValidator(t, ids.zipcode) : {}]}
        >
          <Input onInput={toZipCode} minLength={5} placeholder="ZIP CODE" />
        </Form.Item>
      </Col>

      {!consecutiveAddress && ids.addressTwo && (
        <Col xs={24}>
          <Form.Item
            className="field--first field--last"
            label={t('common:fields.addressTwo')}
            name={ids.addressTwo}
            rules={[ids.addressTwo ? yupValidator(t, ids.addressTwo) : {}]}
          >
            <Input placeholder="ADDRESS 2" onInput={(e: any) => (e.target.value = e.target.value.toUpperCase())} />
          </Form.Item>
        </Col>
      )}

      {ids.phone && (
        <Col xs={24} xxl={breakPoints?.phone || 12}>
          <Form.Item
            className="mb-0"
            label={labels?.phone || t('common:fields.telephone')}
            rules={[ids.phone ? yupValidator(t, ids.phone) : {}]}
            name={ids.phone}
          >
            {/* <Row gutter={8} wrap={false}>
              <Col>
                <Form.Item name={`${ids.phone}_code`}>
                  <SelectPhoneCode />
                </Form.Item>
              </Col>
              <Col flex="auto">
                <Form.Item name={ids.phone} rules={[ids.phone ? yupValidator(t, ids.phone) : {}]}> */}
            <Input placeholder="PHONE NUMBER" minLength={10} onInput={toNum} />
            {/* </Form.Item>
              </Col>
            </Row> */}
          </Form.Item>
        </Col>
      )}
    </Row>
  );
}

export default FieldsetLocation;
