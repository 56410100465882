import { useOidcAccessToken } from '@axa-fr/react-oidc';
import { getDashboardCount } from 'api/dashbard';
import React, { useContext, useEffect, useState } from 'react';
import { MdOutlineHealthAndSafety } from 'react-icons/md';
import { useCanHandleDental } from 'utils/hooks/useCanHandleDental';
import { FORM_ACTIONS, SECTIONS } from 'config/constants';
import { TbDental, TbHealthRecognition } from 'react-icons/tb';
import { Modal, Tabs, notification } from 'antd';
import { Page } from 'components/common/Page';
import Search from './Search';
import { ReactComponent as PlusIcon } from 'heroicons/outline/plus.svg';
import { ReactComponent as PaperAirplane } from 'heroicons/outline/paper-airplane.svg';

import { ReactComponent as UploadIcon } from 'heroicons/outline/upload.svg';
import { ButtonList, Button, ValidationErrors, FieldError } from '@datum/react-components';
import { useNavigate, useParams } from 'react-router';
import { useCanViewArchive } from 'utils/hooks/useCanViewArchive';
import NotAllowedPage from 'components/app/NotAllowedPage';
import { FormContext } from 'components/common/FormProvider';
import FormAttachmentsNew from 'components/common/FormAttachmentsNew';
import { uploadClaimStraightThrough } from 'api/claims';

const { TabPane } = Tabs;

interface Props {
  isArchive?: boolean;
}

const LandingPage: React.FC<Props> = ({ isArchive }) => {
  const canHandleDental = useCanHandleDental();
  const { accessToken } = useOidcAccessToken();
  const [dasboardHcfaCount, setDasboardHcfaCount] = useState(0);
  const [dasboardUb04Count, setDasboardUb04Count] = useState(0);
  const [dasboardDentalCount, setDasboardDentalCount] = useState(0);
  const navigate = useNavigate();
  const canViewArchive = useCanViewArchive();

  const [loading, setLoading] = useState(false);

  const [isModalOpen, setIsModalOpen] = React.useState(false);
  const { fileRepresentationsList, setFileRepresentationsList } = useContext(FormContext);
  /**Upload hardcoded to HCFA */
  const [section] = useState<SECTIONS>(SECTIONS.HCFA);
  const [validationErrors, setValidationErrors] = React.useState<FieldError[]>([]);

  const { tabId } = useParams();

  useEffect(() => {
    handleDashboardHcfaCount();
    handleDashboardUb04Count();
    handleDashboarDentalCount();
  }, [isArchive]);

  useEffect(() => {
    if (fileRepresentationsList?.length > 0) {
      setValidationErrors([]);
    }
  }, [fileRepresentationsList?.length]);

  const handleDashboardHcfaCount = async () => {
    if (accessToken) {
      try {
        const data = await getDashboardCount('hcfa', accessToken, isArchive);

        setDasboardHcfaCount(data || 0);
      } catch (error: any) {
        console.error('An error occurred:', error);
      } finally {
        //
      }
    }
  };

  const handleDashboardUb04Count = async () => {
    if (accessToken) {
      try {
        const data = await getDashboardCount('ub04', accessToken, isArchive);

        setDasboardUb04Count(data || 0);
      } catch (error: any) {
        console.error('An error occurred:', error);
      } finally {
        //
      }
    }
  };

  const handleDashboarDentalCount = async () => {
    if (accessToken) {
      try {
        const data = await getDashboardCount('dental', accessToken, isArchive);

        setDasboardDentalCount(data || 0);
      } catch (error: any) {
        console.error('An error occurred:', error);
      } finally {
        //
      }
    }
  };

  const handleCancel = () => {
    setIsModalOpen(false);
    setFileRepresentationsList([]);
    setValidationErrors([]);
  };

  const handleOk = async () => {
    setLoading(true);

    if (fileRepresentationsList?.length === 0) {
      notification.error({
        message: 'Please add a document and try again.'
      });

      setValidationErrors([
        { propLabel: 'Document', errorMessage: 'Document is missing. Please add document and try again.' }
      ]);

      setLoading(false);
      return false;
    }

    if (!fileRepresentationsList?.some((r) => r.containsOriginalClaim)) {
      notification.error({
        message: 'One attachment must contain original claim'
      });

      setValidationErrors([
        { propLabel: 'Original claim', errorMessage: 'Please select document that contains original claim.' }
      ]);

      setLoading(false);
      return false;
    }

    try {
      const uploadedClaim = await uploadClaimStraightThrough(fileRepresentationsList, section, accessToken);

      handleCancel();

      notification.success({
        message: 'Document uploaded successfully. ID:',
        description: <b>{`${uploadedClaim.docId}`}</b>
      });
    } catch (e) {
      console.log('error', e);
      notification.error({
        message: 'Upload failed. Try again'
      });
    } finally {
      setLoading(false);
    }
  };

  const tabPanels = [
    {
      key: 'tab-hcfa',
      label: (
        <div className="tab-title-wrapper">
          <MdOutlineHealthAndSafety />
          <p>HCFA Claims</p>
          <p>
            <small>({dasboardHcfaCount})</small>
          </p>
        </div>
      ),
      children: (
        <div className="tab-content dashboard-content" id="tab-hcfa-content">
          <Search isArchive={isArchive} section={SECTIONS.HCFA} />
        </div>
      )
    },
    {
      key: 'tab-ub',
      label: (
        <div className="tab-title-wrapper">
          <TbHealthRecognition />
          <p>UB Claims</p>
          <p>
            <small>({dasboardUb04Count})</small>
          </p>
        </div>
      ),
      children: (
        <div className="tab-content dashboard-content" id="tab-ub04-content">
          <Search isArchive={isArchive} section={SECTIONS.UB04} />
        </div>
      )
    }
  ];

  if (canHandleDental) {
    tabPanels.push({
      key: 'tab-dental',
      label: (
        <div className="tab-title-wrapper">
          <TbDental />
          <p>Dental Claims</p>
          <p>
            <small>({dasboardDentalCount})</small>
          </p>
        </div>
      ),
      children: (
        <div className="tab-content dashboard-content" id="tab-dental-content">
          <Search isArchive={isArchive} section={SECTIONS.DENTAL} />
        </div>
      )
    });
  }
  if (isArchive && !canViewArchive) {
    return <NotAllowedPage />;
  }
  return (
    <>
      {!isArchive && (
        <div className="Search__actions-wrapper">
          <h2 className="actions-title">Claims</h2>
          <ButtonList>
            <ButtonList.Item leftIcon={<PlusIcon />} onClick={() => navigate(`/hcfa/${FORM_ACTIONS.NEW}`)}>
              New HCFA Claim
            </ButtonList.Item>
            <ButtonList.Item leftIcon={<PlusIcon />} onClick={() => navigate(`/ub04/${FORM_ACTIONS.NEW}`)}>
              New UB Claim
            </ButtonList.Item>
            {canHandleDental && (
              <ButtonList.Item leftIcon={<PlusIcon />} onClick={() => navigate(`/dental/${FORM_ACTIONS.NEW}`)}>
                New Dental Claim
              </ButtonList.Item>
            )}
            <ButtonList.Item leftIcon={<UploadIcon />} onClick={() => setIsModalOpen(true)}>
              Upload Claim
            </ButtonList.Item>
            <ButtonList.Item leftIcon={<PlusIcon />} onClick={() => navigate(`/document-status-reports`)}>
              Reports
            </ButtonList.Item>
          </ButtonList>
        </div>
      )}
      <div className={'dashboard-page'}>
        <Page.Content>
          <Tabs
            tabPosition="top"
            style={{ height: '100%' }}
            // onChange={handleTabChange}
            className="dashboard-tabs"
            defaultActiveKey={tabId ?? 'tab-hcfa'}
          >
            {tabPanels.map((panel) => (
              <TabPane tab={panel.label} key={panel.key}>
                {panel.children}
              </TabPane>
            ))}
          </Tabs>
        </Page.Content>
        <Modal
          className="simple-upload-doc"
          width={1000}
          title={`Upload Claim`}
          open={isModalOpen}
          onOk={handleOk}
          onCancel={handleCancel}
          destroyOnClose
          footer={[
            <Button key="back" variant="light" onClick={handleCancel}>
              Cancel
            </Button>,
            <Button
              leftIcon={<PaperAirplane style={{ rotate: '90deg' }} />}
              key="submit"
              variant="primary"
              // loading={loading}
              disabled={loading}
              onClick={handleOk}
              style={{ marginLeft: '1rem' }}
            >
              Send
            </Button>
          ]}
        >
          <ValidationErrors errors={validationErrors} clearErrors={() => setValidationErrors([])} />
          <FormAttachmentsNew form={section} readOnly={false} accept=".pdf, .jpeg, .jpg, .tiff, .png, .tif" isSimple />
        </Modal>
      </div>
    </>
  );
};

export default LandingPage;
