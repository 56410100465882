import * as React from 'react';

type FormProviderProps = { children: React.ReactNode };

export type FormState = {
  claimId?: string;
  filters?: string[];
};

type Action =
  | { type: 'SET_CLAIM_ID'; claimId: string }
  | { type: 'SET_FILTERS'; filters: string[] }
  | { type: 'CLEAR_STATE' };

type Dispatch = (action: Action) => void;

export const FormStateContext = React.createContext<FormState | undefined>(undefined);
export const FormDispatchContext = React.createContext<Dispatch | undefined>(undefined);

function reducer(state: FormState, action: Action) {
  switch (action.type) {
    case 'SET_CLAIM_ID': {
      return { ...state, claimId: action.claimId };
    }
    case 'SET_FILTERS': {
      return { ...state, filters: action.filters };
    }
    case 'CLEAR_STATE': {
      return { claimId: undefined, filters: undefined };
    }
    default:
      return state;
  }
}

function FormProvider({ children }: FormProviderProps): JSX.Element {
  const [state, dispatch] = React.useReducer(reducer, {
    claimId: undefined,
    filters: undefined
  });
  return (
    <FormStateContext.Provider value={state}>
      <FormDispatchContext.Provider value={dispatch}>{children}</FormDispatchContext.Provider>
    </FormStateContext.Provider>
  );
}

function useFormProvider(): [FormState, Dispatch] {
  const stateContext = React.useContext(FormStateContext);
  const dispatchContext = React.useContext(FormDispatchContext);
  if (stateContext === undefined || dispatchContext === undefined) {
    throw new Error('useFormProvider must be used within an FormProvider');
  }
  return [stateContext, dispatchContext];
}

export { FormProvider, useFormProvider };
