import { SECTIONS } from 'config/constants';
import React, { useState } from 'react';
import FormAttachmentsNew from './FormAttachmentsNew';
import './PageViewerWithAttachments.scss';
import { AttachmentDTO, FileRepresentationsDTO } from 'models/DTO/AttachmentDTO';
import PageViewer from './PageViewer';

interface Props {
  accept?: string;
  readOnly: boolean;
  form: 'ub04' | 'hcfa' | 'dental' | SECTIONS;
}

const PageViewerWithAttachments: React.FC<Props> = ({ form, readOnly, accept }) => {
  const [selectedAttachment, setSelectedAttachment] = useState<AttachmentDTO | FileRepresentationsDTO>();

  return (
    <div className="PageViewerWithAttachments__container">
      <FormAttachmentsNew form={'hcfa'} readOnly={readOnly} setSelectedAttachment={setSelectedAttachment} />
      <div className="PageViewerWithAttachments__page-viewer-container-container">
        <PageViewer selectedAttachment={selectedAttachment} />
      </div>
    </div>
  );
};

export default PageViewerWithAttachments;
