export function isValidLuhn(value: string) {
  if (value.match(/^\d{10}$/)) {
    const lastDigit = parseInt(value.charAt(9), 10);
    const everyOther = listWithEveryOtherDoubled(value.substring(0, 9));
    let sum = 0;

    for (const num of everyOther) {
      sum += sumOfDigits(num);
    }

    const total = sum + 24;
    const units = total % 10;
    const checkDigit = units !== 0 ? 10 - units : units;
    return lastDigit === checkDigit;
  }
  return false;
}

function listWithEveryOtherDoubled(str: string) {
  const nums = [];

  for (let i = 0; i < str.length; i++) {
    if (i % 2 === 0) {
      nums.push(2 * parseInt(str.charAt(i), 10));
    } else {
      nums.push(parseInt(str.charAt(i), 10));
    }
  }

  return nums;
}

function sumOfDigits(number: number) {
  let num = number;
  let sum = 0;

  while (num > 0) {
    sum += num % 10;
    num = Math.floor(num / 10);
  }

  return sum;
}
