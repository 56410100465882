import axios from 'axios';
import { ClaimStatusEnum } from 'models/enums/ClaimStatusEnum';

let resolvedStatuses: ClaimStatusEnum[] | null = null;

export const getResubmitStatuses = async (token: string): Promise<ClaimStatusEnum[]> => {
  if (resolvedStatuses) return resolvedStatuses;
  const statuses = await fetchResubmitStatuses(token);
  resolvedStatuses = statuses;
  return statuses;
};

const fetchResubmitStatuses = async (token: string) => {
  const res = await axios.get<ClaimStatusEnum[]>(window._env_.API_URL + `/api/v1/statuses/resubmit`, {
    headers: {
      Authorization: `Bearer ${token}`
    }
  });
  return res.data;
};

export const fetchStatuses = async (token: string) => {
  const res = await axios.get<ClaimStatusEnum[]>(window._env_.API_URL + `/api/v1/statuses`, {
    headers: {
      Authorization: `Bearer ${token}`
    }
  });
  return res.data;
};
