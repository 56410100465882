import { useOidcAccessToken } from '@axa-fr/react-oidc';
import { KeycloakDataEnum } from 'models/enums/KeycloakDataEnum';

export const useAccessTokenProvidedInfo = () => {
  const { accessTokenPayload } = useOidcAccessToken();
  const providerNameOidc: string = accessTokenPayload?.[KeycloakDataEnum.providerNameOidc];
  const userBillingOidc: string | undefined = accessTokenPayload?.[KeycloakDataEnum.userBillingOidc];
  const userAddressOidc: string | undefined = accessTokenPayload?.[KeycloakDataEnum.userAddressOidc];
  const providerAddressOidc: string | undefined = accessTokenPayload?.[KeycloakDataEnum.providerAddressOidc];
  const providerAddressOtherOidc: string | undefined = accessTokenPayload?.[KeycloakDataEnum.providerAddressOtherOidc];
  const providerCityOidc: string | undefined = accessTokenPayload?.[KeycloakDataEnum.providerCityOidc];
  const providerStateOidc: string | undefined = accessTokenPayload?.[KeycloakDataEnum.providerStateOidc];
  const providerZipCodeOidc: string | undefined = accessTokenPayload?.[KeycloakDataEnum.providerZipCodeOidc];
  const npiOidc: string | undefined = accessTokenPayload?.[KeycloakDataEnum.npiOidc];
  const LobOidc: string | undefined = accessTokenPayload?.[KeycloakDataEnum.LOBOidc];
  const taxID: string | undefined = accessTokenPayload?.[KeycloakDataEnum.taxID];

  return {
    userBillingOidc,
    providerAddressOidc,
    providerCityOidc,
    providerStateOidc,
    providerZipCodeOidc,
    npiOidc,
    providerNameOidc,
    LobOidc,
    providerAddressOtherOidc,
    userAddressOidc,
    taxID
  };
};
