import * as React from 'react';

import PaperServicesList from './PaperServicesList';

type ServicesFieldsListProps = {
  setDisabledSwitch: React.Dispatch<React.SetStateAction<boolean>>;
  readOnly: boolean;
};

function ServicesFieldsList({ readOnly, setDisabledSwitch }: ServicesFieldsListProps): JSX.Element {
  return <PaperServicesList readOnly={readOnly} setDisabledSwitch={setDisabledSwitch} />;
}

export default ServicesFieldsList;
