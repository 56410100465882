import { FormInstance } from 'antd';
export const GLOBAL_FORM_INSTANCE_PROP_NAME = 'GLOBAL_FORM_INSTANCE_PROP_NAME';
export function putFormInstanceInGlobalObject(formInstance: FormInstance) {
  //@ts-ignore
  window[GLOBAL_FORM_INSTANCE_PROP_NAME] = formInstance;
}

export function getFormInstanceFromGlobalObject(): FormInstance {
  //@ts-ignore
  return window[GLOBAL_FORM_INSTANCE_PROP_NAME] as FormInstance;
}
