import { useOidcAccessToken } from '@axa-fr/react-oidc';
import { useUserData } from './useUserData';

export const useCanViewProfiles = () => {
  const { accessTokenPayload } = useOidcAccessToken();
  const { isUserRole } = useUserData();
  if (isUserRole) return false;
  const resourceAccess = accessTokenPayload?.['resource_access'];
  if (!resourceAccess) return false;
  const realmManagement = resourceAccess?.['realm-management'];
  if (!realmManagement) return false;
  const roles = realmManagement?.['roles'];
  return roles && Array.isArray(roles) && roles.includes('query-users') && roles.includes('view-users');
};
