import { FormInstance } from 'antd';
import { NamePath } from 'antd/lib/form/interface';
import { FormType } from 'components/common/FormProvider';
import { ValidationTableDependenciesMap as ValidationTableDependenciesMapHCFA } from 'utils/hcfa/customRules';
import { ValidationTableDependenciesMap as ValidationTableDependenciesMapUB04 } from 'utils/ub04/customRules';
import { ValidationTableDependenciesMap as ValidationTableDependenciesMapDental } from 'utils/dental/customRules';

interface Args {
  row?: number;
  fields: string[];
  tableName: '24Table' | '42Services';
  formInstance: FormInstance;
}

export function revalidateTable({ row, fields, tableName, formInstance }: Args) {
  const validateSingleRow = typeof row === 'number';
  const tableValues = formInstance?.getFieldsValue()?.[tableName];
  if (!tableValues?.length) return;
  if (validateSingleRow) {
    const revalidateNamePaths: NamePath[] = fields.map((f) => [tableName, row!, f]);
    formInstance.validateFields(revalidateNamePaths);
    return;
  }
  const tableRowsNum = tableValues.length;
  const revalidateNamePaths: NamePath[] = [];
  for (let i = 0; i < tableRowsNum; i++) {
    fields.forEach((f) => {
      revalidateNamePaths.push([tableName, i, f]);
    });
  }
  formInstance.validateFields(revalidateNamePaths);
}

export function getRevalidateFields(fieldName: string, formType: FormType) {
  let dependencyMap: Record<string, string[]> = {};
  switch (formType) {
    case 'hcfa': {
      dependencyMap = ValidationTableDependenciesMapHCFA;
      break;
    }
    case 'ub04': {
      dependencyMap = ValidationTableDependenciesMapUB04;
      break;
    }
    case 'dental': {
      dependencyMap = ValidationTableDependenciesMapDental;
      break;
    }
  }
  /**Purely custom */
  // @ts-ignore
  if (fieldName in dependencyMap) return dependencyMap[fieldName] as string[];
  return [];
}
