import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { Form, Select } from 'antd';

import { getYupValidator } from 'utils/dental/validations';
import { FormContext } from 'components/common/FormProvider';
import { onSearchSelect } from 'utils/search/search';

type Props = {
  id: string;
  hideLabel?: boolean;
};

function FormItemSex({ id, hideLabel }: Props) {
  const { t } = useTranslation();
  const { form } = useContext(FormContext);

  return (
    <Form.Item label={hideLabel ? '' : t('common:fields.sex')} name={id} required rules={[getYupValidator(t, id)]}>
      <Select
        allowClear
        options={[
          { value: 'M', label: 'M' },
          { value: 'F', label: 'F' },
          { value: 'U', label: 'U' }
        ]}
        showSearch
        onSearch={(input) => {
          onSearchSelect(form, id, input, ['M', 'F', 'U']);
        }}
      />
    </Form.Item>
  );
}

export default FormItemSex;
