import { useOidcAccessToken } from '@axa-fr/react-oidc';
import { DatePicker, Divider, Form, Select, Table, notification } from 'antd';
import React, { useState } from 'react';
import { Button } from '@datum/react-components';
import { getGeographyVolumeReports } from 'api/reports';
import { useUserData } from 'utils/hooks/useUserData';
import NotAllowedPage from 'components/app/NotAllowedPage';
import { getFilterOptions } from 'api/dashbard';
import { DATE_FORMAT } from 'utils/reportsDateFormat';
import moment from 'moment';

// const LOBS_MOCK = ['test LOB', 'OH', 'IN', 'MS', 'Indiana', 'WV', 'NC', 'Michigan', 'lob', 'Georgia', 'MI'];

function GeographyVolumeReports() {
  const { accessToken } = useOidcAccessToken();
  const [loading, setLoading] = useState(false);
  const [docStatusReportList, setDocStatusReportList] = useState([]);
  //const [lobs, setLobs] = useState<string[]>(LOBS_MOCK);
  const { isUserRole } = useUserData();

  const [docStatusQueries, setDocStatusQueries] = useState<any>({
    claimType: [
      /*'HCFA', 'UB04', 'DENTAL'*/
    ],
    lob: [
      // 'GA - DSNP',
      // 'GA - Marketplace',
      // 'IN - DSNP',
      // 'IN - Marketplace',
      // 'IN - Medicaid',
      // 'MI - Medicaid',
      // 'NC - Marketplace',
      // 'OH - DSNP',
      // 'OH - Marketplace',
      // 'OH - Medicaid',
      // 'OH - MyCare',
      // 'GA - Medicaid',
      // 'KY - Marketplace',
      // 'AR - Medicaid',
      // 'WV - Marketplace',
      // 'IA - IOWA Medicaid',
      // 'MS - MISSISIPI Medicaid',
      // 'MI - Marketplace'
    ]
    // state: [
    //   // 'Ohio',
    //   // 'Indiana',
    //   // 'Kentucky',
    //   // 'Georgia',
    //   // 'West Virginia',
    //   // 'North Carolina',
    //   // 'Arkansas',
    //   // 'Mississippi',
    //   // 'Ohio/All',
    //   // 'Michigan'
    // ],
    // incomingMode: [
    //   /*'PORTAL'*/
    // ]
  });

  const geographyVolumeReportsFunction = async (values?: any) => {
    const portalDateFrom = values.portalDateFrom?.format('YYYY-MM-DD');
    const portalDateTo = values.portalDateTo?.format('YYYY-MM-DD');

    const transformdValues = { ...values, portalDateFrom, portalDateTo };

    if (accessToken) {
      try {
        const data = await getGeographyVolumeReports(
          {
            lob: transformdValues.lob,
            claimType: transformdValues.claimType,
            portalDateFrom: transformdValues.portalDateFrom && transformdValues.portalDateFrom,
            portalDateTo: transformdValues.portalDateTo && transformdValues.portalDateTo
          },
          accessToken
        );
        if (data) {
          setDocStatusReportList(data);
        }
      } catch (error: any) {
        console.error('An error occurred:', error);
        notification.error({
          message: 'Could not fetch User Report'
        });
      } finally {
        //
      }
    }
  };

  React.useEffect(() => {
    filterOptionsFunction();
  }, []);

  const filterOptionsFunction = async (values?: any) => {
    if (accessToken) {
      try {
        setLoading(true);
        const data = await getFilterOptions(accessToken);

        if (data) {
          setDocStatusQueries(data);
        }
      } catch (error: any) {
        console.error('An error occurred:', error);
        notification.error({
          message: 'Could not fetch Filter Options'
        });
      } finally {
        setLoading(false);
      }
    }
  };

  const columns: any = [
    {
      title: <b>CLAIM TYPE</b>,
      dataIndex: 'claimType',
      key: 'claimType',
      fixed: 'left'
    },
    {
      title: <b>CREATION DATE</b>,
      dataIndex: 'creationDate',
      key: 'creationDate',
      render: (date: string) => <div>{date ? moment(date).format('MMM DD. YYYY') : ''}</div>
    },
    {
      title: <b>DOCUMENT COUNT</b>,
      dataIndex: 'documentCount',
      key: 'documentCount'
    },
    {
      title: <b>LOB</b>,
      dataIndex: 'lob',
      key: 'lob'
    }
  ];

  if (isUserRole) return <NotAllowedPage />;
  return (
    <div className="branding-wrapper">
      <div className="box-wrapper">
        <div style={{ padding: '1rem 3rem 0em' }}>
          <Divider orientation="left">
            <b>GEOGRAPHY VOLUME REPORTS</b>
          </Divider>
        </div>
        <div style={{ marginTop: '2rem' }}>
          <Form
            name="basic"
            layout="vertical"
            autoComplete="off"
            onFinish={geographyVolumeReportsFunction}
            style={{ display: 'flex', alignItems: 'flex-end', gap: '1rem', padding: '0 1rem' }}
          >
            {/* <Form.Item name="username" label="Username">
              <Input placeholder="Username" allowClear />
            </Form.Item> */}
            <Form.Item label="LOB" name="lob">
              <Select
                mode="multiple"
                loading={loading}
                style={{ width: '150px' }}
                placeholder="Please select"
                allowClear
                options={docStatusQueries?.lob?.map((el: any) => ({ label: el, value: el }))}
                showSearch
              />
            </Form.Item>
            <Form.Item label="Claim Type" name="claimType">
              <Select
                mode="multiple"
                loading={loading}
                style={{ width: '150px' }}
                placeholder="Please select"
                allowClear
                options={docStatusQueries?.claimType?.map((el: any) => ({ label: el, value: el }))}
                showSearch
              />
            </Form.Item>
            <Form.Item
              name="portalDateFrom"
              label={
                <>
                  <span style={{ color: 'red' }}>* </span> Portal Creation Date From
                </>
              }
              rules={[{ required: true, message: 'Please input your Portal Creation Date From!' }]}
            >
              <DatePicker suffixIcon={null} format={DATE_FORMAT} placeholder={DATE_FORMAT} style={{ width: '100%' }} />
            </Form.Item>
            <Form.Item
              name="portalDateTo"
              label={
                <>
                  <span style={{ color: 'red' }}>* </span> Portal Creation Date To
                </>
              }
              rules={[{ required: true, message: 'Please input your Portal Creation Date To!' }]}
            >
              <DatePicker suffixIcon={null} format={DATE_FORMAT} placeholder={DATE_FORMAT} style={{ width: '100%' }} />
            </Form.Item>
            <Form.Item>
              <div className="theme ant-col theme ant-form-item-label">
                <label htmlFor="basic_filter" title="Filter" style={{ opacity: 0 }}>
                  Filter
                </label>
              </div>
              <Button type="submit" style={{ width: '100%' }} variant="primary">
                Reports
              </Button>
            </Form.Item>
          </Form>
        </div>
        <div style={{ padding: '0 1rem' }}>
          <Table
            scroll={{ x: 1300 }}
            bordered
            style={{ width: '100%' }}
            dataSource={docStatusReportList}
            columns={columns}
          />
        </div>
      </div>
    </div>
  );
}

export default GeographyVolumeReports;
